import React from 'react'
import { DescriptionCP } from 'submodules/nerit-framework-ui/common/components/description/DescriptionCP'
import { TagActiveCP } from 'submodules/nerit-framework-ui/common/components/tag/tag-active/TagActiveCP'
import { AccountResponseDTO } from 'submodules/space4leads-sdk/services/accounts/dtos/responses/AccountResponseDTO'
import { PlanLabelEnum } from 'submodules/space4leads-sdk/services/accounts/enums/PlanEnum'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { RoutingHelper } from 'config/RoutingHelper'
import { AccountUtils } from 'submodules/space4leads-sdk/services/accounts/utils/AccountUtils'

interface ICPProps {
    account: AccountResponseDTO
}

/**
 * Tela de detalhes da conta.
 */
export function DescriptionAccountDataCP(props: ICPProps): JSX.Element {

    const currentPlanConfig = AccountUtils.getPlanConfig(props.account.plan)

    return (
        <DescriptionCP
            bordered={true}
            numberOfColumns={1}
            items={[
                {
                    label: 'Plano',
                    text: PlanLabelEnum[props.account.plan],
                },
                {
                    label: 'Nº da conta',
                    text: `#${props.account.code}`,
                },
                {
                    label: 'Nome da Conta',
                    text: props.account.name,
                },
                {
                    label: 'Data de Ativação',
                    text: DateUtils.formatDate(props.account.creationDate, DateFormatEnum.BR_WITHOUT_TIME),
                },
                {
                    label: 'Status',
                    text: <TagActiveCP isActive={true}/>,
                },
                {
                    label: 'Data de desativação',
                    text: DateUtils.formatDate(props.account.disableDate, DateFormatEnum.BR_WITHOUT_TIME),
                    hide: !props.account.disableDate
                },
                {
                    label: 'Link de Assinatura',
                    text: !!currentPlanConfig?.paymentLink ? <ButtonCP type={'danger'} onClick={() => RoutingHelper.openInNewTab(currentPlanConfig.paymentLink!)}>Assinar plano</ButtonCP> : undefined,
                    hide: !props.account.disableDate || !currentPlanConfig?.paymentLink
                },
            ]}
        />
    )
}
