import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { WizardCP } from 'common/components/wizard/WizardCP'
import { StepCustomerDataICP } from 'modules/referral-program/components/wizard-referral-program-subscribe/steps/StepCustomerDataICP'
import { StepFranchiseDataICP } from 'modules/referral-program/components/wizard-referral-program-subscribe/steps/StepFranchiseDataICP'
import { IReferralProgramResponseDTO } from 'modules/referral-program/services/referral-program/dtos/responses/IReferralProgramResponseDTO'
import { ReferralProgramSubscribeFormModel } from 'modules/referral-program/components/wizard-referral-program-subscribe/models/ReferralProgramSubscribeFormModel'
import { useFormStateManager } from 'common/form-state-manager/UseFormStateManager'
import { RequestUtils } from 'common/request-manager/RequestUtils'
import { useRequest } from 'common/request-manager/use-request/UseRequest'
import { IReferralProgramSubscribeRequestDTO } from 'modules/referral-program/services/referral-program/dtos/requests/IReferralProgramSubscribeRequestDTO'
import { ReferralProgramRequests } from 'modules/referral-program/services/referral-program/ReferralProgramRequests'
import { ICustomerPersonByReferralProgramResponseDTO } from 'modules/person/services/dtos/response/ICustomerPersonByReferralProgramResponseDTO'
import { StepReferFriendsICP } from 'modules/referral-program/components/wizard-referral-program-subscribe/steps/StepReferFriendsICP'
import { SystemUtils } from 'common/utils/SystemUtils'
import { NotificationHelper } from 'common/helpers/NotificationHelper'
import { RequestHelper } from 'common/request-manager/RequestHelper'
import { IApiReturn } from 'common/interfaces/IApiReturn'
import { IReferralProgramSubscribeMultipleRequestDTO } from 'modules/referral-program/services/referral-program/dtos/requests/IReferralProgramSubscribeMultipleRequestDTO'
import { IReferralProgramSubscribeItemRequestDTO } from 'modules/person/services/dtos/request/IReferralProgramSubscribeItemRequestDTO'
import { TableReferFriendsUtils } from 'modules/referral-program/components/table-refer-friends/inner/TableReferFriendsUtils'

interface IWizardInviteCPProps {
    referralProgram: IReferralProgramResponseDTO
    referredByToken?: string
    onSubscribe: (personRegisterd: ICustomerPersonByReferralProgramResponseDTO) => void
}

/**
 * Landing Page para coletar as respostas de uma pesquisa
 */
export function WizardReferralProgramSubscribeCP(props: IWizardInviteCPProps): JSX.Element {

    const [formValidator, setFormValidator] = useState<ReferralProgramSubscribeFormModel>((new ReferralProgramSubscribeFormModel()))
    const formStateManager = useFormStateManager<ReferralProgramSubscribeFormModel>(formValidator)

    const [isSubscribing, setIsSubscribing] = useState<boolean>(false)
    const [personSubscribed, setPersonSubscribed] = useState<ICustomerPersonByReferralProgramResponseDTO>()

    const subscribeMultipleRequest = useRequest('none')
    useEffect(onSubscribeMultipleRequestChange, [subscribeMultipleRequest.isAwaiting])

    useEffect(init, [])

    /**
     * Inicializa.
     */
    function init(): void {

        // Começa com 5 linhas já abertas para indicação
        const initialFriendsFields: IReferralProgramSubscribeItemRequestDTO[] = []
        for (let i = 0; i < 5; i++)
            initialFriendsFields.push({ name: '', phone: '' })

        setFormValidator(new ReferralProgramSubscribeFormModel({
            friends: initialFriendsFields
        }))
    }

    /**
     * Retorna se pode avancar apos digitado os dados pessoais.
     */
    function canContinueOnCustomerData(): boolean {
        const formValues = formStateManager.getFormValues()
        return !!formValues?.email && !!formValues?.name && !!formValues?.phone && formValues?.isTermChecked
    }

    /**
     * Invoca api para persistir os dados de quem esta se cadastrando.
     */
    async function subscribe(): Promise<boolean> {

        setIsSubscribing(true)

        formStateManager.setConsiderAllErrors(true)
        if (!await formStateManager.validate())
            return false

        const formValues = formStateManager.getFormValues()
        if (!formValues)
            return false

        const dto: IReferralProgramSubscribeRequestDTO = {
            name: formValues.name,
            phone: formValues.phone,
            email: formValues.email,
            franchiseCode: formValues.franchiseCode,
            referredByToken: props.referredByToken
        }

        const result = await RequestHelper.runRequest<IApiReturn<ICustomerPersonByReferralProgramResponseDTO>>(ReferralProgramRequests.subscribe(props.referralProgram.slug, dto))
        const resultData = result?.data.data

        setPersonSubscribed(resultData)
        setIsSubscribing(false)

        return !!resultData
    }

    /**
     * Ao terminar o wizard
     */
    async function onFinish(): Promise<void> {

        if (!personSubscribed)
            return

        if (SystemUtils.isEmpty(formStateManager.getFieldValue('friends'))) {
            NotificationHelper.error('Ops!', 'Você deve indicar ao menos um amigo para participar do programa')
            return
        }

        const dto: IReferralProgramSubscribeMultipleRequestDTO = {
            persons: TableReferFriendsUtils.getFilledRows(formStateManager.getFieldValue('friends')),
            franchiseCode: formStateManager.getFieldValue('franchiseCode'),
            referredByToken: personSubscribed.token
        }

        subscribeMultipleRequest.runRequest(ReferralProgramRequests.subscribeMultiple(props.referralProgram.slug, dto))
    }

    /**
     * Apos reotorno da api de salvar.
     */
    function onSubscribeMultipleRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(subscribeMultipleRequest, 'Ocorreu algun erro ao salvar o cadastro', true))
            return

        props.onSubscribe(personSubscribed!)
    }

    return (
        <WrapperSCP>
            <WizardCP
                loading={subscribeMultipleRequest.isAwaiting || isSubscribing}
                action={onFinish}
                steps={[
                    {
                        key: 'signup',
                        title: 'Cadastro',
                        content: <StepCustomerDataICP formStateManager={formStateManager} referralProgramTermUrl={props.referralProgram.consentTermUrl}/>,
                        canContinue: canContinueOnCustomerData
                    },
                    {
                        key: 'franchise',
                        title: 'Unidade',
                        content: <StepFranchiseDataICP formStateManager={formStateManager} participatingFranchises={props.referralProgram.participatingFranchises}/>,
                        nextAction: subscribe,
                        canContinue: () => !!formStateManager.getFieldValue('franchiseCode')
                    },
                    {
                        key: 'refer-friends',
                        title: 'Indicação',
                        content: <StepReferFriendsICP formStateManager={formStateManager}/>,
                        canContinue: () => !SystemUtils.isEmpty(formStateManager.getFieldValue('friends')) && !!formStateManager.getFieldValue('friends')[0].name && !!formStateManager.getFieldValue('friends')[0].phone
                    },
                ]}
            />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    margin-top: 20px;
`
