import { TextCP } from 'common/components/text/TextCP'
import React from 'react'
import { ProgressCP } from 'common/components/progress/ProgressCP'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { MaskUtils } from 'common/utils/MaskUtils'
import { FlexCP } from 'common/components/flex/FlexCP'
import { HelpCP } from 'common/components/help/HelpCP'
import { MathUtils } from 'common/utils/MathUtils'

interface ICProps {
    title: string
    value: number
    target?: number
    help?: string
    type: 'ratio' | 'target'
}

export function CardDashboardWidgetRateFooterICP(props: ICProps): JSX.Element {

    return (
        <>
            <FlexCP alignItems={'center'} justifyContent={!!props.help ? 'space-between' : undefined}>
                <TextCP text={props.title}/>
                {
                    !!props.help &&
                    <HelpCP
                        showOnlyIcon={true}
                        marginTop={0}
                        marginBottom={0}
                        text={props.help}
                        type={'tooltip'}
                    />
                }
            </FlexCP>

            {
                props.target !== undefined &&
                <>
                    <ProgressCP
                        percent={MathUtils.getPercentage(props.value, props.target, true)}
                        backgroudColorArea={'#FFF'}
                        gradientColor={true}
                    />
                    {
                        props.type === 'target' &&
                        <TextCP
                            text={`Meta: ${MaskUtils.applyNumberMask(props.target, 2)}%`}
                            color={ThemeAnt.gray}
                        />
                    }
                </>
            }
        </>
    )
}
