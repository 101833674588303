import { MaskUtils } from 'common/utils/MaskUtils'
import { IFranchiseResponseDTO } from 'modules/admin/services/franchise/dtos/response/IFranchiseResponseDTO'
import { FranchiseFormModel } from 'modules/admin/components/drawer-franchise/inner/FranchiseFormModel'
import { IFranchiseSaveRequestDTO } from 'modules/admin/services/franchise/dtos/request/IFranchiseSaveRequestDTO'

/**
 * Reune metodos uteis para manipular formulario de dados da franquia.
 */
export const FranchiseFormUtils = {

    /**
     * Gera FormModel de Franquia a partir do DTO
     */
    getFormData(dto: IFranchiseResponseDTO): Partial<FranchiseFormModel> {
        return {
            name: dto.name,
            cnpj: dto.cnpj,
            email: dto.email,
            phone: dto.phone,
            zipCode: dto.address?.zipCode,
            street: dto.address?.street,
            number: dto.address?.number?.toString(),
            complement: dto.address?.complement,
            neighborhood: dto.address?.neighborhood,
            city: dto.address?.city,
            state: dto.address?.state
        }
    },

    /**
     * Gera DTO de Franquia a partir do FormModel
     */
    getDto(formModel: FranchiseFormModel): IFranchiseSaveRequestDTO {
        return {
            name: formModel.name,
            cnpj: formModel.cnpj,
            email: formModel.email,
            phone: MaskUtils.removeMask(formModel.phone),
            address: {
                street: formModel.street,
                neighborhood: formModel.neighborhood,
                complement: formModel.complement,
                number: formModel.number ? formModel.number.toString() : undefined,
                zipCode: formModel.zipCode,
                city: formModel.city,
                state: formModel.state,
            }
        }
    },
}
