import React, { useEffect, useState } from 'react'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { ProjectResponseDTO } from 'submodules/space4leads-sdk/services/accounts/dtos/responses/ProjectResponseDTO'
import { ProjectFormModel } from 'modules/admin/components/modal-project/inner/ProjectFormModel'
import { SwitchCP } from 'submodules/nerit-framework-ui/common/components/form-fields/switch/SwitchCP'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { ProjectSaveRequestDTO } from 'submodules/space4leads-sdk/services/accounts/dtos/requests/ProjectSaveRequestDTO'
import { ProjectsRequests } from 'submodules/space4leads-sdk/services/accounts/ProjectsRequests'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'

interface ICPProps {
    visible: boolean
    onSave: () => void
    onCancel: () => void
    accountCode: number
    project?: ProjectResponseDTO
}

/**
 * Drawer de projeto.
 */
export function ModalProjectCP(props: ICPProps): JSX.Element {

    const [formValidator, setFormValidator] = useState<ProjectFormModel>((new ProjectFormModel()))
    const formStateManager = useFormStateManager<ProjectFormModel>(formValidator)

    const saveProjectRequest = useRequest<void>('none')
    useEffect(onSaveProjectRequestChange, [saveProjectRequest.isAwaiting])

    useEffect(init, [props.visible])

    /**
     * Inicializa a tela.
     */
    function init(): void {

        // Sempre reseta variaveis locais para nao pegar de forms antigos
        setFormValidator(new ProjectFormModel({
            isActive: true
        }))

        if (!props.visible || !props.project)
            return

        setFormValidator(new ProjectFormModel({
            name: props.project.name,
            isActive: props.project.isActive,
            logoUrl: props.project.logoUrl,
        }))
    }

    /**
     * Salva.
     */
    async function save(): Promise<void> {

        if (!await FormStateManagerUtils.validateRequiredFields(formStateManager))
            return

        const formValues = formStateManager.getFormValues()!
        const dto: ProjectSaveRequestDTO = {
            name: formValues.name,
            isActive: formValues.isActive,
            accountCode: props.accountCode,
            logoUrl: formValues.logoUrl,
        }

        if (!!props.project)
            saveProjectRequest.runRequest(ProjectsRequests.update(props.project.code, dto))
        else
            saveProjectRequest.runRequest(ProjectsRequests.create(dto))
    }

    /**
     * Apos reotorno da api de salvar
     */
    function onSaveProjectRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(saveProjectRequest, NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE, NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE, true))
            return

        formStateManager.reset(undefined)
        props.onSave()
    }

    return (
        <ModalCP
            title={'Projeto'}
            visible={props.visible}
            onCancel={props.onCancel}
            onOk={save}
            actionLoading={saveProjectRequest.isAwaiting}
            width={400}
        >
            <InputCP
                formStateManager={formStateManager}
                fieldName={'name'}
                label={'Nome do Projeto'}
            />

            <InputCP
                formStateManager={formStateManager}
                fieldName={'logoUrl'}
                label={'URL da Logo do Projeto'}
            />

            <SwitchCP
                formStateManager={formStateManager}
                fieldName={'isActive'}
                label={'Ativo?'}
            />
        </ModalCP>
    )
}
