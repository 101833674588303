import React from 'react'
import { TitleCP } from 'common/components/title/TitleCP'
import { HelpCP } from 'common/components/help/HelpCP'

interface ICPProps {
    title: string
    subtitle: string
}

/**
 * Titulo e Subtitulo da area de cadastro do programa.
 */
export function TitleReferralProgramSubscribeCP(props: ICPProps): JSX.Element {

    return (
        <>
            <TitleCP underLine={true}>
                {props.title}
            </TitleCP>
            <HelpCP text={props.subtitle} type={'text'}/>
        </>
    )
}
