import React from 'react'
import { MenuItemCP } from 'common/components/menu/menu-item/MenuItemCP'
import { MenuCP } from 'common/components/menu/menu/MenuCP'
import { SubMenuCP } from 'common/components/menu/sub-menu/SubMenuCP'
import { SiderCP } from 'common/components/screen-layout/sider/SiderCP'
import { TextCP } from 'common/components/text/TextCP'
import { useScreenSize } from 'common/responsiveness/use-screen-size/UseScreenSize'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { HeaderCP } from 'common/components/screen-layout/header/HeaderCP'
import { ScreenSubMenuTP } from 'common/components/screen-layout-submenu/submenu/inner/ScreenSubMenuItemTP'

interface IScreenSubmenuCPProps<ViewTP> {
    onSelectMenuOption: (option: ViewTP) => void
    currentMenuOption: ViewTP
    title: string
    submenuConfigs: Array<ScreenSubMenuTP<ViewTP>>
    defaultOpenKeys?: string[]
}

/**
 * COMPONENTE:
 * Generico de Submenus das telas que possuem submenu.
 */
export function ScreenSubmenuCP<ViewTP>(props: IScreenSubmenuCPProps<ViewTP>): JSX.Element {

    const screenSize = useScreenSize()

    function render(submenuCP: JSX.Element): JSX.Element {
        return screenSize.smd ? submenuCP : <SiderCP width={275} bgColor={ThemeAnt.secondMenuBg}>{submenuCP}</SiderCP>
    }

    return render(
        <MenuCP defaultOpenKeys={props.defaultOpenKeys}>
            {
                !screenSize.smd &&
                <HeaderCP
                    title={props.title}
                    titleSize={'large'}
                    titleUnderlineColor={'#fff'}
                />
            }

            {
                props.submenuConfigs.filter(submenu => submenu.enabled).map(submenu => (
                    <SubMenuCP key={submenu.key} title={submenu.title}>
                        {
                            submenu.items.filter(submenuItem => submenuItem.enabled === undefined || submenuItem.enabled).map(menuItem => (
                                <MenuItemCP
                                    key={menuItem.view as any}
                                    marginVertical={0}
                                    align={'left'}
                                    onClick={() => props.onSelectMenuOption(menuItem.view)}
                                    isActive={menuItem.view === props.currentMenuOption}
                                    dataTour={menuItem.view as any}
                                >
                                    <TextCP text={menuItem.name}/>
                                </MenuItemCP>
                            ))
                        }
                    </SubMenuCP>
                ))
            }
        </MenuCP>
    )
}
