import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { TimelineCP } from 'submodules/nerit-framework-ui/common/components/timeline/TimelineCP'
import { ICustomerGoalsResponseDTO } from 'modules/referral-program/services/referral-program/dtos/responses/ICustomerGoalsResponseDTO'
import { useRequest } from 'common/request-manager/use-request/UseRequest'
import { IGenericListResponseDTO } from 'common/dtos/responses/IGenericListResponseDTO'
import { PersonRequests } from 'modules/person/services/PersonRequests'
import { RequestUtils } from 'common/request-manager/RequestUtils'
import { CollapseCP } from 'common/components/collapse/CollapseCP'
import { ProgressCP } from 'common/components/progress/ProgressCP'
import { TimelineItemCustomerReferralsContentICP } from 'modules/referral-program/components/timeline-customer-referrals/inner/TimelineItemCustomerReferralsContentICP'
import { IconGoalStatusCP } from 'modules/referral-program/components/icon-goal-status/IconGoalStatusCP'
import { MathUtils } from 'common/utils/MathUtils'
import { useScreenSize } from 'common/responsiveness/use-screen-size/UseScreenSize'

interface ICPProps {
    referralProgramCode: number
    personCode: number
}

/**
 * Timeline das metas de um cliente.
 */
export function TimelineCustomerReferralsCP(props: ICPProps): JSX.Element {

    const screenSize = useScreenSize()

    const [customerGoals, setCustomerGoals] = useState<ICustomerGoalsResponseDTO[]>([])

    const getCustomerGoalsRequest = useRequest<IGenericListResponseDTO<ICustomerGoalsResponseDTO>>()
    useEffect(onGetCustomerGoalsRequestChange, [getCustomerGoalsRequest.isAwaiting])

    useEffect(init, [props.personCode, props.referralProgramCode])

    /**
     * Requisicao API.
     */
    function init(): void {
        getCustomerGoalsRequest.runRequest(PersonRequests.getGoals(props.personCode, props.referralProgramCode))
    }

    /**
     * Retorno da requisicao.
     */
    function onGetCustomerGoalsRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getCustomerGoalsRequest, 'Não foi possível obter os metas deste usuário'))
            return

        setCustomerGoals(getCustomerGoalsRequest.responseData!.list)
    }

    return (
        <TimelineWrapperSCP>
            <TimelineCP
                loading={getCustomerGoalsRequest.isAwaiting}
                items={
                    customerGoals.map((customerGoal) => ({
                        dot: <IconGoalStatusCP wasAchived={customerGoal.totalAchived >= customerGoal.goal.quantity}/>,
                        content: (
                            <CollapseCP
                                showExpandArrow={true}
                                expandIconPosition={'right'}
                                accordion={true}
                                panels={[
                                    {
                                        title: (
                                            screenSize.smd
                                                ?
                                                <TitleWrapperSCP>
                                                    {customerGoal.goal.title}
                                                    <ProgressCP percent={MathUtils.getPercentage(customerGoal.totalAchived, customerGoal.goal.quantity)} width={100}/>
                                                </TitleWrapperSCP>
                                                :
                                                customerGoal.goal.title
                                        ),
                                        extra: screenSize.smd ? undefined : <ProgressCP percent={MathUtils.getPercentage(customerGoal.totalAchived, customerGoal.goal.quantity)} width={100}/>,
                                        content: <TimelineItemCustomerReferralsContentICP goal={customerGoal.goal}/>,
                                    }
                                ]}
                            />
                        ),
                    }))
                }
            />
        </TimelineWrapperSCP>
    )
}

const TimelineWrapperSCP = styled.div`
  margin: 20px;
`

const TitleWrapperSCP = styled.div`
  display: flex;
  flex-direction: column;
`
