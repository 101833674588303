import { RequestUtils } from 'common/request-manager/RequestUtils'
import { useRequest } from 'common/request-manager/use-request/UseRequest'
import React, { useEffect } from 'react'
import { IGenericListResponseDTO } from 'common/dtos/responses/IGenericListResponseDTO'
import { TableCP } from 'common/components/table/TableCP'
import { FranchisesTableUtils } from 'modules/admin/components/table-franchises/inner/FranchisesTableUtils'
import { IFranchiseResponseDTO } from 'modules/admin/services/franchise/dtos/response/IFranchiseResponseDTO'
import { FranchiseRequests } from 'modules/admin/services/franchise/FranchiseRequests'
import { CardCP } from 'common/components/card/CardCP'

interface ITableTreatmentGroupCPProps {
    onEdit: (code: number) => void
    forceLoadList: boolean
    onListLoaded: () => void
}

/**
 * Listagem de unidades.
 */
export function TableFranchisesCP(props: ITableTreatmentGroupCPProps): JSX.Element {

    const searchFranchiseRequest = useRequest<IGenericListResponseDTO<IFranchiseResponseDTO>>()
    useEffect(onSearchFranchiseRequestChange, [searchFranchiseRequest.isAwaiting])

    useEffect(init, [props.forceLoadList])

    /**
     * Inicializa.
     */
    function init(): void {

        if (!props.forceLoadList)
            return

        getFranchisesFromApi()
    }

    /**
     * Obtem unidades da api.
     */
    function getFranchisesFromApi(): void {
        searchFranchiseRequest.runRequest(FranchiseRequests.search())
    }

    /**
     * Retorno da requisicao.
     */
    function onSearchFranchiseRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(searchFranchiseRequest, 'Não foi possível obter unidades.'))
            return

        props.onListLoaded()
    }

    return (
        <CardCP innerSpacing={'none'}>
            <TableCP
                data={searchFranchiseRequest.responseData?.list}
                loading={searchFranchiseRequest.isAwaiting}
                columns={FranchisesTableUtils.getTableColumns(props.onEdit)}
            />
        </CardCP>
    )
}
