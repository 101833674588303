import { Switch } from 'antd'
import { TextCP } from 'common/components/text/TextCP'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FormModel } from 'common/form-state-manager/FormModel'
import { IFormStateManager } from 'common/form-state-manager/IFormStateManager'
import { CheckIconCP } from 'common/components/icons/CheckIconCP'
import { CloseIconCP } from 'common/components/icons/CloseIconCP'

interface IScpProps {
    marginRight?: number
    marginLeft?: number
    marginTop?: number
    marginBottom?: number
}

interface ISwitchCPProps<FModelTP extends FormModel = any> extends IScpProps{

    isTextInside: boolean
    isChecked?: boolean
    onChange?: (checked: boolean) => void

    textOutside?: string
    textInsideChecked?: string
    textInsideUnchecked?: string
    disabled?: boolean

    formStateManager?: IFormStateManager<FModelTP>
    fieldName?: keyof FModelTP
}

/**
 * COMPONENTE
 * Elemento de selecao unica do tipo 'Switch'.
 */
export function SwitchCP<FModelTP extends FormModel = any>(props: ISwitchCPProps<FModelTP>): JSX.Element {

    const [isChecked, setIsChecked] = useState<boolean>(false)
    useEffect(init, [props.isChecked, props.formStateManager])

    /**
     * Inicializa.
     */
    function init(): void {

        if (props.isChecked)
            return setIsChecked(props.isChecked)

        if (props.formStateManager && props.fieldName)
            return setIsChecked(props.formStateManager.getFieldValue(props.fieldName))

    }

    /**
     * Ao alterar o valor do campo.
     */
    function onChange(checked: boolean): void {

        setIsChecked(checked)
        if (props.formStateManager && props.fieldName)
            props.formStateManager.changeFieldValue(props.fieldName, checked)

        if (props.onChange)
            props.onChange(checked)
    }

    return (
        <WrapperSCP
            marginTop={props.marginTop ?? 8}
            marginRight={props.marginRight ?? 0}
            marginBottom={props.marginBottom ?? 8}
            marginLeft={props.marginLeft ?? 0}
        >
            <Switch
                checkedChildren={props.isTextInside ? props.textInsideChecked : <CheckIconCP size={12}/>}
                unCheckedChildren={props.isTextInside ? props.textInsideUnchecked : <CloseIconCP size={12}/>}
                checked={isChecked}
                disabled={props.disabled}
                onChange={onChange}
                defaultChecked={true}
            />

            {
                (!props.isTextInside && props.textOutside) &&
                <TextCP text={props.textOutside} marginLeft={5}/>
            }
        </WrapperSCP>
    )

}

const WrapperSCP = styled.div<IScpProps>`

   display: flex;
   margin: ${props => `${props.marginTop!}px ${props.marginRight!}px ${props.marginBottom!}px ${props.marginLeft!}px`};
   align-items: center;
   justify-content: flex-start;

    .ant-switch {
        &.ant-switch-checked {
            background-color: ${(props) => props.theme.primaryColor};
        }
    }

`
