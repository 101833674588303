import React from 'react'
import { AccessControlCP } from 'common/components/access-control/AccessControlCP'
import { RoutingHelper } from 'config/RoutingHelper'
import { StringUtils } from 'common/utils/StringUtils'
import { RouteConfigTP } from 'common/types/RouteConfigTP'
import { ScreenReferralProgramList } from 'modules/referral-program/screens/screen-referral-program-list/ScreenReferralProgramList'
import { ScreenReferralProgramConfig } from 'modules/referral-program/screens/screen-referral-program-config/ScreenReferralProgramConfig'

ReferralProgramRoutes.ROOT = '/referral-program'
ReferralProgramRoutes.SETUP = `${ReferralProgramRoutes.ROOT}/setup`
ReferralProgramRoutes.SETUP_WITH_QUERY = `${ReferralProgramRoutes.ROOT}/setup/:code?`

ReferralProgramRoutes.CUSTOMER_REFERRALS_LP = '/lp-customer-referrals'
ReferralProgramRoutes.CUSTOMER_REFERRALS_LP_WITH_QUERY = `${ReferralProgramRoutes.CUSTOMER_REFERRALS_LP}/:slug`

ReferralProgramRoutes.REFERRAL_PROGRAM_SUBSCRIBE_LP = '/lp-subscribe'
ReferralProgramRoutes.REFERRAL_PROGRAM_SUBSCRIBE_LP_WITH_QUERY = `${ReferralProgramRoutes.REFERRAL_PROGRAM_SUBSCRIBE_LP}/:slug`

ReferralProgramRoutes.REFERRED_BY_TOKEN_PARAM = 't'
ReferralProgramRoutes.CUSOMER_REFERRALS_PHONE_PARAM = 'p'
ReferralProgramRoutes.CUSOMER_REFERRALS_EMAIL_PARAM = 'e'

const ROUTES_CONFIG: RouteConfigTP[] = [
    {
        key: StringUtils.getSlugStyleString(ReferralProgramRoutes.ROOT),
        path: ReferralProgramRoutes.ROOT,
        component: ScreenReferralProgramList,
        exact: true,
    },
    {
        key: StringUtils.getSlugStyleString(ReferralProgramRoutes.SETUP),
        path: ReferralProgramRoutes.SETUP_WITH_QUERY,
        component: ScreenReferralProgramConfig,
    },
]

/**
 * Componente que define as rotas para acesso ao modulo de Indicacoes
 */
export function ReferralProgramRoutes(): JSX.Element {

    return (
        <AccessControlCP>
            {RoutingHelper.renderRoutingSwitch(ROUTES_CONFIG)}
        </AccessControlCP>
    )
}

