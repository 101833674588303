import React from 'react'
import { PermissionUtils } from 'modules/auth/utils/PermissionUtils'
import { RoutingHelper } from 'config/RoutingHelper'
import { PermissionEnum } from 'submodules/space4leads-sdk/services/people/person/enums/PermissionEnum'
import { PlanEnum } from 'submodules/space4leads-sdk/services/accounts/enums/PlanEnum'

interface IAccessControlCPProps {
    children: React.ReactNode
    redirect?: boolean
    permissions?: PermissionEnum[]
    plan?: PlanEnum
    notifyDenial?: boolean
    routeToReturn?: string
}

/**
 * Wrapper que abstrai tratamento generico para restricao de acesso a recursos do sistema dependendo da compatibilidade
 * entre a combinacao de restricoes definidas para o recurso, os modulos habilitados para o schema e as permissoes do usuario atual logado.
 */
export function AccessControlCP(props: IAccessControlCPProps): JSX.Element {

    if (PermissionUtils.hasAccess(props.permissions, props.plan))
        return <>{props.children}</>

    if (!props.redirect)
        return <></>

    if (props.notifyDenial)
        PermissionUtils.showDefaultDeniedAccessMsg()

    return RoutingHelper.renderRedirect(props.routeToReturn ?? 'default')
}
