import React from 'react'
import styled from 'styled-components'
import { ReferralProgramStatusAndLogoColumnICP } from 'modules/referral-program/components/list-referral-programs/inner/list-item-referral-programs/inner/ReferralProgramStatusAndLogoColumnICP'
import { IReferralProgramListItemResponseDTO } from 'modules/referral-program/services/referral-program/dtos/responses/IReferralProgramListItemResponseDTO'
import { ReferralProgramItemPreviewICP } from 'modules/referral-program/components/list-referral-programs/inner/list-item-referral-programs/inner/ReferralProgramItemPreviewICP'
import { ReferralProgramStatisticColumnICP } from 'modules/referral-program/components/list-referral-programs/inner/list-item-referral-programs/inner/ReferralProgramStatisticColumnICP'
import { IconCP } from 'common/components/icons/IconCP'
import { ConditionalRenderCP } from 'common/components/conditional-render/ConditionalRenderCP'
import { ReferralProgramActionsColumnICP } from 'modules/referral-program/components/list-referral-programs/inner/list-item-referral-programs/inner/ReferralProgramActionsColumnICP'
import { MathUtils } from 'common/utils/MathUtils'
import { SystemConfig } from 'config/SystemConfig'
import { ReferralProgramRoutes } from 'modules/referral-program/routes/ReferralProgramRoutes'

interface ICPProps {
    showActions: boolean
    onUpdateList?: () => void
    referralProgram: IReferralProgramListItemResponseDTO
    onSelectReferralProgram: () => void
}

/**
 * Campos de cada item da listagem de programas de indicacao.
 */
export function ListItemReferralProgramsCP(props: ICPProps): JSX.Element {

    return (
        <ListItemContainerSCP>
            <ReferralProgramStatusAndLogoColumnICP referralProgram={props.referralProgram}/>

            <ReferralProgramItemPreviewICP referralProgram={props.referralProgram}/>

            <ReferralProgramStatisticColumnICP
                total={props.referralProgram.totalAdheredPersons}
                percent={MathUtils.getPercentage(props.referralProgram.totalAdheredPersons, props.referralProgram.totalRegisteredPersons)}
                icon={<IconCP antIcon={'user'}/>}
                label={'engajamento'}
                helpText={'Relação entre o total de pessoas que aderiram ao programa e o total de pessoas que se cadastraram no programa'}
            />

            <ConditionalRenderCP shouldRender={props.showActions}>
                <ReferralProgramActionsColumnICP
                    onSelectReferralProgram={props.onSelectReferralProgram}
                    onUpdateList={props.onUpdateList}
                    code={props.referralProgram.code}
                    isActive={props.referralProgram.isActive}
                    referralProgramLink={`${SystemConfig.getInstance().uiBaseUrl}${ReferralProgramRoutes.REFERRAL_PROGRAM_SUBSCRIBE_LP}/${props.referralProgram.slug}`}
                />
            </ConditionalRenderCP>
        </ListItemContainerSCP>
    )

}

const ListItemContainerSCP = styled.div`
  display: flex;
`

// const ChartContainerSCP = styled.div`
//   background: white;
// `
