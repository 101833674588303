import { FormModel } from 'common/form-state-manager/FormModel'
import React, { useEffect, useState } from 'react'
import { AutocompletePickerCP } from 'common/components/form-fields/autocomplete-picker/AutocompletePickerCP'
import { INameAndCodeResponseDTO } from 'common/dtos/responses/INameAndCodeResponseDTO'
import { IAutocompleteCommonProps } from 'common/components/form-fields/autocomplete-picker/inner/IAutocompleteCommonProps'
import { ReferralProgramRequests } from 'modules/referral-program/services/referral-program/ReferralProgramRequests'
import * as _ from 'lodash'
import { IReferralProgramResponseDTO } from 'modules/referral-program/services/referral-program/dtos/responses/IReferralProgramResponseDTO'
import { SystemUtils } from 'common/utils/SystemUtils'

interface ICPProps<FModelTP extends FormModel = any> extends IAutocompleteCommonProps<INameAndCodeResponseDTO, FModelTP> {
    label: string
    onSelectReferralProgram?: (fullOpt: IReferralProgramResponseDTO) => void
}

/**
 * COMPONENTE select programa de indicacao.
 */
export function AutocompleteReferralProgramCP<FModelTP extends FormModel = any>(props: ICPProps<FModelTP>): JSX.Element {

    const [loadedOptions, setLoadedOptions] = useState<IReferralProgramResponseDTO[]>()
    const [, setSelectedOption] = useState<IReferralProgramResponseDTO>()

    useEffect(init, [loadedOptions])

    /**
     * Inicializa.
     */
    function init(): void {

        // Precaucao de sincronismo dos valores setados no FSM, pois um depende do outro
        SystemUtils.sleep(10)

        if (!props.fieldName || !props.formStateManager)
            return

        const fieldValue = props.formStateManager?.getFieldValue(props.fieldName) ?? (loadedOptions ? loadedOptions[0] : undefined)
        if (!fieldValue)
            return

        let fullOptFound
        // @FIXME - renato - 24/06/21 - Por algum motivo o valor inicial do campo é retornado com o label do campo e nao o codigo, por isso buscamos pelo nome
        // @FIXME - analisar esse comportamento do formStateManager. Deveria ter retornado aqui o codigo selecionado e nao o nome
        // @FIXME - quando eh passado o initialOpts o retorno eh o codigo mesmo, quando eh utilizado o que esta no FSM ai vem a string
        if (_.isString(fieldValue)) {
            fullOptFound = _.find(loadedOptions, (_selectedOptionFind: IReferralProgramResponseDTO) => _selectedOptionFind.title === fieldValue)
            setSelectedOption(fullOptFound)
            return
        }

        onSelectReferralProgram(fieldValue.code)
    }

    /**
     * Ao selecionar uma origem do lead.
     */
    function onSelectReferralProgram(code: number): void {

        const fullOptFound = _.find(loadedOptions, (_selectedOptionFind: IReferralProgramResponseDTO) => _selectedOptionFind.code === code)
        setSelectedOption(fullOptFound)

        if (!fullOptFound)
            return

        if (!!props.onSelectReferralProgram)
            props.onSelectReferralProgram(fullOptFound)

        if (!!props.formStateManager && !!props.fieldName)
            props.formStateManager.changeFieldValue(props.fieldName, fullOptFound.code)

    }

    return (
        <AutocompletePickerCP<INameAndCodeResponseDTO, FModelTP>
            requestConfigGetter={ReferralProgramRequests.names}
            showOptionsOnLoad={true}
            onChange={onSelectReferralProgram}
            onLoadOptions={options => setLoadedOptions(options)}
            // NOTE: Usando operador 'spread' excepcionalmente para garantir compatibilidade com props extendidas de outro componente
            {...props/* eslint-disable-line react/jsx-props-no-spreading */}
        />
    )
}