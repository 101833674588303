import React from 'react'
import { DescriptionItemCP } from 'common/components/description/description-item/DescriptionItemCP'
import { PlanEnum } from 'submodules/space4leads-sdk/services/accounts/enums/PlanEnum'
import { DescriptionCP } from 'common/components/description/DescriptionCP'
import { SystemConfig } from 'config/SystemConfig'

interface ICPProps {
    plan: PlanEnum
    email: string
    password: string
}

/**
 * Dados de um schema recem criado
 */
export function CreatedSchemaDataICP(props: ICPProps): JSX.Element {

    return (
        <DescriptionCP>
            {
                props.plan &&
                <DescriptionItemCP label={'Plano'}>
                    {props.plan}
                </DescriptionItemCP>
            }
            <DescriptionItemCP label={'E-mail'}>
                {props.email}
            </DescriptionItemCP>
            <DescriptionItemCP label={'Senha'}>
                {props.password}
            </DescriptionItemCP>
            <DescriptionItemCP label={'Url de Acesso'}>
                <a href={SystemConfig.getInstance().uiBaseUrl} target={'_blank'} rel={'noopener noreferrer'}>
                    {SystemConfig.getInstance().uiBaseUrl}
                </a>
            </DescriptionItemCP>
        </DescriptionCP>
    )
}
