import { Layout } from 'antd'
import { ButtonCP } from 'common/components/button/ButtonCP'
import React from 'react'
import { useScreenSize } from 'common/responsiveness/use-screen-size/UseScreenSize'
import { HeaderTitleICP } from 'common/components/screen-layout/header/inner/HeaderTitleICP'
import { HelpCPOLD } from 'common/components/help/HelpCPOLD'
import { LogoCP } from 'common/components/logo/LogoCP'
import { ConditionalRenderCP } from 'common/components/conditional-render/ConditionalRenderCP'
import { FontSizeTP } from 'submodules/nerit-framework-ui/theme/_old/types/ThemeTypes'
import styled, { css } from 'styled-components'

type WrapperSCPPropsTP = {
    noBackground?: boolean,
    titleUnderlineColor?: string,
    titleFlexProp?: number,
}

type CustomPropsTP = {
    title?: string | JSX.Element,
    onClickBack?: () => void,
    titleSize?: FontSizeTP,
    style?: React.CSSProperties,
    tourId?: number,
}

interface IHeaderCPProps extends React.PropsWithChildren<WrapperSCPPropsTP & CustomPropsTP> {
}

/**
 * Componente de cabeçalho do sistema.
 */
export function HeaderCP(props: IHeaderCPProps): JSX.Element {

    const screenSize = useScreenSize()

    return (
        <>
            {
                screenSize.smd &&
                <LogoMobileWrapperSCP>
                    <LogoCP/>
                </LogoMobileWrapperSCP>
            }

            <WrapperSCP
                noBackground={props.noBackground}
                titleUnderlineColor={props.titleUnderlineColor}
                data-tour={'header-cp'}
            >
                <Layout.Header style={props.style}>
                    <LeftContainerSCP titleFlexProp={props.titleFlexProp}>

                        <ConditionalRenderCP shouldRender={!!props.onClickBack}>
                            <ButtonCP
                                onClick={props.onClickBack}
                                ghost={true}
                                type={'primary'}
                                shape={'circle'}
                                icon={'arrow-left'}
                                marginRight={10}
                            />
                        </ConditionalRenderCP>

                        {
                            !!props.title &&
                            <HeaderTitleICP
                                title={props.title}
                                noBackground={props.noBackground}
                                titleSize={props.titleSize}
                            />
                        }

                        {
                            props.tourId &&
                            <HelpCPOLD tourId={props.tourId} dataTour={'header-menuTitle-help'}/>
                        }
                    </LeftContainerSCP>
                    <RightContentSCP>
                        {props.children}
                    </RightContentSCP>

                </Layout.Header>
            </WrapperSCP>
        </>
    )
}

const LogoMobileWrapperSCP = styled.div`
    display: flex;
    background-color: ${props => props.theme.primaryColor};
    justify-content: center;
    padding: 10px;
    
    img {
      height: 50px;
    }
`

const underlineCSS = css<{ titleUnderlineColor?: string }>`
    ::after{
        content: '';
        width: 58px;
        height: 4px;
        position: absolute;
        background: ${props => props.titleUnderlineColor ?? props.theme.primaryColor};
        border-radius: 8px 8px 0 0;
        bottom: 0;
        left: 25px;
    }
`

const WrapperSCP = styled.div<WrapperSCPPropsTP>`

    .ant-layout-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0 25px;
        line-height: normal;
        background: ${props => (!!props.noBackground ? 'transparent' : '#FFF')};
        height: 65px;
    }

    ${props => (!props.noBackground ? 'position: relative;' : '')}
    ${props => (!props.noBackground ? underlineCSS : '')}
`

const LeftContainerSCP = styled.div<WrapperSCPPropsTP>`
    display: flex;
    justify-content: space-between;
    flex: ${props => props.titleFlexProp ?? 'unset'};
`

const RightContentSCP = styled.div<WrapperSCPPropsTP>`
    display: flex;
    justify-content: flex-end;
`
