import { IsNotEmpty } from 'common/validation/decorators/IsNotEmpty'
import { FormModel } from 'common/form-state-manager/FormModel'
import { IsPhoneBR } from 'common/validation/decorators/phone/IsPhoneBR'
import { IsEmail } from 'common/validation/decorators/IsEmail'
import { IReferralProgramSubscribeItemRequestDTO } from 'modules/person/services/dtos/request/IReferralProgramSubscribeItemRequestDTO'

export class ReferralProgramSubscribeFormModel extends FormModel {

    @IsNotEmpty()
    name: string

    @IsNotEmpty()
    @IsEmail()
    email: string

    @IsNotEmpty()
    @IsPhoneBR()
    phone: string

    @IsNotEmpty()
    isTermChecked: boolean

    @IsNotEmpty()
    franchiseCode: number

    friends: IReferralProgramSubscribeItemRequestDTO[]

    constructor(initialData?: any) {
        super(initialData)
        Object.assign(this, initialData)
    }

}
