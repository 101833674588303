import { ChannelEnum } from 'submodules/space4leads-sdk/services/surveys/surveys/enums/ChannelEnum'

/**
 * Paineis colapsaveis de escolha de canais.
 */
export const ChannelUtils = {

    getChannelIconName(channel: ChannelEnum): string | undefined {

        switch (channel) {

            case ChannelEnum.LINK:
                return 'link'

            case ChannelEnum.QRCODE:
                return 'qrcode'

            default:
                return undefined
        }

    },
}
