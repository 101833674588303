export enum NotificationTriggerEnum {
    SUBSCRIBE = 'SUBSCRIBE',
    FIRST_GOAL_CONCLUDED = 'FIRST_GOAL_CONCLUDED',
    GOAL_CONCLUDED = 'GOAL_CONCLUDED',
}

export enum NotificationTriggerLabelEnum {
    SUBSCRIBE = 'No momento da adesão (cadastro)',
    FIRST_GOAL_CONCLUDED = 'Ao atingir a primeira meta',
    GOAL_CONCLUDED = 'Ao atingir todas as metas',
}