import React from 'react'
import { IconICP } from 'common/components/icons/inner/IconICP'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'

interface ICPProps {
    wasAchived: boolean
}

/**
 * Timeline das metas de um cliente.
 */
export function IconGoalStatusCP(props: ICPProps): JSX.Element {

    return (
        <IconICP
            iconName={props.wasAchived ? 'check-circle' : 'clock-circle'}
            theme={'twoTone'}
            color={props.wasAchived ? ThemeAnt.successColor : ThemeAnt.warningColor}
        />
    )
}
