import React from 'react'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { CreateAccountAccessFormModel } from 'modules/admin/components/wizard-create-account/inner/CreateAccountAccessFormModel'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { SelectPlanCP } from 'modules/admin/components/select-plan/SelectPlanCP'

interface ITrialAccessContentStepICPProps {
    formStateManager: IFormStateManager<CreateAccountAccessFormModel>
    email: string
}

/**
 * COMPONENTE da etapa de dados do candidato
 */
export function CreateAccountAccessContentStepICP(props: ITrialAccessContentStepICPProps): JSX.Element | null {

    return (
        <>
            <SelectPlanCP
                formStateManager={props.formStateManager}
                required={true}
                fieldName={'plan'}
            />

            <InputCP
                label={'Nome do Sistema'}
                formStateManager={props.formStateManager}
                required={true}
                fieldName={'accountName'}
                maxlength={15}
                hint={{
                    type: 'text',
                    text: 'O nome do sistema geralmente é o nome da Empresa'
                }}
            />

            <InputCP
                label={'E-mail de Acesso'}
                value={props.email}
                disabled={true}
            />

            <InputCP
                label={'Escolha sua senha'}
                fieldName={'password'}
                formStateManager={props.formStateManager}
                required={true}
                type={'password'}
            />

            <InputCP
                label={'Informe a senha novamente'}
                fieldName={'confirmPassword'}
                formStateManager={props.formStateManager}
                required={true}
                type={'password'}
            />
        </>
    )
}
