import { HttpMethodEnum } from 'common/enums/HttpMethodEnum'
import { RequestConfigTP } from 'common/request-manager/types/RequestConfigTP'
import { SystemConfig } from 'config/SystemConfig'
import { IReferralProgramSubscribeRequestDTO } from 'modules/referral-program/services/referral-program/dtos/requests/IReferralProgramSubscribeRequestDTO'
import { IReferralsSearchRequestDTO } from 'modules/referral-program/services/referral-program/dtos/requests/IReferralsSearchRequestDTO'
import { IReferralProgramSubscribeMultipleRequestDTO } from 'modules/referral-program/services/referral-program/dtos/requests/IReferralProgramSubscribeMultipleRequestDTO'
import { IReferralProgamSaveRequestDTO } from 'modules/referral-program/services/referral-program/dtos/requests/IReferralProgamSaveRequestDTO'

/**
 * Classe auxiliar para realizar requisiçoes do modulo Programas de Indicacao.
 */
export class ReferralProgramRequests {

    private static readonly _MODULE_BASE = `${SystemConfig.getInstance().apiBaseUrl}/referral-programs`

    static findByCode = (code: number): RequestConfigTP => ({
        url: `${ReferralProgramRequests._MODULE_BASE}/${code}`,
        method: HttpMethodEnum.GET,
    })

    static findBySlug = (slug: string): RequestConfigTP => ({
        url: `${ReferralProgramRequests._MODULE_BASE}/slug/${slug}`,
        method: HttpMethodEnum.GET,
    })

    static search = (): RequestConfigTP => ({
        url: `${ReferralProgramRequests._MODULE_BASE}/`,
        method: HttpMethodEnum.GET,
    })

    static names = (): RequestConfigTP => ({
        url: `${ReferralProgramRequests._MODULE_BASE}/names`,
        method: HttpMethodEnum.GET,
    })

    static subscribe = (slug: string, dto: IReferralProgramSubscribeRequestDTO): RequestConfigTP => ({
        url: `${ReferralProgramRequests._MODULE_BASE}/${slug}/subscribe`,
        method: HttpMethodEnum.POST,
        params: dto
    })

    static subscribeMultiple = (slug: string, dto: IReferralProgramSubscribeMultipleRequestDTO): RequestConfigTP => ({
        url: `${ReferralProgramRequests._MODULE_BASE}/${slug}/subscribe-multiple`,
        method: HttpMethodEnum.POST,
        params: dto
    })

    static getReferrals = (referralProgramCode: number, dto: IReferralsSearchRequestDTO): RequestConfigTP => ({
        url: `${ReferralProgramRequests._MODULE_BASE}/${referralProgramCode}/referrals`,
        method: HttpMethodEnum.GET,
        params: dto,
    })

    static save = (dto: IReferralProgamSaveRequestDTO): RequestConfigTP => ({
        url: `${ReferralProgramRequests._MODULE_BASE}/`,
        method: HttpMethodEnum.POST,
        params: dto
    })

    static update = (code: number, dto: IReferralProgamSaveRequestDTO): RequestConfigTP => ({
        url: `${ReferralProgramRequests._MODULE_BASE}/${code}`,
        method: HttpMethodEnum.PUT,
        params: dto
    })

    static toogleActive = (code: number): RequestConfigTP => ({
        url: `${ReferralProgramRequests._MODULE_BASE}/${code}/toogle-active`,
        method: HttpMethodEnum.PUT,
    })

    static delete = (code: number): RequestConfigTP => ({
        url: `${ReferralProgramRequests._MODULE_BASE}/${code}`,
        method: HttpMethodEnum.DELETE,
    })

    static getNotifications = (code: number): RequestConfigTP => ({
        url: `${ReferralProgramRequests._MODULE_BASE}/${code}/notifications`,
        method: HttpMethodEnum.GET,
    })

}
