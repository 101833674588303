import {
    registerDecorator,
    ValidationOptions,
    Validator,
    ValidatorConstraint,
    ValidatorConstraintInterface,
} from 'class-validator'

import { ValidationTypes } from 'common/validation/ValidationTypes'

@ValidatorConstraint({ name: ValidationTypes.NOT_CONTAINS })
class IsStringConstraint implements ValidatorConstraintInterface {

    validate(value: string): boolean {
        const validator = new Validator()
        return validator.notContains(value, ' ')
    }

    defaultMessage(): string {
        return 'Valor não deve conter espaço!'
    }
}

/**
 * DECORATOR de VALIDACAO
 * Valida SE 01 string NAO contem espacos.
 *
 * @author guilherme.diniz
 * @author hjcostabr
 */
export function NotContainsSpace(validationOptions?: ValidationOptions) {
    return (object: {}, propertyName: string) => {
        registerDecorator({
            target: object.constructor,
            propertyName,
            options: validationOptions,
            constraints: [],
            validator: IsStringConstraint,
        })
    }
}
