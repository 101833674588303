import cellImg from 'common/components/sms-preview/inner/cel-app.png'
import React from 'react'
import styled from 'styled-components'

export const SMS_INNER_CLASS = 'sms-inner'
export const SMS_IMAGE_CLASS = 'sms-image'
export const SMS_TEXT_BOX_CLASS = 'text-box'

interface ISmsPreviewCPProps {
    text?: string
}

/**
 * Componente que exibe uma imagem de celular com texto da msg de sms
 *
 * @author renatofs
 */
export function SmsPreviewCP(props: ISmsPreviewCPProps): JSX.Element {

    return (
        <SmsPreviewSCP>
            <div className={SMS_INNER_CLASS}>
                <div className={SMS_IMAGE_CLASS}>
                    <img src={cellImg}/>
                    <div className={SMS_TEXT_BOX_CLASS}>
                        <p>{props.text}</p>
                    </div>
                </div>
            </div>
        </SmsPreviewSCP>
    )

}

const SmsPreviewSCP = styled.div`
    width: 100%;

    .${SMS_INNER_CLASS} {
        display: flex;
        justify-content: center;

        .${SMS_IMAGE_CLASS} {
            position: relative;

            .${SMS_TEXT_BOX_CLASS} {
                position: absolute;
                top: 22%;
                left: 18%;
                width: 223px; // height: 207px;
                height: auto;
                border-radius: 10px;
                background-color: #efefef;
                padding: 10px;
                white-space: -moz-pre-wrap !important;
                /* Mozilla, since 1999 */
                white-space: -webkit-pre-wrap;
                /*Chrome & Safari */
                white-space: -pre-wrap;
                /* Opera 4-6 */
                white-space: -o-pre-wrap;
                /* Opera 7 */
                white-space: pre-wrap;
                /* css-3 */
                word-wrap: break-word;
                /* Internet Explorer 5.5+ */
                word-break: break-all;
                white-space: normal;
            }
        }
    }
`
