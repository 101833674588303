import React, { useEffect, useState } from 'react'
import { CardCP } from 'submodules/nerit-framework-ui/common/components/card/CardCP'
import { DescriptionAccountDataCP } from 'modules/admin/components/description-account-data/DescriptionAccountDataCP'
import { AppStateUtils } from 'common/utils/AppStateUtils'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { AccountResponseDTO } from 'submodules/space4leads-sdk/services/accounts/dtos/responses/AccountResponseDTO'
import { AccountsRequests } from 'submodules/space4leads-sdk/services/accounts/AccountsRequests'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { TableProjectsCP } from 'modules/admin/components/table-projects/TableProjectsCP'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'
import { TitleCP } from 'submodules/nerit-framework-ui/common/components/title/TitleCP'

/**
 * Tela de detalhes da conta.
 */
export function ScreenContentAccountDetails(): JSX.Element {

    const [currentAccount, setCurrentAccount] = useState<AccountResponseDTO>()
    const getCurrentAccountRequest = useRequest<AccountResponseDTO>()
    useEffect(onGetCurrentAccountRequestChange, [getCurrentAccountRequest.isAwaiting])

    useEffect(init, [])

    /**
     * Inicializa dados da tela
     */
    function init(): void {
        getCurrentAccountRequest.runRequest(AccountsRequests.getAccount(AppStateUtils.getCurrentProject()!.accountCode))
    }

    /**
     * Retorno da requisicao
     */
    function onGetCurrentAccountRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getCurrentAccountRequest, 'Erro ao obter a conta atual'))
            return

        setCurrentAccount(getCurrentAccountRequest.responseData)
    }

    if (!currentAccount)
        return <LoadingOverlayCP show={getCurrentAccountRequest.isAwaiting}/>

    return (
        <>
            <CardCP
                innerSpacing={'none'}
                bordered={false}
            >
                <DescriptionAccountDataCP
                    account={currentAccount}
                />
            </CardCP>

            <TitleCP
                marginTop={40}
                marginLeft={22}
                underLine={true}
            >
                Projetos vinculados à essa conta
            </TitleCP>
            <TableProjectsCP
                wrappedOnCard={true}
                accountCode={currentAccount.code}
            />
        </>
    )
}
