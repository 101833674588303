import { EnvironmentEnum } from 'common/enums/EnvironmentEnum'

/**
 * Classe de configuracao do sistema.
 */
export class SystemConfig {

    readonly appName = 'StarNPS'
    readonly defaultTimeZone = 'America/Sao_Paulo'
    readonly environment = process.env.REACT_APP_STAGE as EnvironmentEnum

    readonly reduxEncryptKey = 'cmVybXRzLWNyZXRrZXk='

    readonly apiBaseUrl: string
    readonly uiBaseUrl: string

    readonly anonymousUserToken: string

    readonly space4LeadsOwnSurveyToken: string
    readonly space4LeadsOwnProjectCode: number

    private static _instance: SystemConfig

    private constructor() {
        switch (this.environment) {
            case EnvironmentEnum.DEV:
                this.uiBaseUrl = 'http://localhost:3000'
                this.apiBaseUrl = 'http://localhost:3005'
                this.anonymousUserToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyQ29kZSI6MSwiaWF0IjoxNjE1NTEyMTI5fQ.pIKmo38fGEyiwuiM4v2L7yG3MK7oLWpsnA8eEvfJXGU'
                this.space4LeadsOwnSurveyToken = 'MTY4'
                this.space4LeadsOwnProjectCode = 113
                break

            case EnvironmentEnum.PROD:
                this.uiBaseUrl = 'https://nps.lumiacrm.com.br'
                this.apiBaseUrl = 'https://app.lumiacrm.com.br/star'
                this.anonymousUserToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyQ29kZSI6MSwiaWF0IjoxNjA2OTE3NTkyfQ.iLFIyoyHh0BP17h53cip13fwkfH7j4tv1iNKAJBUd34'
                this.space4LeadsOwnSurveyToken = 'MTY4'
                this.space4LeadsOwnProjectCode = 113
                break

            case EnvironmentEnum.HOM:
            case EnvironmentEnum.TEST:
            default:
                throw new Error('Ambiente não configurado!')
        }
    }

    static getInstance(): SystemConfig {
        if (!this._instance)
            this._instance = new SystemConfig()
        return this._instance
    }
}
