import React from 'react'
import { TableCP } from 'common/components/table/TableCP'
import { TableReferFriendsUtils } from 'modules/referral-program/components/table-refer-friends/inner/TableReferFriendsUtils'
import { IReferralProgramSubscribeItemRequestDTO } from 'modules/person/services/dtos/request/IReferralProgramSubscribeItemRequestDTO'
import styled from 'styled-components'

interface ICPProps {
    friends: IReferralProgramSubscribeItemRequestDTO[]
    onChange: (index: number, name?: string, phone?: string) => void
    onDelete: (index: number) => void
}

/**
 * Tabela com as indicacoes de um cliente.
 */
export function TableReferFriendsCP(props: ICPProps): JSX.Element {

    return (
        <WrapperSCP>
            <TableCP
                columns={TableReferFriendsUtils.getTableColumns(props.onChange, props.onDelete)}
                data={props.friends}
            />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`

  td {
    border: none !important;
  }
  
  .wrapper-input {
    margin-bottom: 0px !important;
  }
`
