import { FormModel } from 'common/form-state-manager/FormModel'
import React, { useEffect, useState } from 'react'
import { AutocompletePickerCP } from 'common/components/form-fields/autocomplete-picker/AutocompletePickerCP'
import { INameAndCodeResponseDTO } from 'common/dtos/responses/INameAndCodeResponseDTO'
import { IAutocompleteCommonProps } from 'common/components/form-fields/autocomplete-picker/inner/IAutocompleteCommonProps'
import { EventRequests } from 'modules/referral-program/services/event/EventRequests'
import { ModalEventCP } from 'modules/referral-program/components/modal-event/ModalEventCP'
import { SystemUtils } from 'common/utils/SystemUtils'

interface ICPProps<FModelTP extends FormModel = any> extends IAutocompleteCommonProps<INameAndCodeResponseDTO, FModelTP> {
    label: string
}

/**
 * Autocomplete de metas.
 */
export function AutocompleteEventCP<FModelTP extends FormModel = any>(props: ICPProps<FModelTP>): JSX.Element {

    const [isEventModalVisible, setIsEventModalVisible] = useState<boolean>(false)
    const [selectedName, setSelectedName] = useState<string>()
    const [initialOptions, setInitialOptions] = useState<INameAndCodeResponseDTO[]>()
    const [shouldClearSearchStr, setShouldClearSearchStr] = useState<boolean>(true)

    useEffect(() => setInitialOptions(props.initialOptions), [props.initialOptions])

    /**
     * Ao clicar em adicionar nova pessoa.
     */
    function onAdd(name: string): void {
        setSelectedName(name)
        setIsEventModalVisible(true)
    }

    /**
     * Retorno ao adicionar pessoa.
     */
    function onEventAdded(event: INameAndCodeResponseDTO): void {

        setIsEventModalVisible(false)
        if (!!props.formStateManager && !!props.fieldName) {

            let eventCodes = props.formStateManager.getFieldValue(props.fieldName) as number[]
            if (SystemUtils.isEmpty(eventCodes))
                eventCodes = [event.code]
            else
                eventCodes.push(event.code)

            props.formStateManager.changeFieldValue(props.fieldName, eventCodes)
        }

        const addedEvent: INameAndCodeResponseDTO = {
            code: event.code,
            name: event.name
        }

        setInitialOptions([addedEvent])
        setShouldClearSearchStr(true)
    }

    return (
        <>
            <AutocompletePickerCP<INameAndCodeResponseDTO, FModelTP>
                requestConfigGetter={EventRequests.search}
                showOptionsOnLoad={true}
                showFixedAddButton={true}
                isMultiple={true}
                onAdding={onAdd}
                mustClearSearch={shouldClearSearchStr}
                onSearchCleared={() => setShouldClearSearchStr(false)}
                initialOptions={initialOptions}

                // NOTE: Usando operador 'spread' excepcionalmente para garantir compatibilidade com props extendidas de outro componente
                {...props/* eslint-disable-line react/jsx-props-no-spreading */}
            />

            <ModalEventCP
                visible={isEventModalVisible}
                onCancel={() => setIsEventModalVisible(false)}
                onSave={onEventAdded}
                initialName={selectedName}
            />
        </>
    )
}