import { TablePaginationTP } from 'common/components/table/TableCP'

/**
 * Utilitarios gerais do componente de tabela
 */
export const TableUtils = {

    getDefaultPagination(pageSize?: number): TablePaginationTP {
        return {
            current: 1,
            pageSize: pageSize ?? 50,
            total: 0,
            showTotal: (total, range) => `Mostrando ${range.join('-')} de ${total}`
        }
    },

}
