import React from 'react'
import { RadioGroupCP } from 'common/components/form-fields/radio-group/RadioGroupCP'

export type RadioGroupCustomerReferralsViewTP = 'referralsView' | 'timelineView'

interface ICPProps {
    selectedView: RadioGroupCustomerReferralsViewTP
    onChangeView: (view: RadioGroupCustomerReferralsViewTP) => void
}

/**
 * Conteudo da Landing Page para captar respostas
 */
export function RadioGroupCustomerReferralsViewCP(props: ICPProps): JSX.Element {

    return (
        <RadioGroupCP<RadioGroupCustomerReferralsViewTP>
            selected={props.selectedView}
            buttonHeight={32}
            buttonWidth={120}
            paddingTop={0}
            onChange={props.onChangeView}
            options={[
                {
                    value: 'timelineView',
                    content: 'Prêmios'
                },
                {
                    value: 'referralsView',
                    content: 'Indicações'
                }
            ]}
        />
    )
}
