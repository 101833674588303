import { DateUtils } from 'common/utils/date/DateUtils'
import { DateFormatEnum } from 'common/enums/DateFormatEnum'
import { IDateRangeSearchRequestDTO } from 'common/dtos/requests/IDateRangeSearchRequestDTO'
import { IDateRangeFilter } from 'common/components/date-range-picker/inner/IDateRangeFilter'

/**
 * UTILS para manipular dtos.
 */
export const DtoUtils = {

    convertToDateRangeDto(dateRange?: IDateRangeFilter): IDateRangeSearchRequestDTO | undefined {

        if (!dateRange || (!dateRange.beginDate && !dateRange.endDate))
            return

        return {
            beginDate: dateRange?.beginDate ? DateUtils.getFormatted(dateRange.beginDate, DateFormatEnum.US_WITHOUT_TIME) : undefined,
            endDate: dateRange?.endDate ? DateUtils.getFormatted(dateRange.endDate, DateFormatEnum.US_WITHOUT_TIME) : undefined,
        }
    },

}
