import { UserMainLayoutCP } from 'modules/user/components/user-main-layout/UserMainLayoutCP'
import React from 'react'
import { AuthUtils } from 'common/utils/AuthUtils'
import { RoutingHelper } from 'config/RoutingHelper'
import { AuthRoutes } from 'modules/auth/AuthRoutes'

/**
 * Determina direcionamento apropriado para definacao de rotas / telas
 * de acordo o tipo de pessoa logada atualmente (se houver alguma).
 */
export function PrivateAccessVerifierCP(): JSX.Element {
    return AuthUtils.isUserLogged() ? <UserMainLayoutCP/> : RoutingHelper.renderRedirect(AuthRoutes.USER_LOGIN)
}
