import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import { InputMaskTypeEnum } from 'common/enums/InputMaskTypeEnum'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { IconICP } from 'common/components/icons/inner/IconICP'
import { CustomerLoginFormModel } from 'modules/referral-program/components/customer-login-form/inner/CustomerLoginFormModel'
import { useFormStateManager } from 'common/form-state-manager/UseFormStateManager'
import { useRequest } from 'common/request-manager/use-request/UseRequest'
import { ICustomerPersonByReferralProgramResponseDTO } from 'modules/person/services/dtos/response/ICustomerPersonByReferralProgramResponseDTO'
import { IPersonSearchByReferralProgramRequestDTO } from 'modules/person/services/dtos/request/IPersonSearchByReferralProgramRequestDTO'
import { PersonRequests } from 'modules/person/services/PersonRequests'
import { NotificationHelper } from 'common/helpers/NotificationHelper'
import { ReferralProgramRoutes } from 'modules/referral-program/routes/ReferralProgramRoutes'
import { useLocation } from 'react-router'
import { IReferralProgramResponseDTO } from 'modules/referral-program/services/referral-program/dtos/responses/IReferralProgramResponseDTO'
import { ReferralProgramUtils } from 'modules/referral-program/utils/ReferralProgramUtils'

interface IScreenContentCustomerReferralsLoginLPProps {
    onLoadCustomer: (customer: ICustomerPersonByReferralProgramResponseDTO) => void
    referralProgram: IReferralProgramResponseDTO
}

/**
 * Conteudo da Landing Page para logar o usuario
 */
export function CustomerLoginFormCP(props: IScreenContentCustomerReferralsLoginLPProps): JSX.Element {

    const routeLocation = useLocation()

    const [formValidator] = useState<CustomerLoginFormModel>((new CustomerLoginFormModel()))
    const formStateManager = useFormStateManager<CustomerLoginFormModel>(formValidator)

    const getCustomerRequest = useRequest<ICustomerPersonByReferralProgramResponseDTO>()
    useEffect(onGetCustomerRequestChange, [getCustomerRequest.isAwaiting])

    useEffect(init, [])

    /**
     * Inicializa.
     */
    function init(): void {

        // Token de quem indicou
        const query = new URLSearchParams(routeLocation.search)
        const emailParam = query.get(ReferralProgramRoutes.CUSOMER_REFERRALS_EMAIL_PARAM)
        const phoneParam = query.get(ReferralProgramRoutes.CUSOMER_REFERRALS_PHONE_PARAM)

        if (!!emailParam && !!phoneParam)
            loadPersonFromApi(phoneParam, emailParam)
    }

    /**
     * Busca cliente com as informacoes digitadas.
     */
    async function doCustomerLogin(): Promise<void> {

        formStateManager.setConsiderAllErrors(true)
        if (!await formStateManager.validate())
            return

        const formValues = formStateManager.getFormValues()
        if (!formValues)
            return

        // O doLogin manda para a propria url com os dados de parametro, quando receber ira de fato verificar os dados que foram informados
        // Coloca os dados na url para quando der um refresh nao precisar logar novamente
        const urlParams = `?${ReferralProgramRoutes.CUSOMER_REFERRALS_PHONE_PARAM}=${formValues.phone}&${ReferralProgramRoutes.CUSOMER_REFERRALS_EMAIL_PARAM}=${formValues.email}`
        window.top.location.href = `${ReferralProgramUtils.getCustomerDashboardUrl(props.referralProgram)}${urlParams}`
    }

    /**
     * Chama api para buscar pessoa.
     */
    function loadPersonFromApi(phone: string, email: string): void {

        const dto: IPersonSearchByReferralProgramRequestDTO = {
            email,
            phone,
            referralProgramCode: props.referralProgram.code,
        }

        getCustomerRequest.runRequest(PersonRequests.search(dto))
    }

    /**
     * Retorno da requisicao para pegar agenda passada como parametro
     */
    function onGetCustomerRequestChange(): void {

        if (getCustomerRequest.isAwaiting || !getCustomerRequest.wasTried)
            return

        const isSuccess = (getCustomerRequest.isSuccess && !!getCustomerRequest.responseData)
        if (!isSuccess) {
            NotificationHelper.error('Ops!', 'Não encontramos ninguém com esses dados')
            return
        }

        const responseDTO = getCustomerRequest.responseData!
        props.onLoadCustomer(responseDTO)
    }

    return (
        <FormSCP>
            <InputCP
                label={'Telefone'}
                fontSize={'extraLarge'}
                mask={InputMaskTypeEnum.PHONE}
                icon={<IconICP iconName={'phone'}/>}
                formStateManager={formStateManager}
                fieldName={'phone'}
                required={true}
            />
            <InputCP
                label={'E-mail'}
                fontSize={'extraLarge'}
                type={'email'}
                icon={<IconICP iconName={'mail'}/>}
                formStateManager={formStateManager}
                fieldName={'email'}
                required={true}
            />

            <ButtonsSCP>
                <ButtonCP
                    onClick={() => ReferralProgramUtils.redirectOrHistoryPush(ReferralProgramUtils.getSubscribeUrl(props.referralProgram))}
                    type={'ghost'}
                >
                    Quero me Cadastrar
                </ButtonCP>
                <ButtonCP
                    icon={'login'}
                    onClick={doCustomerLogin}
                    loading={getCustomerRequest.isAwaiting}
                >
                    Entrar
                </ButtonCP>
            </ButtonsSCP>
        </FormSCP>
    )
}

const FormSCP = styled.div`
  margin-top: 30px;
  
  @media (min-width: 600px) {
    width: 350px;
  }
  
  @media (max-width: 599px) {
    width: 100%;
  }
   
`

const ButtonsSCP = styled.div`
  
  display: flex;
  align-items: center;

  @media (min-width: 600px) {
    justify-content: space-between;
  }

  @media (max-width: 599px) {
    flex-direction: column-reverse;
    justify-content: center;
    
    button {
      margin-bottom: 20px;
    }
  }
`
