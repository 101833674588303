import React from 'react'
import { LayoutSubmenuCP } from 'common/components/screen-layout-submenu/layout/LayoutSubmenuCP'
import { ReportsViewTP } from 'modules/reports/screens/reports/inner/ReportsViewTP'
import { MULTI_PROJECTS_SUBMENU_KEY, REFERRAL_PROGRAMS_SUBMENU_KEY, ReportsScreenUtils, SURVEYS_SUBMENU_KEY, } from 'modules/reports/screens/reports/inner/ReportsScreenUtils'

/**
 * TELA do modulo Relatorios
 */
export function ReportsScreen(): JSX.Element {

    return (
        <LayoutSubmenuCP<ReportsViewTP>
            defaultSubmenu={'npsAnswersReportView'}
            menuTitle={'Relatórios'}
            submenuConfigs={ReportsScreenUtils.getSubMenuConfigs()}
            defaultOpenKeys={[REFERRAL_PROGRAMS_SUBMENU_KEY, SURVEYS_SUBMENU_KEY, MULTI_PROJECTS_SUBMENU_KEY]}
        />
    )
}
