import React from 'react'
import { IFormStateManager } from 'common/form-state-manager/IFormStateManager'
import { ReferralProgramThanksFormModel } from 'modules/referral-program/components/wizard-referral-program-config/inner/models/ReferralProgramThanksFormModel'
import { TitleCP } from 'common/components/title/TitleCP'
import { TinyMceCP } from 'common/components/wysiwyg/tiny-mce/TinyMceCP'
import styled from 'styled-components'
import { TextAreaCP } from 'common/components/form-fields/text-area/TextAreaCP'

interface ICPProps {
    formStateManager: IFormStateManager<ReferralProgramThanksFormModel>
}

/**
 * COMPOENENT Etapa de configuracoes do agradecimento.
 */
export function ReferralProgramThanksStepICP(props: ICPProps): JSX.Element {

    return (
        <>
            <MsgWrapperSCP>
                <TitleCP underLine={true} textSize={'normal'}>
                    Mensagem de agradecimento
                </TitleCP>
                <TinyMceCP
                    onEditorContentChange={(text) => props.formStateManager.changeFieldValue('thanksMessage', text)}
                    editorContent={props.formStateManager.getFieldValue('thanksMessage')}
                />
            </MsgWrapperSCP>

            <TextAreaCP
                label={'Mensagem padrão do Whatsapp'}
                required={true}
                formStateManager={props.formStateManager}
                fieldName={'inviteMessage'}
                hint={{ type: 'text', text: 'Texto padrão para ser enviado pelo whatsapp quando a pessoa for enviar aos seus amigos' }}
            />
        </>
    )
}

const MsgWrapperSCP = styled.div`
    margin-bottom: 30px;
`
