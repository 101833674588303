import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { Space4LeadsSystemApiEnum } from 'submodules/space4leads-sdk/common/Space4LeadsSystemApiEnum'
import { UserChangePasswordRequestDTO } from 'submodules/space4leads-sdk/services/people/user/dtos/requests/UserChangePasswordRequestDTO'

export class UserRequests {

    static CONTROLLER_ROOT = 'users'
    static CHANGE_PASSWORD_EP = 'change-password/:code'
    static RESET_PASSWORD_EP = 'reset-password'

    static changePassword = (code: number, dto: UserChangePasswordRequestDTO): RequestConfigTP => ({
        baseUrlType: Space4LeadsSystemApiEnum.SPACE4LEADS_API,
        url: `${UserRequests.CONTROLLER_ROOT}/${UserRequests.CHANGE_PASSWORD_EP.replace(':code', code.toString())}`,
        method: HttpMethodEnum.PUT,
        params: dto,
    })

    static resetPassword = (email: string): RequestConfigTP => ({
        baseUrlType: Space4LeadsSystemApiEnum.SPACE4LEADS_API,
        url: `${UserRequests.CONTROLLER_ROOT}/${UserRequests.RESET_PASSWORD_EP}`,
        method: HttpMethodEnum.PUT,
        params: { email }
    })

}
