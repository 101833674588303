import React from 'react'
import { ConditionalRenderCP } from 'common/components/conditional-render/ConditionalRenderCP'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { FallbackScreenContentCP } from 'common/components/fallback-screen-content/FallbackScreenContent'
import { FlexCP } from 'common/components/flex/FlexCP'

interface ISurveyThanksCPProps {
    thanksMessage: string
    thanksCtaUrl?: string
    thanksCtaLabel?: string
    disabled: boolean
}

/**
 * Componente apos ser respondido a pesquisa
 */
export function SurveyThanksCP(props: ISurveyThanksCPProps): JSX.Element {

    return (
        <FallbackScreenContentCP
            status={'success'}
            message={props.thanksMessage}
            bottomContent={
                <ConditionalRenderCP shouldRender={!!props.thanksCtaLabel && !!props.thanksCtaUrl}>
                    <FlexCP justifyContent={'center'}>
                        <ButtonCP
                            onClick={() => window.open(props.thanksCtaUrl)}
                            type={'primary'}
                            disabled={props.disabled}
                        >
                            {props.thanksCtaLabel}
                        </ButtonCP>
                    </FlexCP>
                </ConditionalRenderCP>
            }
        />
    )
}
