import React, { useEffect, useState } from 'react'
import { IReferralProgramSubscribeItemRequestDTO } from 'modules/person/services/dtos/request/IReferralProgramSubscribeItemRequestDTO'
import { NotificationHelper } from 'common/helpers/NotificationHelper'
import { ModalCP } from 'common/components/modal/ModalCP'
import { StepReferFriendsICP } from 'modules/referral-program/components/wizard-referral-program-subscribe/steps/StepReferFriendsICP'
import { ReferralProgramSubscribeFormModel } from 'modules/referral-program/components/wizard-referral-program-subscribe/models/ReferralProgramSubscribeFormModel'
import { useFormStateManager } from 'common/form-state-manager/UseFormStateManager'
import { ICustomerPersonByReferralProgramResponseDTO } from 'modules/person/services/dtos/response/ICustomerPersonByReferralProgramResponseDTO'
import { useRequest } from 'common/request-manager/use-request/UseRequest'
import { SystemUtils } from 'common/utils/SystemUtils'
import { IReferralProgramSubscribeMultipleRequestDTO } from 'modules/referral-program/services/referral-program/dtos/requests/IReferralProgramSubscribeMultipleRequestDTO'
import { TableReferFriendsUtils } from 'modules/referral-program/components/table-refer-friends/inner/TableReferFriendsUtils'
import { ReferralProgramRequests } from 'modules/referral-program/services/referral-program/ReferralProgramRequests'
import { RequestUtils } from 'common/request-manager/RequestUtils'

interface ICPProps {
    visible: boolean
    customer: ICustomerPersonByReferralProgramResponseDTO
    referralProgramSlug: string
    onClose: (dataChanged?: boolean) => void
}

/**
 * Modal para indicar vários amigos.
 */
export function ModalReferMultipleFriendsCP(props: ICPProps): JSX.Element {

    const [formValidator, setFormValidator] = useState<ReferralProgramSubscribeFormModel>((new ReferralProgramSubscribeFormModel()))
    const formStateManager = useFormStateManager<ReferralProgramSubscribeFormModel>(formValidator)

    const subscribeMultipleRequest = useRequest('none')
    useEffect(onSubscribeMultipleRequestChange, [subscribeMultipleRequest.isAwaiting])

    useEffect(init, [props.visible])

    /**
     * Inicializa.
     */
    function init(): void {

        setFormValidator(new ReferralProgramSubscribeFormModel())
        if (!props.visible)
            return

        // Começa com 5 linhas já abertas para indicação
        const initialFriendsFields: IReferralProgramSubscribeItemRequestDTO[] = []
        for (let i = 0; i < 5; i++)
            initialFriendsFields.push({ name: '', phone: '' })

        setFormValidator(new ReferralProgramSubscribeFormModel({
            friends: initialFriendsFields
        }))
    }

    /**
     * Ao terminar o wizard
     */
    function subscribeMultiple(): void {

        if (!TableReferFriendsUtils.isFilledRowsValid(formStateManager.getFieldValue('friends'))) {
            NotificationHelper.error('Ops!', 'Um ou mais dados estão inválidos. Verifique se o telefone e nome estão preenchidos corretamente')
            return
        }

        const filledRows = TableReferFriendsUtils.getFilledRows(formStateManager.getFieldValue('friends'))
        if (SystemUtils.isEmpty(filledRows)) {
            NotificationHelper.error('Ops!', 'Você deve indicar ao menos um amigo para participar do programa')
            return
        }

        const dto: IReferralProgramSubscribeMultipleRequestDTO = {
            persons: filledRows,
            franchiseCode: props.customer.referralFranchise.code,
            referredByToken: props.customer.token
        }

        subscribeMultipleRequest.runRequest(ReferralProgramRequests.subscribeMultiple(props.referralProgramSlug, dto))
    }

    /**
     * Apos reotorno da api de salvar.
     */
    function onSubscribeMultipleRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(subscribeMultipleRequest, 'Ocorreu algun erro ao salvar o cadastro', true))
            return

        NotificationHelper.info('Yes', 'Seus amigos foram indicados com sucesso')
        props.onClose(true)
    }

    return (
        <ModalCP
            visible={props.visible}
            top={5}
            onOk={subscribeMultiple}
            onCancel={props.onClose}
            destroyOnClose={true}
            title={'Indicar Amigos'}
            loading={subscribeMultipleRequest.isAwaiting}
        >

            <StepReferFriendsICP formStateManager={formStateManager}/>

        </ModalCP>
    )
}
