import React, { useEffect } from 'react'
import { CardDashboardWidgetCP } from 'modules/dashboard/components/card-dashboard-widget/CardDashboardWidgetCP'
import { TitleCP } from 'common/components/title/TitleCP'
import styled from 'styled-components'
import { useRequest } from 'common/request-manager/use-request/UseRequest'
import { IGenericListResponseDTO } from 'common/dtos/responses/IGenericListResponseDTO'
import { IWidgetResponseDTO } from 'modules/dashboard/services/dtos/response/IWidgetResponseDTO'
import { IReferralProgramStatusReportRequestDTO } from 'modules/reports/services/referral-program-reports/dtos/requests/IReferralProgramStatusReportRequestDTO'
import { ReferralProgramReportRequests } from 'modules/reports/services/referral-program-reports/ReferralProgramReportRequests'
import { RequestUtils } from 'common/request-manager/RequestUtils'
import { LoadingCP } from 'common/components/loading/LoadingCP'
import { ColumnCP } from 'common/components/grid/ColumnCP'
import { IFormStateManager } from 'common/form-state-manager/IFormStateManager'
import { ReportsCommonFiltersFormModel } from 'modules/reports/components/drawer-referral-program-report-filters/inner/ReportsCommonFiltersFormModel'
import { DtoUtils } from 'common/dtos/DtoUtils'

interface ICPProps {
    filters: IFormStateManager<ReportsCommonFiltersFormModel>
    onLoadReport: () => void
    shouldLoadReport: boolean
}

/**
 * Analise do programa de indicacao
 */
export function WidgetsRowGoalCP(props: ICPProps): JSX.Element {

    const getGoalsPerformedReportRequest = useRequest<IGenericListResponseDTO<IWidgetResponseDTO>>()
    useEffect(onGetGoalsPerformedReportRequestChange, [getGoalsPerformedReportRequest.isAwaiting])

    useEffect(init, [props.shouldLoadReport])

    /**
     * Requisicao API.
     */
    function init(): void {

        if (!props.shouldLoadReport)
            return

        const filterValues = props.filters.getFormValues()
        const requestDto: IReferralProgramStatusReportRequestDTO = {
            dateRange: DtoUtils.convertToDateRangeDto(filterValues?.dateRange),
            referralProgramCode: filterValues?.referralProgramCode,
            franchiseCodes: filterValues?.franchisesCodes,
        }

        getGoalsPerformedReportRequest.runRequest(ReferralProgramReportRequests.getGoalsPerformedReport(requestDto))
    }

    /**
     * Retorno da requisicao.
     */
    function onGetGoalsPerformedReportRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getGoalsPerformedReportRequest, 'Não foi possível obter dados do relatório'))
            return

        props.onLoadReport()
    }

    return (
        <GoalsWrapperSCP>
            <TitleCP underLine={true} textSize={'normal'} marginBottom={30}>Alcance das Metas</TitleCP>
            <LoadingCP show={getGoalsPerformedReportRequest.isAwaiting}/>
            <>
                {
                        getGoalsPerformedReportRequest.responseData?.list.map((goalsPerformed) => (
                            <ColumnCP size={12}>
                                <ItemWrapperSCP>
                                    <CardDashboardWidgetCP
                                        widgetResponseDto={goalsPerformed}
                                        targetLabel={'Total'}
                                    />
                                </ItemWrapperSCP>
                            </ColumnCP>
                        ))
                }
            </>
        </GoalsWrapperSCP>
    )
}

const GoalsWrapperSCP = styled.div`
  margin-top: 40px;
`

const ItemWrapperSCP = styled.div`
  margin-bottom: 20px;
`
