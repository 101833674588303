import React, { useEffect, useState } from 'react'
import { HeaderCP } from 'common/components/screen-layout/header/HeaderCP'
import { SurveyConfigCP } from 'modules/survey/components/survey-config/SurveyConfigCP'
import { SurveyTypeEnum } from 'submodules/space4leads-sdk/services/surveys/surveys/enums/SurveyTypeEnum'
import { RoutingHelper } from 'config/RoutingHelper'
import { CsatRoutes } from 'modules/survey/routes/CsatRoutes'
import { SurveyResponseDTO } from 'submodules/space4leads-sdk/services/surveys/surveys/dtos/response/SurveyResponseDTO'
import { useRequest } from 'common/request-manager/use-request/UseRequest'
import { SurveyRequests } from 'modules/survey/services/survey/SurveyRequests'
import { RequestUtils } from 'common/request-manager/RequestUtils'
import { useParams } from 'react-router-dom'
import { LoadingOverlayCP } from 'common/components/loading/loading-overlay/LoadingOverlayCP'
import { PermissionEnum } from 'submodules/space4leads-sdk/services/people/person/enums/PermissionEnum'
import { AccessControlCP } from 'common/components/access-control/AccessControlCP'

/**
 * TELA do modulo CSAT.
 */
export function ScreenCsatConfig(): JSX.Element {

    const routeParams = useParams<{ code?: string }>()

    const [survey, setSurvey] = useState<SurveyResponseDTO>()

    const getSurveyRequest = useRequest<SurveyResponseDTO>()
    useEffect(onGetSurveyRequestChange, [getSurveyRequest.isAwaiting])

    useEffect(init, [])

    /**
     * Inicializa dados da tela
     */
    function init(): void {

        if (!routeParams.code)
            return

        getSurveyRequest.runRequest(SurveyRequests.getOne(Number(routeParams.code)))
    }

    /**
     * Retorno da requisicao para pegar campanha de CSAT
     */
    function onGetSurveyRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getSurveyRequest, 'Erro obter campanha de CSAT'))
            return

        setSurvey(getSurveyRequest.responseData)
    }

    return (
        <AccessControlCP permissions={[PermissionEnum.ADMIN, PermissionEnum.EDITOR]} redirect={true}>
            <HeaderCP
                title={'Configurações de Pesquisas CSAT'}
                onClickBack={() => RoutingHelper.historyPush(CsatRoutes.ROOT)}
            />

            <LoadingOverlayCP show={getSurveyRequest.isAwaiting}/>
            <SurveyConfigCP
                type={SurveyTypeEnum.CSAT}
                survey={survey}
            />
        </AccessControlCP>
    )
}
