import React from 'react'
import { AccessControlCP } from 'common/components/access-control/AccessControlCP'
import { RoutingHelper } from 'config/RoutingHelper'
import { StringUtils } from 'common/utils/StringUtils'
import { RouteConfigTP } from 'common/types/RouteConfigTP'
import { ScreenCsatConfig } from 'modules/survey/screens/screen-csat-config/ScreenCsatConfig'
import { ScreenCsatList } from 'modules/survey/screens/screen-csat-list/ScreenCsatList'

CsatRoutes.ROOT = '/csat'

CsatRoutes.SETUP = `${CsatRoutes.ROOT}/setup`
CsatRoutes.SETUP_WITH_QUERY = `${CsatRoutes.SETUP}/:code?`

const ROUTES_CONFIG: RouteConfigTP[] = [
    {
        key: StringUtils.getSlugStyleString(CsatRoutes.ROOT),
        path: CsatRoutes.ROOT,
        component: ScreenCsatList,
        exact: true
    },
    {
        key: StringUtils.getSlugStyleString(CsatRoutes.SETUP),
        path: CsatRoutes.SETUP_WITH_QUERY,
        component: ScreenCsatConfig,
    },
]

/**
 * Componente que define as rotas para acesso ao modulo de CSAT
 */
export function CsatRoutes(): JSX.Element {

    return (
        <AccessControlCP>
            {RoutingHelper.renderRoutingSwitch(ROUTES_CONFIG)}
        </AccessControlCP>
    )
}

