import { FormModel } from 'common/form-state-manager/FormModel'
import { IDateRangeFilter } from 'common/components/date-range-picker/inner/IDateRangeFilter'
import { IReferralProgramResponseDTO } from 'modules/referral-program/services/referral-program/dtos/responses/IReferralProgramResponseDTO'

export class ReportsCommonFiltersFormModel extends FormModel {

    referralProgramCode?: number
    referralProgram?: IReferralProgramResponseDTO

    dateRange?: IDateRangeFilter
    franchisesCodes?: number[]

    constructor(initialData?: any) {
        super(initialData)
        Object.assign(this, initialData)
    }

}
