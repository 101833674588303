import styled from 'styled-components'
import * as React from 'react'
import { CardCP } from 'common/components/card/CardCP'
import { ConditionalRenderCP } from 'common/components/conditional-render/ConditionalRenderCP'

interface ICardLoginCPProps {
    children: JSX.Element | JSX.Element[]
    showCover: boolean
}

export function CardLoginCP(props: ICardLoginCPProps): JSX.Element {
    return (
        <CardWrapSCP>
            <CardCP innerSpacing={'none'} margin={'0px'}>
                <ConditionalRenderCP shouldRender={props.showCover}>
                    {/*<div className='img-login' style={{ backgroundImage: `url(${DEV_INVITE_DOMAIN_MOCK.logoUrl})` }}/>*/}
                    <div className='img-login'/>
                </ConditionalRenderCP>

                {props.children}
            </CardCP>
        </CardWrapSCP>
    )
}

const CardWrapSCP = styled.div`

    padding: 0;
    box-shadow: 5px 2px 25px 8px rgba(0, 0, 0, 0.1);
    border-radius: 12px;

    @media screen and (max-width: 768px) {
      width: 620px !important;
      margin-bottom: 0 !important;
    }

    @media screen and (max-width: 425px) {
      width: 100% !important;
    }

    .ant-card-body {
      display: flex;
      width: 100%;
      height: 100%;
      
      @media screen and (max-width: 768px) {
        flex-direction: column;
      }

      .img-login {
        flex: 1;
        border-radius: 9px 0 0 9px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        background-size: cover;
        @media screen and (max-width: 768px) {
          height: 315px;
          border-radius: 9px 9px 0 0;

          img{
            margin-bottom: 0;
          }
        }

        @media screen and (max-width: 435px) {
          height: 200px;
        }

        img {
          position: absolute;
          padding-bottom: 2.5rem;
        }


      }

      .form-login {
        flex: 1;
        padding: 30px;
        img {
          margin-bottom: 20px;
        }

        .box-inputs{
          margin-bottom: 2.5rem;
          
          .email {
            margin-bottom: 1.1rem
          }
        }

        .simple-input {
          margin: 1rem 0;

          input {
            color: rgba(0, 0, 0, 0.5);
            font-size: 14px;
            margin-bottom: 1rem;
          }

          .ant-input-affix-wrapper .ant-input-prefix{
            left: 2px;
          }
          .ant-input-affix-wrapper .ant-input:not(:first-child){
            padding-left: 25px;
            padding-top: 8px;
          }
        }

        @media screen and (max-width: 425px) {
          padding: 1.5rem;
        }

        .title-login{
          color: rgba(0, 0, 0, 0.4);
          font-weight: 500;
          font-size: 1.3rem;
          display: block;
          margin-bottom: 3rem;
        }


      }

      .options-form {
        margin: 1.5rem 0 2.4rem;
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: 768px) {
          margin-top: 3rem;
        }

        .remember-password{
          color: var(--fundiar-primary-brown);
          font-weight: 700;
        }

        .ant-checkbox-wrapper {
          font-weight: 700;
          color: rgba(0, 0, 0, 0.5);

          .ant-checkbox {

            &.ant-checkbox-checked {
              .ant-checkbox-inner {
                background: #fff;
                border-color: #d9d9d9;

                &::after {
                  border-color: var(--secondary-color);
                }
              }
            }

            &:hover .ant-checkbox-inner {
              border-color: #d9d9d9;
            }
          }

        }
      }
  }
`