import { ScreenSubMenuTP } from 'common/components/screen-layout-submenu/submenu/inner/ScreenSubMenuItemTP'
import React from 'react'
import { AdminSpaceRoutes } from 'modules/admin-space/AdminSpaceRoutes'
import { ScreenContentAccountsCP } from 'modules/admin-space/screens/screen-admin-space/screen-content/ScreenContentAccountsCP'

export const ADMIN_SPACE_SUBMENU_ACCOUNTS_KEY = 'accounts'
export type AdminSpaceViewTP =
    'accountsView'

export const ScreenAdminSpaceUtils = {

    getSubMenuConfigs(): Array<ScreenSubMenuTP<AdminSpaceViewTP>> {
        return [
            {
                key: ADMIN_SPACE_SUBMENU_ACCOUNTS_KEY,
                title: 'Contas',
                enabled: true,
                items: [
                    {
                        name: 'Todas as Contas',
                        view: 'accountsView',
                        route: AdminSpaceRoutes.ACCOUNTS,
                        content: <ScreenContentAccountsCP/>
                    },
                ]
            }
        ]
    },

}
