import React from 'react'
import { PlanEnum, PlanLabelEnum } from 'submodules/space4leads-sdk/services/accounts/enums/PlanEnum'
import { IFormItemCommonProps } from 'submodules/nerit-framework-ui/common/components/form-fields/inner/interfaces/IFormItemCommonProps'
import { SelectCP } from 'submodules/nerit-framework-ui/common/components/form-fields/select/SelectCP'

/**
 * Select dos planos
 */
export function SelectPlanCP(props: IFormItemCommonProps): JSX.Element {

    return (
        <SelectCP
            options={
                Object.values(PlanEnum).map((plan) => ({
                    label: PlanLabelEnum[plan],
                    value: plan
                }))
            }
            label={'Escolha seu plano'}
            required={props.required}
            formStateManager={props.formStateManager}
            fieldName={props.fieldName}
            errorMessage={props.errorMessage}
        />
    )
}
