import { HttpMethodEnum } from 'common/enums/HttpMethodEnum'
import { RequestConfigTP } from 'common/request-manager/types/RequestConfigTP'
import { SystemConfig } from 'config/SystemConfig'
import { ISurveyAnswerCreateRequestDTO } from 'modules/survey/services/survey-answer/dtos/requests/ISurveyAnswerCreateRequestDTO'

/**
 * Classe auxiliar para realizar requisiçoes do modulo PESQUISAS.
 */
export class SurveyRequests {

    private static readonly _MODULE_BASE = `${SystemConfig.getInstance().apiBaseUrl}/surveys`

    static getOne = (code: number): RequestConfigTP => ({
        url: `${SurveyRequests._MODULE_BASE}/${code}`,
        method: HttpMethodEnum.GET,
    })

    static searchCsat = (): RequestConfigTP => ({
        url: `${SurveyRequests._MODULE_BASE}/csat`,
        method: HttpMethodEnum.GET,
    })

    static delete = (code: number): RequestConfigTP => ({
        url: `${SurveyRequests._MODULE_BASE}/${code}`,
        method: HttpMethodEnum.DELETE,
    })

    static createAnswer = (surveyCode: number, answerDto: ISurveyAnswerCreateRequestDTO): RequestConfigTP => ({
        url: `${SurveyRequests._MODULE_BASE}/${answerDto}`,
        method: HttpMethodEnum.POST,
        params: answerDto,
    })

    static updateAnswer = (code: number): RequestConfigTP => ({
        url: `${SurveyRequests._MODULE_BASE}/${code}`,
        method: HttpMethodEnum.PUT,
    })

}
