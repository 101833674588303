import _ from 'lodash'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { SelectOptionTP } from 'common/components/form-fields/select/inner/types/SelectOptionTP'
import { SelectCP } from 'common/components/form-fields/select/SelectCP'
import { SchemaIconCP } from 'common/components/icons/SchemaIconCP'
import { PopOverCP } from 'common/components/pop-over/PopOverCP'
import { AppStateUtils } from 'common/utils/AppStateUtils'
import { StringUtils } from 'common/utils/StringUtils'
import styled from 'styled-components'
import { AuthActions } from 'modules/auth/AuthActions'
import React, { useEffect, useState } from 'react'
import { SystemUtils } from 'common/utils/SystemUtils'
import { SelectFullOptionTP } from 'common/components/form-fields/select/inner/types/SelectFullOptionTP'

interface IFranchiseSelectCPProps {
    disabled?: boolean
    onChangeCurrentFranchise: () => void
}

/**
 * COMPONENTE Select de Projetos
 */
export function SelectCurrentProjectCP(props: IFranchiseSelectCPProps): JSX.Element {

    const [isPopoverVisible, setIsPopoverVisible] = useState<boolean>(false)
    const currentFranchise = AppStateUtils.getCurrentProject()
    const [franchiseSelectOptions, setFranchiseSelectOptions] = useState<SelectOptionTP[]>([])

    useEffect(init, [])

    /**
     * Inicializa.
     */
    function init(): void {

        if (SystemUtils.isEmpty(AppStateUtils.getUserAccounts()))
            return

        let options: SelectFullOptionTP[] = []

        // Adiciona Franquias
        AppStateUtils.getUserAccounts()!.forEach((userAccount) => {

            const franchiseOpts = userAccount.projects.map((userProject) => ({
                value: userProject.code,
                key: `prj-${userProject.code}`,
                label: `${userAccount.account.name} - ${userProject.name}`
            }))

            options = _.concat(options, franchiseOpts)
        })

        setFranchiseSelectOptions(_.sortBy(options, [option => option.label.toLowerCase()]))
    }

    /**
     * Ao selecionar um projeto
     */
    function onSelectFranchise(selectedProjectCode: number): void {

        if (!selectedProjectCode)
            return

        AppStateUtils.getUserAccounts()!.forEach((userAccount) => {
            userAccount.projects.forEach((userProject) => {

                if (selectedProjectCode === userProject.code)
                    AuthActions.setCurrentProject(userProject, userAccount)

            })
        })

        setIsPopoverVisible(false)
        props.onChangeCurrentFranchise()
    }

    return (
        <ContentWrapperSCP>
            <PopOverCP
                trigger={'click'}
                placement={'right'}
                visible={isPopoverVisible}
                onClose={(): void => setIsPopoverVisible(false)}
                content={
                    <>
                        <TitleSCP>
                            <SchemaIconCP/>
                            Alterar o Projeto
                        </TitleSCP>

                        <ContainerWrapperSCP>
                            <SelectCP
                                value={currentFranchise?.code}
                                options={franchiseSelectOptions}
                                placeholder={'Selecione um projeto'}
                                notFoundContent={'Nenhum projeto encontrado.'}
                                onChange={onSelectFranchise}
                                loading={!currentFranchise}
                                disabled={props.disabled}
                                width={300}
                            />
                        </ContainerWrapperSCP>
                    </>
                }
            >
                <ButtonCP
                    tooltip={`Você está logado na unidade: ${currentFranchise?.name ?? ''}`}
                    tooltipPlacement={'topLeft'}
                    onClick={(): void => setIsPopoverVisible(true)}
                    type={'link'}
                >
                    {StringUtils.getInitials(currentFranchise?.name ?? '')}
                </ButtonCP>
            </PopOverCP>
        </ContentWrapperSCP>
    )
}

const TitleSCP = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;

  i {
    margin-right: 7px;
  }
`

const ContentWrapperSCP = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  color: ${props => props.theme.primaryColor};
  border-bottom: dashed 1px ${props => props.theme.secondaryColor};

  button {
    margin: 10px 0 20px 0;
    color: ${props => props.theme.secondaryColor} !important;
  }
`

const ContainerWrapperSCP = styled.div`
  .ant-form-explain {
    display: none;
  }
`
