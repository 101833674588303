export enum PermissionEnum {
    ADMIN = 'ADMIN',
    EDITOR = 'EDITOR',
    VIEWER = 'VIEWER',
}

export enum PermissionLabelEnum {
    ADMIN = 'Administrador',
    EDITOR = 'Editor',
    VIEWER = 'Visualizador',
}
