import React from 'react'
import styled from 'styled-components'
import { CardLoginOLD } from 'modules/auth/components/card-login/CardLoginOLD'
import bgImg from 'modules/auth/components/auth-screen-cover/inner/auth-cover2.png'
import { LoginFormCP } from 'modules/auth/components/user-login-form/LoginFormCP'
import { RoutingHelper } from 'config/RoutingHelper'
import { AuthRoutes } from 'modules/auth/AuthRoutes'
import { LogoCP } from 'common/components/logo/LogoCP'
import { NpsRoutes } from 'modules/survey/routes/NpsRoutes'

/**
 * TELA de Login.
 */
export function ScreenLogin(): JSX.Element {

    return (
        <CenteredCardSCP>
            <CardLoginOLD>
                <div className='img-login' style={{ backgroundImage: `url(${bgImg})` }}/>

                <div className='form-login'>
                    <LogoCP align={'center'} marginTop={10}/>
                    <LoginFormCP
                        onLoginSuccess={() => RoutingHelper.historyReplace(NpsRoutes.ROOT)}
                        onRecoverPassword={() => RoutingHelper.historyPush(AuthRoutes.RECOVER_PWD)}
                    />
                </div>
            </CardLoginOLD>
        </CenteredCardSCP>
    )
}

const CenteredCardSCP = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`
