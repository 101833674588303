import React from 'react'
import { Helmet } from 'react-helmet'
import { SystemConfig } from 'config/SystemConfig'

interface IHelmetCPProps {
    title: string
}

/**
 * Componente helmet
 */
export function HelmetCP(props: IHelmetCPProps): JSX.Element {
    return (
        <Helmet>
            <title>
                {`${props.title} | ${SystemConfig.getInstance().appName}`}
            </title>
        </Helmet>

    )
}
