import React from 'react'
import styled from 'styled-components'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { CreateAccountAccessFormModel } from 'modules/admin/components/wizard-create-account/inner/CreateAccountAccessFormModel'
import { CreateAccountPersonFormModel } from 'modules/admin/components/wizard-create-account/inner/CreateAccountPersonFormModel'
import { CreatedSchemaDataICP } from 'modules/admin/components/result-create-account/inner/CreatedSchemaDataICP'
import { SystemConfig } from 'config/SystemConfig'
import { TitleCP } from 'submodules/nerit-framework-ui/common/components/title/TitleCP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { Error404Screen } from 'common/screens/Error404Screen'
import { PlanLabelEnum } from 'submodules/space4leads-sdk/services/accounts/enums/PlanEnum'

interface ITrialRequestWizardCPProps {
    personFormStateManager: IFormStateManager<CreateAccountPersonFormModel>
    accessFormStateManager: IFormStateManager<CreateAccountAccessFormModel>
}

/**
 * Conteudo da tela de criar um trial
 */
export function ResultCreateAccountCP(props: ITrialRequestWizardCPProps): JSX.Element {

    // Plano contratado
    if (!props.accessFormStateManager.getFieldValue('plan')) {
        NotificationHelper.error('Ops!', 'Não encontramos o plano contratado')
        return <Error404Screen/>
    }
    return (
        <SuccessNotificationWrapperSCP>
            <SuccessTitleSCP>
                {`Parabéns ${props.personFormStateManager.getFormValues()?.name as string ?? ''}! Sua conta foi criada com sucesso!`}
            </SuccessTitleSCP>

            <TitleCP marginTop={40} textSize={'normal'} underLine={true}>
                Aqui estão seus dados de acesso:
            </TitleCP>
            <CreatedSchemaDataICP
                plan={PlanLabelEnum[props.accessFormStateManager.getFieldValue('plan')]}
                email={props.personFormStateManager.getFieldValue('email') ?? 'Mesmo informado no momento do cadastro'}
                password={props.accessFormStateManager.getFieldValue('password') ?? 'Mesmo informado no momento do cadastro'}
            />
            <ButtonSCP>
                <a href={SystemConfig.getInstance().uiBaseUrl} target={'_blank'} rel={'noopener noreferrer'}>
                    Entrar no meu sistema
                </a>
            </ButtonSCP>
        </SuccessNotificationWrapperSCP>
    )
}

const ButtonSCP = styled.div<{ isPrimary?: boolean }>`
  padding: 10px 20px;
  margin: 20px;
  background: ${props => props.isPrimary ? ThemeAnt.primaryColor : undefined};
  border: solid 1px ${ThemeAnt.primaryColor};
  border-radius: 5px;

  a {
    color: ${props => props.isPrimary ? 'white' : ThemeAnt.primaryColor};
  }
`

const SuccessNotificationWrapperSCP = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

const SuccessTitleSCP = styled.h4`
  font-size: ${ThemeAnt.fontSizes.extraLarge};
  padding: 25px 0 15px;
  text-align: center;
`
