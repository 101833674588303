import { ObjectPropsTP } from 'common/types/ObjectPropsTP'
import { IsEmail } from 'common/validation/decorators/IsEmail'
import { IsString } from 'common/validation/decorators/IsString'
import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { IsPhoneBR } from 'submodules/nerit-framework-utils/validators/decorators/IsPhoneBR'
import { IsRequired } from 'submodules/nerit-framework-utils/validators'

export class ProfileFormModel extends FormModel {

    @IsRequired()
    @IsString()
    name: string

    @IsRequired()
    @IsEmail()
    email: string

    @IsPhoneBR()
    @IsRequired()
    phone: string

    constructor(initialData: ObjectPropsTP<ProfileFormModel>) {
        super(initialData)
        Object.assign(this, initialData)
    }
}
