import React from 'react'
import { AccessControlCP } from 'common/components/access-control/AccessControlCP'
import { RoutingHelper } from 'config/RoutingHelper'

/*
 * Rotas nao autenticadas
 */
SurveyRoutes.SURVEY_LANDING_PAGE = '/lp-survey'
SurveyRoutes.SURVEY_LANDING_PAGE_WITH_QUERY = `${SurveyRoutes.SURVEY_LANDING_PAGE}/:token`

SurveyRoutes.SURVEY_RESULT_LANDING_PAGE = '/lp-survey-result/:token'

/**
 */
export function SurveyRoutes(): JSX.Element {
    return (
        <AccessControlCP>
            {RoutingHelper.renderRoutingSwitch([])}
        </AccessControlCP>
    )
}
