import React from 'react'
import { ContentCP } from 'common/components/screen-layout/content/ContentCP'
import { HeaderCP } from 'common/components/screen-layout/header/HeaderCP'
import { HeaderButtonICP } from 'common/components/screen-layout/header/inner/HeaderButtonICP'
import { RoutingHelper } from 'config/RoutingHelper'
import { CsatRoutes } from 'modules/survey/routes/CsatRoutes'
import styled from 'styled-components'
import { ListCsatCampaignsCP } from 'modules/survey/components/list-csat-campaigns/ListCsatCampaignsCP'
import { AccessControlCP } from 'common/components/access-control/AccessControlCP'
import { PermissionEnum } from 'submodules/space4leads-sdk/services/people/person/enums/PermissionEnum'

/**
 * TELA de listagem de Disparos
 */
export function ScreenCsatList(): JSX.Element {

    return (
        <>
            <HeaderCP
                title={'Campanhas de CSAT'}
            >
                <AccessControlCP permissions={[PermissionEnum.EDITOR, PermissionEnum.ADMIN]}>
                    <HeaderButtonICP
                        onClick={() => RoutingHelper.historyPush(CsatRoutes.SETUP)}
                        icon={'plus'}
                        label={'Nova Campanha'}
                    />
                </AccessControlCP>
            </HeaderCP>

            <ContentCP overflow={'auto'}>

                <ContentSCP>
                    <ListCsatCampaignsCP/>
                </ContentSCP>
            </ContentCP>
        </>
    )

}

const ContentSCP = styled.div`
  padding: 20px;
  margin-bottom: 50px;
  max-width: 100%;
`
