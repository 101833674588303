import { FormModel } from 'common/form-state-manager/FormModel'
import { IsRequired } from 'submodules/nerit-framework-utils/validators'
import { IsEmail } from 'submodules/nerit-framework-utils/validators/decorators/IsEmail'
import { PermissionEnum } from 'submodules/space4leads-sdk/services/people/person/enums/PermissionEnum'

export class ProjectUsersFormModel extends FormModel {

    @IsRequired()
    name: string

    @IsRequired()
    @IsEmail()
    email: boolean

    @IsRequired()
    permission: PermissionEnum

    constructor(initialData?: any) {
        super(initialData)
        Object.assign(this, initialData)
    }

}
