import React from 'react'
import { LayoutSubmenuCP } from 'common/components/screen-layout-submenu/layout/LayoutSubmenuCP'
import { ADMIN_SPACE_SUBMENU_ACCOUNTS_KEY, AdminSpaceViewTP, ScreenAdminSpaceUtils } from 'modules/admin-space/screens/screen-admin-space/utils/ScreenAdminSpaceUtils'

/**
 * TELA de administração do sistema.
 */
export function ScreenAdminSpace(): JSX.Element {

    return (
        <LayoutSubmenuCP<AdminSpaceViewTP>
            menuTitle={'Admin Space4Leads'}
            defaultSubmenu={'accountsView'}
            defaultOpenKeys={[ADMIN_SPACE_SUBMENU_ACCOUNTS_KEY]}
            submenuConfigs={ScreenAdminSpaceUtils.getSubMenuConfigs()}
        />
    )
}
