import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
import { App } from 'modules/app/components/app/App'

ReactDOM.render(<App/>, document.getElementById('root'))

serviceWorker.register(
    {
        onUpdate: registration => {
            const waitingServiceWorker = registration.waiting

            if (waitingServiceWorker) {
                waitingServiceWorker.addEventListener('statechange', event => {
                    /* eslint-disable @typescript-eslint/ban-ts-ignore */
                    // @ts-ignore
                    if (event.target && event.target.state === 'activated')
                        serviceWorker.displayUpdateNotification()

                })
                waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' })
            }
        },
    }
)
