import React from 'react'

interface ICPProps {
    children: JSX.Element | JSX.Element[]
}

/**
 * Area para impressao
 */
export const PrintAreaCP = React.forwardRef((props: ICPProps, ref: any) => {

    return (
        <div ref={ref}>
            { props.children }
        </div>
    )
})

