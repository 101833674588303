import React, { useState } from 'react'
import styled from 'styled-components'
import { IFormStateManager } from 'common/form-state-manager/IFormStateManager'
import { ReferralProgramSubscribeFormModel } from 'modules/referral-program/components/wizard-referral-program-subscribe/models/ReferralProgramSubscribeFormModel'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { HelpCP } from 'common/components/help/HelpCP'
import { TableReferFriendsCP } from 'modules/referral-program/components/table-refer-friends/TableReferFriendsCP'
import { ConditionalRenderCP } from 'common/components/conditional-render/ConditionalRenderCP'
import { SystemUtils } from 'common/utils/SystemUtils'
import { IReferralProgramSubscribeItemRequestDTO } from 'modules/person/services/dtos/request/IReferralProgramSubscribeItemRequestDTO'
import { ModalReferFriendCP } from 'modules/referral-program/components/modal-refer-friend/ModalReferFriendCP'
import { FlexCP } from 'common/components/flex/FlexCP'
import { TableReferFriendsUtils } from 'modules/referral-program/components/table-refer-friends/inner/TableReferFriendsUtils'

interface ICPProps {
    formStateManager: IFormStateManager<ReferralProgramSubscribeFormModel>
}

/**
 * Etapa de indicador os amigos.
 */
export function StepReferFriendsICP(props: ICPProps): JSX.Element {

    const [isReferFriendModalVisible, setIsReferFriendModalVisible] = useState<boolean>(false)

    /**
     * Ao salvar novo amigo.
     */
    function onAddFriend(friend: IReferralProgramSubscribeItemRequestDTO): void {

        const newFriends: IReferralProgramSubscribeItemRequestDTO[] = props.formStateManager.getFieldValue('friends') ? [...props.formStateManager.getFieldValue('friends')] : []
        newFriends.push(friend)

        props.formStateManager.changeFieldValue('friends', newFriends)
        setIsReferFriendModalVisible(false)
    }

    /**
     * Evento ao deletar um amigo.
     */
    function onDeleteFriend(index: number): void {

        const newFriends = [...props.formStateManager.getFieldValue('friends')]
        newFriends.splice(index, 1)

        props.formStateManager.changeFieldValue('friends', newFriends)
    }

    /**
     * On change dos inputs da tabela.
     */
    function onChangeFieldValue(index: number, name?: string, phone?: string): void {

        // Itera na lista alterando os valores
        const modTable = props.formStateManager.getFieldValue('friends').map((friend: IReferralProgramSubscribeItemRequestDTO, curIndex: number) => {

            if (curIndex !== index)
                return friend

            return ({
                name: name ?? friend.name,
                phone: phone ?? friend.phone,
            })
        })

        props.formStateManager.changeFieldValue('friends', modTable)
    }

    return (
        <>
            <HelpSCP>
                <HelpCP text={'Indique ao menos um amigo(a) para participar do programa'} type={'text'}/>
            </HelpSCP>

            <ConditionalRenderCP shouldRender={!SystemUtils.isEmpty(props.formStateManager.getFieldValue('friends'))}>
                <TableReferFriendsCP
                    friends={props.formStateManager.getFieldValue('friends')}
                    onChange={onChangeFieldValue}
                    onDelete={onDeleteFriend}
                />
            </ConditionalRenderCP>

            <ConditionalRenderCP shouldRender={TableReferFriendsUtils.isAllRowsFilled(props.formStateManager.getFieldValue('friends') ?? [])}>
                <FlexCP justifyContent={'center'}>
                    <ButtonCP
                        marginTop={30}
                        onClick={() => setIsReferFriendModalVisible(true)}
                    >
                        Indicar + pessoas
                    </ButtonCP>
                </FlexCP>
            </ConditionalRenderCP>

            {
                isReferFriendModalVisible &&
                <ModalReferFriendCP
                    visible={isReferFriendModalVisible}
                    onAdd={onAddFriend}
                    onClose={() => setIsReferFriendModalVisible(false)}
                />
            }
        </>
    )
}

const HelpSCP = styled.div`
  padding: 10px;
`
