import { HttpMethodEnum } from 'common/enums/HttpMethodEnum'
import { RequestConfigTP } from 'common/request-manager/types/RequestConfigTP'
import { SystemConfig } from 'config/SystemConfig'
import { IReferralProgamSaveRequestDTO } from 'modules/referral-program/services/referral-program/dtos/requests/IReferralProgamSaveRequestDTO'
import { IGenericSearchStringRequestDTO } from 'common/dtos/requests/IGenericSearchStringRequestDTO'

/**
 * Classe auxiliar para realizar requisiçoes do modulo Programas de Indicacao.
 */
export class GoalRequests {

    private static readonly _MODULE_BASE = `${SystemConfig.getInstance().apiBaseUrl}/goals`

    static findByCode = (code: number): RequestConfigTP => ({
        url: `${GoalRequests._MODULE_BASE}/${code}`,
        method: HttpMethodEnum.GET,
    })

    static search = (dto: IGenericSearchStringRequestDTO): RequestConfigTP => ({
        url: `${GoalRequests._MODULE_BASE}/`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static save = (dto: IReferralProgamSaveRequestDTO): RequestConfigTP => ({
        url: `${GoalRequests._MODULE_BASE}/`,
        method: HttpMethodEnum.POST,
        params: dto
    })

    static update = (code: number, dto: IReferralProgamSaveRequestDTO): RequestConfigTP => ({
        url: `${GoalRequests._MODULE_BASE}/${code}`,
        method: HttpMethodEnum.PUT,
        params: dto
    })

    static delete = (code: number): RequestConfigTP => ({
        url: `${GoalRequests._MODULE_BASE}/${code}`,
        method: HttpMethodEnum.DELETE,
    })

}
