import React from 'react'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { PlanEnum } from 'submodules/space4leads-sdk/services/accounts/enums/PlanEnum'
import { PermissionUtils } from 'modules/auth/utils/PermissionUtils'

interface ICPProps {
    onClick: () => void
}

/**
 * Botao para criar novo projeto.
 */
export function ButtonNewProjectCP(props: ICPProps): JSX.Element {

    return (
        <ButtonCP
            icon={'plus'}
            onClick={props.onClick}
            disabled={!PermissionUtils.hasAccess(undefined, PlanEnum.PREMIUM)}
            tooltip={PermissionUtils.hasAccess(undefined, PlanEnum.PREMIUM)
                ? undefined
                : 'Mude para o Plano Premium para poder criar múltiplos projetos'
            }
        >
            Novo Projeto
        </ButtonCP>
    )
}
