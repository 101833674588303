/**
 * TYPE
 * Propriedades de rota para a tela de respostas de um formulario
 */
export type SurveyLandingPageScreenRoutePropsTP = {
    token: string,
}

export const SURVEY_LADING_PAGE_PARAM_SCORE = 's'
export const SURVEY_LADING_PAGE_PARAM_PERSON_TOKEN = 'p'
export const SURVEY_LADING_PAGE_PARAM_TAGS = 't'
