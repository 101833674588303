import React from 'react'
import { TextCP } from 'common/components/text/TextCP'
import { FlexCP } from 'common/components/flex/FlexCP'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { FontAwsomeIconCP } from 'common/components/icons/FontAwsomeIconCP'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { RoutingHelper } from 'config/RoutingHelper'
import { ButtonSizeTP } from 'common/components/button/inner/ButtonSizeTP'
import { IReferralProgramResponseDTO } from 'modules/referral-program/services/referral-program/dtos/responses/IReferralProgramResponseDTO'
import { ReferralProgramUtils } from 'modules/referral-program/utils/ReferralProgramUtils'

const SUBSCRIBE_LINK_VAR = '##subscribe_link##'

interface IButtonWhatsAppCPProps {
    buttonText: string
    size?: ButtonSizeTP
    referralProgram: IReferralProgramResponseDTO
    token?: string
    phone?: string
}

/**
 * Botao para abrir whatsapp.
 */
export function ButtonWhatsAppInviteFriendsCP(props: IButtonWhatsAppCPProps): JSX.Element {

    let subscribeUrl = ReferralProgramUtils.getSubscribeUrl(props.referralProgram, true)
    if (props.token)
        subscribeUrl += `?t=${props.token}`

    const message = props.referralProgram.inviteMessage.replace(SUBSCRIBE_LINK_VAR, subscribeUrl)

    let wppLink = `https://api.whatsapp.com/send?text=${message}`
    if (!!props.phone)
        wppLink += `&phone=55${props.phone}`

    return (
        <ButtonCP
            bgColor={'#25d366'}
            color={'#FFF'}
            type={'ghost'}
            borderColor={'#25d366'}
            onClick={() => RoutingHelper.openInNewTab(wppLink)}
            size={props.size}
        >
            <FlexCP>
                <FontAwsomeIconCP type={faWhatsapp}/>
                <TextCP text={props.buttonText} marginLeft={5}/>
            </FlexCP>
        </ButtonCP>
    )
}
