import React from 'react'
import { Icon } from 'antd'

interface IIconCPProps {
    antIcon: string
    color?: string
}

/**
 * Componente WRAPPER de Icone
 */
export function IconCP(props: IIconCPProps): JSX.Element {

    return (
        <Icon type={props.antIcon} style={{ color: props.color }}/>
    )

}