import { IGenericListResponseDTO } from 'common/dtos/responses/IGenericListResponseDTO'
import { RequestUtils } from 'common/request-manager/RequestUtils'
import { useRequest } from 'common/request-manager/use-request/UseRequest'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { SurveyRequests } from 'modules/survey/services/survey/SurveyRequests'
import { ListCP } from 'common/components/list/ListCP'
import { ListItemICP } from 'common/components/list/inner/ListItemICP'
import { ListItemCsatCampaignsCP } from 'modules/survey/components/list-item-csat-campaigns/ListItemCsatCampaignsCP'
import { ISurveyListItemResponseDTO } from 'modules/survey/services/survey/dtos/response/ISurveyListItemResponseDTO'

/**
 * COMPONENTE Listagem de campanhas de CSAT.
 */
export function ListCsatCampaignsCP(): JSX.Element {

    const [csatCampaigns, setCsatCampaigns] = useState<ISurveyListItemResponseDTO[]>()

    const searchCsatCampaignsRequest = useRequest<IGenericListResponseDTO<ISurveyListItemResponseDTO>>()
    useEffect(onSearchCsatCampaignsRequestChange, [searchCsatCampaignsRequest.isAwaiting])

    useEffect(init, [])

    /**
     * Inicializa.
     */
    function init(): void {
        searchCsatCampaignsRequest.runRequest(SurveyRequests.searchCsat())
    }

    /**
     * Retorno ao buscar campanhas de CSAT.
     */
    function onSearchCsatCampaignsRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(searchCsatCampaignsRequest, 'Não foi possível obter as campanhas'))
            return

        setCsatCampaigns(searchCsatCampaignsRequest.responseData?.list ?? [])
    }

    return (
        <ContentSCP>
            <ListCP
                bordered={false}
                loading={searchCsatCampaignsRequest.isAwaiting}
                dataSource={csatCampaigns}
                renderItem={csat => (
                    <ListItemICP
                        content={
                            <ListItemCsatCampaignsCP
                                showActions={true}
                                onUpdateList={init}
                                csatCampaign={csat}
                            />
                        }
                    />
                )}
            />
        </ContentSCP>
    )

}

const ContentSCP = styled.div`
    .ant-list-item {
        padding: 0;
        border-radius: 10px;
        margin-bottom: 20px;
        justify-content: center;
        border: none;
        .ant-list-item-meta{
            flex: inherit;
        }
    }
`
