import React, { useState } from 'react'
import { DrawerCP } from 'submodules/nerit-framework-ui/common/components/drawer/DrawerCP'
import { TableProjectsCP } from 'modules/admin/components/table-projects/TableProjectsCP'
import { AccountResponseDTO } from 'submodules/space4leads-sdk/services/accounts/dtos/responses/AccountResponseDTO'
import { TitleCP } from 'submodules/nerit-framework-ui/common/components/title/TitleCP'
import { DescriptionAccountDataCP } from 'modules/admin/components/description-account-data/DescriptionAccountDataCP'
import { ModalAccountCP } from 'modules/admin/components/modal-account/ModalAccountCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'

interface ICPProps {
    visible: boolean
    onSave: () => void
    onCancel: () => void
    account: AccountResponseDTO
}

/**
 * Drawer para gerenciar uma account.
 */
export function DrawerAccountManagementCP(props: ICPProps): JSX.Element {

    const [isAccountModalVisible, setIsAccountModalVisible] = useState<boolean>(false)

    return (
        <>
            <DrawerCP
                title={'Gestão de Conta'}
                visible={props.visible}
                onClose={props.onCancel}
                width={600}
            >
                <FlexCP justifyContent={'flex-end'} margin={{ bottom: 10 }}>
                    <ButtonCP
                        icon={'edit'}
                        onClick={() => setIsAccountModalVisible(true)}
                    >
                        Editar conta
                    </ButtonCP>
                </FlexCP>
                <DescriptionAccountDataCP
                    account={props.account}
                />

                <TitleCP
                    underLine={true}
                    textSize={'normal'}
                    marginTop={30}
                >
                    Projetos da Conta
                </TitleCP>
                <TableProjectsCP
                    accountCode={props.account.code}
                />
            </DrawerCP>

            <ModalAccountCP
                visible={isAccountModalVisible}
                onSave={() => {
                    setIsAccountModalVisible(false)
                    props.onSave()
                }}
                onCancel={() => setIsAccountModalVisible(false)}
                account={props.account}
            />
        </>
    )
}
