import { TablePaginationTP } from 'submodules/nerit-framework-ui/common/components/table/types/TablePaginationTP'
import { MaskUtils } from 'submodules/nerit-framework-ui/common/utils/MaskUtils'

/**
 * Utilitarios gerais do componente de tabela
 */
export const TableUtils = {

    getDefaultPagination(pageSize?: number): TablePaginationTP {
        return {
            current: 1,
            pageSize: pageSize ?? 50,
            total: 0,
            showTotal: (total, range) => `Total: ${MaskUtils.applyNumberMask(total, 0)} (mostrando de ${range.join(' a ')})`
        }
    },

    removePaginationAttributes(filterDto: any): any {

        delete filterDto.itemsPerPage
        delete filterDto.page

        return filterDto
    },

    getReloadNumber(): number {
        return new Date().getTime()
    },

}
