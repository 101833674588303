import React from 'react'
import { Icon } from 'antd'
import { IIconProps } from 'common/components/icons/inner/IIconProps'
import styled from 'styled-components'

interface IIconICPProps extends IIconProps {
    iconName: string
}

/**
 * Abstrai o Icon do Ant
 */
export const IconICP = (props: IIconICPProps): JSX.Element => {
    return (
        <WrapperSCP
            color={props.color}
            size={props.size}
            transitionTime={props.transitionTime}
        >
            <Icon
                type={props.iconName}
                theme={props.theme}
                twoToneColor={(props.theme === 'twoTone') ? props.color : undefined}
                style={props.style}
            />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div<{ color?: string, size?: number, transitionTime?: number }>`

    display: flex;
    align-items: center;

    i.anticon {
        fill: ${props => props.color ?? 'inherit'};
        color: ${props => props.color ?? 'inherit'};
        font-size: ${props => props.size ?? 20}px !important;
        transition: ${props => (!!props.transitionTime ? `color ${props.transitionTime}s, font-size ${props.transitionTime}s` : 'unset')};
    }
`
