import { validate, ValidationError } from 'class-validator'
import { IFormModelValidator } from 'common/interfaces/IFormModelValidator'

/**
 * VALIDADOR
 * Classe base a ser extendida para criar validadores de campos de para formulario.
 */
export abstract class FormModel implements IFormModelValidator {

    // @TODO - Rever porque os filhos precisam colocar o object.assing, se ja esta no pai
    protected constructor(initialData?: any) {
        if (!!initialData)
            Object.assign(this, initialData)
    }

    /**
     * @inheritDoc
     */
    async validate(): Promise<ValidationError[]> {
        return validate(this)
    }
}
