import { HttpMethodEnum } from 'common/enums/HttpMethodEnum'
import { RequestConfigTP } from 'common/request-manager/types/RequestConfigTP'
import { SystemConfig } from 'config/SystemConfig'
import { IPersonSearchByReferralProgramRequestDTO } from 'modules/person/services/dtos/request/IPersonSearchByReferralProgramRequestDTO'

/**
 * REQUISICOES relacionadas ao modulo de Usuario
 */
export class PersonRequests {

    private static readonly _MODULE_BASE = `${SystemConfig.getInstance().apiBaseUrl}/people`

    static findOne = (personCode: number): RequestConfigTP => ({
        url: `${PersonRequests._MODULE_BASE}/${personCode}`,
        method: HttpMethodEnum.GET,
    })

    static search = (dto: IPersonSearchByReferralProgramRequestDTO): RequestConfigTP => ({
        url: `${PersonRequests._MODULE_BASE}/find-by-referral-program`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static findByToken = (token: string): RequestConfigTP => ({
        url: `${PersonRequests._MODULE_BASE}/find-by-token/${token}`,
        method: HttpMethodEnum.GET,
    })

    static getGoals = (personCode: number, referralProgramCode: number): RequestConfigTP => ({
        url: `${PersonRequests._MODULE_BASE}/${personCode}/referral-programs/${referralProgramCode}/goals`,
        method: HttpMethodEnum.GET,
    })

}
