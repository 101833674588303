import { AxiosResponse } from 'axios'
import { History, LocationState, Path } from 'history'
import { IApiReturn } from 'common/interfaces/IApiReturn'
import { RequestHelper } from 'common/request-manager/RequestHelper'
import { OrArrayTP } from 'common/types/OrArrayTP'
import { OrNullTP } from 'common/types/OrNullTP'
import { OrUndefinedTP } from 'common/types/OrUndefinedTP'
import { RouteConfigTP } from 'common/types/RouteConfigTP'
import { RouteStringTP } from 'common/types/RouteStringTP'
import { AppStateUtils } from 'common/utils/AppStateUtils'
import { StringUtils } from 'common/utils/StringUtils'
import { SystemConfig } from 'config/SystemConfig'
import { ISchemaResponseDTO } from 'modules/app/services/dtos/response/ISchemaResponseDTO'
import { SysAdminRequests } from 'modules/app/services/SysAdminRequests'
import { AuthActions } from 'modules/auth/AuthActions'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

/** Tipo de uma funcao usada para executar um transicao de rotas. */
type _RouteModifierFunctionTP = (path: Path, state?: LocationState) => void

/**
 * HELPER
 * Executa a gestao de roteamento & transicao de telas da aplicacao.
 */
export class RoutingHelper {

    private static readonly _ROUTE_DEEP_ROOT = '/'

    private static readonly _ROUTE_DEEP_ROOT_CONFIG: RouteConfigTP = {
        key: 'empty',
        path: RoutingHelper._ROUTE_DEEP_ROOT,
        exact: true,
        redirect: 'root',
        noSchema: true,
    }

    private static _history?: History

    private static _publicRoutes: RouteConfigTP[]
    private static _defaultRoute: string

    static init(history: History, publicRoutes: RouteConfigTP[]): void {
        RoutingHelper._history = history
        RoutingHelper._publicRoutes = publicRoutes
        RoutingHelper._defaultRoute = RoutingHelper._defaultRoute ?? RoutingHelper._ROUTE_DEEP_ROOT
    }

    static setDefaultRoute(route?: string): void {

        const currentDefault = RoutingHelper._defaultRoute
        const nextDefault = RoutingHelper.getPathWithSchema(route)

        RoutingHelper._defaultRoute = nextDefault

        const isInitialDefault = (!!route && currentDefault === RoutingHelper._ROUTE_DEEP_ROOT)
        if (!isInitialDefault)
            return

        let currentPath = RoutingHelper._history?.location.pathname
        if (!currentPath)
            return

        currentPath = StringUtils.stripEndingChars(currentPath, RoutingHelper._ROUTE_DEEP_ROOT)
        if (currentPath === RoutingHelper._getRootPath())
            RoutingHelper.historyReplace('default')
    }

    static getDefaultRoute(): string {
        return RoutingHelper._defaultRoute ?? RoutingHelper._getRootPath()
    }

    /**
     * Executa transicao de tela ADICIONANDO NOVA ROTA na pilha de historico de navegacao:
     * Permite desabilitar revalidacao de token de autenticacao a ser disparada com o evento de transicao de tela;
     */
    static historyPush(path: RouteStringTP): void {
        if (!!RoutingHelper._history)
            RoutingHelper._changeRoute(RoutingHelper._history.push, path)
    }

    /**
     * Executa transicao de tela SUBSTITUINDO ROTA ATUAL na pilha de historico de navegacao:
     * Permite desabilitar revalidacao de token de autenticacao a ser disparada com o evento de transicao de tela;
     */
    static historyReplace(path: RouteStringTP): void {
        if (!!RoutingHelper._history)
            RoutingHelper._changeRoute(RoutingHelper._history.replace, path)
    }

    static openInNewTab(path: RouteStringTP): void {
        window.open(path)
    }

    static redirect(path: RouteStringTP): void {
        window.location.href = path
    }

    /** Reenderiza componente <Switch/> para roteamento das rotas publicas. */
    static renderPublicRoutingSwitch(): JSX.Element {
        return RoutingHelper.renderRoutingSwitch(RoutingHelper._publicRoutes)
    }

    /** Reenderiza componente <Switch/> para lista de rotas informada. */
    static renderRoutingSwitch(configList: RouteConfigTP[]): JSX.Element {

        configList = [...configList, RoutingHelper._ROUTE_DEEP_ROOT_CONFIG]
        return (
            <Switch>
                {
                    configList.map(routeConfig => RoutingHelper._renderRoute(routeConfig))
                }
            </Switch>
        )
    }

    /** Reenderiza componente <Redirect/> para redirecionamento dentro de componentes. */
    static renderRedirect(to: RouteStringTP): JSX.Element {
        return <Redirect to={RoutingHelper.getPathWithSchema(to)}/>
    }

    /**
     * Executa validacao & tratamento em caso de falha para rotas que se tente acessar, na aplicacao:
     * Retorna verdadeiro se a rota atual for valida sem a necessidade de manipula-la;
     */
    static async validateRoute(path: string): Promise<boolean> {

        const pathSegments = path.split('/').filter(segment => !!segment)
        if (!pathSegments.length) {
            RoutingHelper.historyReplace(RoutingHelper.getDefaultRoute())
            return false
        }

        // Verifica: Rota com schema atual valido
        const customerSchema = AppStateUtils.getDomainSlug()
        if (!!customerSchema && !!path.match(new RegExp(`^/?${customerSchema}`)))
            return true

        // Verifica: Rota publica SEM schema
        const publicPathsWithNoSchema = RoutingHelper._publicRoutes
            .filter(routeConfig => (!!routeConfig.noSchema && !!(routeConfig.rootPath ?? routeConfig.path)))
            .map(routeConfig => (routeConfig.rootPath ?? routeConfig.path)) as string[]

        const isPublicWithNoSchema = !!publicPathsWithNoSchema
            .find(publicRoutePath => !!path.match(new RegExp(`^${publicRoutePath}`)))

        if (isPublicWithNoSchema)
            return true

        // Verifica: Novo shema valido
        const proposedSchema = pathSegments[0]
        const isValidNewSchema = (pathSegments.length >= 2 && await RoutingHelper._validateUnknownSchema(proposedSchema))
        if (!isValidNewSchema)
            return false

        // Verifica: Rota publica COM schema
        const pathWithNoSchema = pathSegments.splice(1).join('/')
        let matchedPublicPath: OrUndefinedTP<string>

        for (const routeConfig of RoutingHelper._publicRoutes) {

            const pathToTest = (routeConfig.rootPath ?? routeConfig.path)
            if (!pathToTest)
                continue

            if (!!pathWithNoSchema.match(new RegExp(`^${pathToTest}`))) {
                matchedPublicPath = pathToTest
                break
            }
        }

        if (!matchedPublicPath)
            return true

        RoutingHelper.historyReplace(RoutingHelper.getPathWithSchema(matchedPublicPath))
        return false
    }

    /**
     * TODO: Verificar
     * Tratamento generico para ocorrencia de erro 403.
     */
    static handleInvalidSchema(history?: History): void {

        if (!!this._history || !!history) {

            const rootPath = RoutingHelper._getRootPath()

            if (rootPath !== RoutingHelper._ROUTE_DEEP_ROOT) {
                this._history = this._history ?? history
                return RoutingHelper.historyReplace(rootPath)
            }
        }

        console.error('FALHA RoutingHelper.handleInvalidSchema - Impossivel tratar erro de schema invalido')
    }

    /** Retorna rota incluindo o schema do dominio atual (se houver). */
    static getPathWithSchema(pathWithNoSchema?: RouteStringTP): string {

        if (!pathWithNoSchema)
            return RoutingHelper._ROUTE_DEEP_ROOT

        if (pathWithNoSchema === 'default')
            return RoutingHelper.getDefaultRoute()

        const rootRoute = RoutingHelper._getRootPath()
        if (pathWithNoSchema === 'root')
            return rootRoute

        pathWithNoSchema = StringUtils.stripInitialChars(pathWithNoSchema, '/')
        return StringUtils.stripRepeatedBegin(`${rootRoute}/${pathWithNoSchema}`, rootRoute)
    }

    /** Executa 01 transicao de tela / mudanca de rota. */
    private static _changeRoute(changeRouteFunction: _RouteModifierFunctionTP, routeWithNoSchema: string): void {
        changeRouteFunction(RoutingHelper.getPathWithSchema(routeWithNoSchema))
    }

    /** Retorna rota 'raiz' da aplicacao. */
    private static _getRootPath(): string {
        const customerSchema = AppStateUtils.getDomainSlug()
        return !!customerSchema ? `/${customerSchema}` : RoutingHelper._ROUTE_DEEP_ROOT
    }

    /** Reenderiza componente <Route/> para definicao de 01 rota. */
    private static _renderRoute(config: RouteConfigTP): OrNullTP<OrArrayTP<JSX.Element>> {

        // Valida parametros
        const possibleRenderers = [config.component, config.redirect, config.render]
        const renderingTypesCount = possibleRenderers.reduce((acc, value) => acc + Number(!!value), 0)

        if (renderingTypesCount < 1)
            return null

        if (renderingTypesCount > 1)
            throw new Error(`Mais de um modo de reenderizacao definido para a rota '${config.path ?? ''}'`)

        // Determina propriedades da rota
        const key = config.key ?? (!!config.rootPath ? StringUtils.getSlugStyleString(config.rootPath) : undefined)
        const renderProp = !!config.redirect ? () => RoutingHelper.renderRedirect(config.redirect!) : config.render

        const paths: Array<string | undefined> = []

        if (!!config.path) {
            paths.push(RoutingHelper.getPathWithSchema(config.path))
            if (!!config.noSchema)
                paths.push(config.path)

        } else
            paths.push(undefined)

        // Gera roteadores associadas a rota
        return paths.map((path, index) => (
            <Route
                key={`${key ?? ''}_${index}`}
                path={path}
                exact={config.exact}
                render={renderProp}
                component={config.component}
            />
        ))
    }

    /**
     * Executa 01 requisicao para examinar seu retorno.
     * Se receber 01 erro diferente de FORBIDDEN, consideramos que o schema eh valido.
     */
    private static async _validateUnknownSchema(schema: string): Promise<boolean> {
        try {

            const reqConfig = SysAdminRequests.getSchemaDataConfig(schema, SystemConfig.getInstance().anonymousUserToken)
            const validationReturn = await RequestHelper.runRequest(reqConfig)

            const schemaData = ((validationReturn as AxiosResponse)?.data as IApiReturn<ISchemaResponseDTO>)?.data
            if (!schemaData)
                return false

            AuthActions.setDomain(schemaData)
            return true

        } catch (err) {
            return false
        }
    }
}
