import { FormModel } from 'common/form-state-manager/FormModel'
import { IsNotEmpty } from 'common/validation/decorators/IsNotEmpty'

export class ReferralProgramMainDataFormModel extends FormModel {

    @IsNotEmpty()
    name: string

    @IsNotEmpty()
    title: string

    @IsNotEmpty()
    subtitle: string

    @IsNotEmpty()
    logoUrl: string

    @IsNotEmpty()
    consentTermUrl: string

    @IsNotEmpty()
    franchiseCodes: number[]

    constructor(initialData?: any) {
        super(initialData)
        Object.assign(this, initialData)
    }

}
