import { CollapseCP } from 'common/components/collapse/CollapseCP'
import { TextCP } from 'common/components/text/TextCP'
import React from 'react'
import styled from 'styled-components'
import { ChannelEnum, ChannelLabelEnum } from 'submodules/space4leads-sdk/services/surveys/surveys/enums/ChannelEnum'
import { ChannelUtils } from 'modules/survey/utils/ChannelUtils'
import { SurveyQrCodeLayoutConfigCP } from 'modules/survey/components/survey-qrcode-layout-config/SurveyQrCodeLayoutConfigCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { SurveyChannelsFormModel } from 'modules/survey/components/wizard-survey-config/inner/models/SurveyChannelsFormModel'
import { SurveyResponseDTO } from 'submodules/space4leads-sdk/services/surveys/surveys/dtos/response/SurveyResponseDTO'
import { HelpCP } from 'submodules/nerit-framework-ui/common/components/help/HelpCP'
import { SURVEY_LADING_PAGE_PARAM_TAGS } from 'modules/survey/screens/screen-survey-landing-page/inner/types/SurveyLandingPageScreenRoutePropsTP'

interface ICPProps {
    survey: SurveyResponseDTO
    formStateManager: IFormStateManager<SurveyChannelsFormModel>
    onEditing: () => void
}

/**
 * Paineis colapsaveis de escolha de canais.
 */
export function CollapseChannelsCP(props: ICPProps): JSX.Element {

    return (
        <WrapperSCP>
            <CollapseCP
                defaultPanelKeys={Object.values(ChannelEnum)}
                panels={
                    Object.values(ChannelEnum).map((channel, index) => ({
                        key: channel,
                        title: (<TextCP icon={ChannelUtils.getChannelIconName(channel)} text={ChannelLabelEnum[channel]} wrap={true}/>),
                        content: (
                            <>
                                {
                                    channel === ChannelEnum.QRCODE &&
                                    <SurveyQrCodeLayoutConfigCP
                                        channelsFormStateManager={props.formStateManager}
                                        onEditing={props.onEditing}
                                    />
                                }
                                {
                                    channel === ChannelEnum.LINK && !!props.survey &&
                                    <>
                                        <TextCP copyable={true} text={props.survey.mainSurveyLink}/>
                                        <HelpCP
                                            text={`Segmente seu público gerando links com tags. Para isto basta adicionar o parâmetro (?${SURVEY_LADING_PAGE_PARAM_TAGS}=tags1, tag2) no final da url com as tags separadas por vírgula.`}
                                            type={'text'}
                                        />
                                        <HelpCP
                                            text={`Ex. ${props.survey.mainSurveyLink}?${SURVEY_LADING_PAGE_PARAM_TAGS}=estudante,religioso`}
                                            type={'text'}
                                        />
                                    </>
                                }
                            </>
                        ),
                    }))
                }
            />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
  
  .ant-collapse-header {
    pointer-events: none;
  }
  
  .ant-collapse-extra {
    pointer-events: all;
  }
  
`
