import { ColumnProps } from 'antd/lib/table'
import React from 'react'
import { FlexCP } from 'common/components/flex/FlexCP'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { IFranchiseResponseDTO } from 'modules/admin/services/franchise/dtos/response/IFranchiseResponseDTO'
import { MaskUtils } from 'common/utils/MaskUtils'
import { InputMaskTypeEnum } from 'common/enums/InputMaskTypeEnum'

/**
 * Utils da tabela de grupos de tratamento.
 */
export const FranchisesTableUtils = {

    getTableColumns(
        onEdit: (code: number) => void
    ): Array<ColumnProps<IFranchiseResponseDTO>> {

        return [
            {
                key: 'title',
                title: 'Unidade',
                render: (text, item) => item.name
            },
            {
                key: 'cnpj',
                title: 'CNPJ',
                render: (text, item) => item.cnpj ? MaskUtils.applyMask(item.cnpj, InputMaskTypeEnum.CNPJ) : ''
            },
            {
                key: 'actions',
                render: (text, item) => (
                    <FlexCP justifyContent={'flex-end'}>
                        <ButtonCP
                            type={'primary'}
                            icon={'edit'}
                            size={'small'}
                            onClick={() => onEdit(item.code)}
                        />
                    </FlexCP>
                )
            },
        ]
    },

}
