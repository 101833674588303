import React from 'react'
import { FallbackScreenContentCP } from 'common/components/fallback-screen-content/FallbackScreenContent'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { ButtonWhatsAppInviteFriendsCP } from 'modules/referral-program/components/button-whatsapp-invite-friends/ButtonWhatsAppInviteFriendsCP'
import { ReferralProgramRoutes } from 'modules/referral-program/routes/ReferralProgramRoutes'
import { IReferralProgramResponseDTO } from 'modules/referral-program/services/referral-program/dtos/responses/IReferralProgramResponseDTO'
import { ICustomerPersonByReferralProgramResponseDTO } from 'modules/person/services/dtos/response/ICustomerPersonByReferralProgramResponseDTO'
import styled from 'styled-components'
import { ReferralProgramUtils } from 'modules/referral-program/utils/ReferralProgramUtils'

interface ICPProps {
    referralProgram: IReferralProgramResponseDTO
    personRegistered: ICustomerPersonByReferralProgramResponseDTO
}

/**
 * Card com com form de preenchimento de dados.
 */
export function ProgramSubscribeResultCP(props: ICPProps): JSX.Element {

    /**
     * Vai para o portal da pessoa.
     */
    function goToPortal(): void {

        let url: string = ReferralProgramUtils.getCustomerDashboardUrl(props.referralProgram)
        url += `?${ReferralProgramRoutes.CUSOMER_REFERRALS_PHONE_PARAM}=${props.personRegistered.phone}`
        url += `&${ReferralProgramRoutes.CUSOMER_REFERRALS_EMAIL_PARAM}=${props.personRegistered.email}`

        ReferralProgramUtils.redirectOrHistoryPush(url)
    }

    return (
        <FallbackScreenContentCP
            status={'success'}
            title={'Parabéns!'}
            message={
                <MessageSCP>
                    {/* eslint-disable-next-line @typescript-eslint/naming-convention */}
                    <div dangerouslySetInnerHTML={{ __html: props.referralProgram.thanksMessage }}/>
                </MessageSCP>
            }
            bottomContent={
                <ButtonsSCP>
                    <ButtonWhatsAppInviteFriendsCP
                        buttonText={'Compartilhar pelo Whatsapp'}
                        token={props.personRegistered.token}
                        referralProgram={props.referralProgram}
                    />
                    <ButtonCP
                        icon={'apartment'}
                        onClick={goToPortal}
                    >
                        Acessar portal de indicações
                    </ButtonCP>
                </ButtonsSCP>
            }
        />
    )
}

const ButtonsSCP = styled.div`
  display: flex;
  align-items: center;

  button {
    margin: 10px;
  }
  
  @media (min-width: 600px) {
    justify-content: space-between;
  }

  @media (max-width: 599px) {
    flex-direction: column;
    justify-content: center;
  }
`

const MessageSCP = styled.div`
  margin-top: 20px;
`
