import { Skeleton } from 'antd'
import * as React from 'react'
import { BasicStyleWrapperCP, BasicStyleWrapperCPProps } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'

/**
 * Skeleton.
 */
export function SkeletonCP(props: BasicStyleWrapperCPProps): JSX.Element {

    return (
        <BasicStyleWrapperCP margin={props.margin}>
            <Skeleton
                active={true}
            />
        </BasicStyleWrapperCP>
    )
}
