import React, { useState } from 'react'
import styled from 'styled-components'
import { DrawerReferralProgramReportFiltersCP } from 'modules/reports/components/drawer-referral-program-report-filters/DrawerReferralProgramReportFiltersCP'
import { useFormStateManager } from 'common/form-state-manager/UseFormStateManager'
import { ReportsCommonFiltersFormModel } from 'modules/reports/components/drawer-referral-program-report-filters/inner/ReportsCommonFiltersFormModel'
import { HeaderCP } from 'common/components/screen-layout/header/HeaderCP'
import { CardCP } from 'common/components/card/CardCP'
import { TableCustomerReferralsCP } from 'modules/referral-program/components/table-customer-referrals/TableCustomerReferralsCP'
import { ContentCP } from 'common/components/screen-layout/content/ContentCP'
import { HeaderButtonICP } from 'common/components/screen-layout/header/inner/HeaderButtonICP'

/**
 * Relatorio de Indicadores do Pipeline de Vendas.
 */
export function ScreenContentReferralProgramLeadsReportCP(): JSX.Element {

    const [isFilterDrawerVisible, setIsFilterDrawerVisible] = useState<boolean>(true)
    const [formValidator, setFormValidator] = useState<ReportsCommonFiltersFormModel>((new ReportsCommonFiltersFormModel()))
    const formStateManager = useFormStateManager<ReportsCommonFiltersFormModel>(formValidator)

    const [shouldLoadReport, setShouldLoadReport] = useState<boolean>(false)

    return (
        <>
            <HeaderCP title={'Leads dos Programas de Indicação'}>
                <HeaderButtonICP
                    onClick={() => setIsFilterDrawerVisible(!isFilterDrawerVisible)}
                    icon={'filter'}
                    label={'Filtros'}
                />
            </HeaderCP>

            <ContentCP overflow={'auto'}>
                <ContentSCP>
                    <CardCP innerSpacing={'none'}>
                        <TableCustomerReferralsCP
                            referralProgram={formStateManager.getFieldValue('referralProgram')}
                            showInviteButton={false}
                            onLoadReport={() => setShouldLoadReport(false)}
                            shouldLoadReport={shouldLoadReport}
                        />
                    </CardCP>
                </ContentSCP>
            </ContentCP>

            <DrawerReferralProgramReportFiltersCP
                formStateManager={formStateManager}
                visible={isFilterDrawerVisible}
                onClearFilters={() => setFormValidator(new ReportsCommonFiltersFormModel({}))}
                onFilter={() => setShouldLoadReport(true)}
                onClose={() => setIsFilterDrawerVisible(false)}
                loading={shouldLoadReport}
            />
        </>
    )
}

const ContentSCP = styled.div`
  margin-bottom: 80px;
`
