import { FormModel } from 'common/form-state-manager/FormModel'
import { IsNotEmpty } from 'common/validation/decorators/IsNotEmpty'
import { GoalFormModel } from 'modules/referral-program/components/modal-goal/inner/GoalFormModel'

export class ReferralProgramGoalFormModel extends FormModel {

    @IsNotEmpty()
    goals: GoalFormModel[]

    constructor(initialData?: any) {
        super(initialData)
        Object.assign(this, initialData)
    }

}
