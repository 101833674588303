import React from 'react'
import { FormItemICP } from 'submodules/nerit-framework-ui/common/components/form-fields/inner/FormItemICP'
import { IFormItemCommonProps } from 'submodules/nerit-framework-ui/common/components/form-fields/inner/interfaces/IFormItemCommonProps'
import { Switch } from 'antd'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'

interface ISwitchFormCPProps extends IFormItemCommonProps {
    disabled?: boolean
    labelAbove?: boolean
}

/**
 * Componente de sim ou nao.
 */
export function SwitchCP(props: ISwitchFormCPProps): JSX.Element {

    /**
     */
    function onChange(checked: boolean): void {

        if (!!props.onChange) {
            props.onChange(checked)
            return
        }

        if (!!props.formStateManager && !!props.fieldName)
            props.formStateManager.changeFieldValue(props.fieldName, checked)
    }

    return (
        <FormItemICP
            onChange={props.onChange}
            fieldName={props.fieldName as any}
            formStateManager={props.formStateManager}
            required={props.required}
            width={props.width}
            onFormSubmit={props.onFormSubmit}
            value={props.value}
            hint={props.hint}
            marginTop={props.marginTop}
            marginRight={props.marginRight}
            marginLeft={props.marginLeft}
            marginBottom={props.marginBottom}
        >
            <FlexCP
                alignItems={'center'}
                flexDirection={props.labelAbove ? 'column' : undefined}
            >
                <TextCP text={props.label as string} marginRight={10}/>
                <Switch
                    checked={props.value ?? (!!props.fieldName ? props.formStateManager?.getFieldValue(props.fieldName) : undefined)}
                    onChange={onChange}
                    checkedChildren={'Sim'}
                    unCheckedChildren={'Não'}
                    disabled={props.disabled}
                />
            </FlexCP>
        </FormItemICP>
    )
}
