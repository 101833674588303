import React from 'react'
import { TextCP } from 'common/components/text/TextCP'
import { CollapseCP } from 'common/components/collapse/CollapseCP'
import { TagsCP } from 'common/components/tag/TagsCP'
import { FlexCP } from 'common/components/flex/FlexCP'
import { ReferralProgramCollpaseItemICP } from 'modules/referral-program/components/collapse-referral-program-messages/inner/ReferralProgramCollpaseItemICP'
import { NotificationTriggerLabelEnum } from 'modules/marketing/services/notification/enums/NotificationTriggerEnum'
import { INotificationByRecipient } from 'modules/referral-program/components/drawer-referral-program-notifications/inner/INotificationByRecipient'
import {
    NotificationChannelEnum,
    NotificationChannelLabelEnum
} from 'modules/marketing/services/notification/enums/NotificationChannelEnum'

interface ICPProps {
    notification: INotificationByRecipient
}

/**
 * Configuracoes de automacao de marketing.
 */
export function CollapseReferralProgramMessagesCP(props: ICPProps): JSX.Element {

    const enabledChannels: string[] = []
    Object.values(NotificationChannelEnum).forEach((channel) => {
        if (props.notification.messages?.find((not) => not.channel === channel && not.isActive))
            enabledChannels.push(NotificationChannelLabelEnum[channel])
    })

    return (
        <CollapseCP
            panels={[{
                key: `key-${props.notification.recipient}-${props.notification.trigger}`,
                title: <TextCP text={NotificationTriggerLabelEnum[props.notification.trigger]} wrap={true}/>,
                extra:
                    <FlexCP justifyContent={'flex-end'}>
                        <TagsCP texts={enabledChannels}/>
                    </FlexCP>,
                content:
                    <ReferralProgramCollpaseItemICP
                        notificationMessages={Array.from(props.notification.messages ?? [])}
                    />
            }]}
        />
    )

}
