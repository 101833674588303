import { IFormStateManager } from 'common/form-state-manager/IFormStateManager'
import { NotificationHelper } from 'common/helpers/NotificationHelper'

/**
 * UTILITARIOS: Acoes e utilitarios da configuracao de NPS.
 */
export const SurveyWizardUtils = {

    /**
     * Ao submeter formulario com os labels da pergunta.
     */
    async onFormSubmit(formStateManager: IFormStateManager<any>): Promise<boolean> {

        formStateManager.setConsiderAllErrors(true)
        if (!await formStateManager.validate()) {
            NotificationHelper.error('Ops!', 'Preencha todos os campos para prosseguir')
            return false
        }

        const formValues = formStateManager.getFormValues()
        if (!formValues)
            return false

        return true
    }

}
