import React, { useState } from 'react'
import { ProjectResponseDTO } from 'submodules/space4leads-sdk/services/accounts/dtos/responses/ProjectResponseDTO'
import { TableProjectsInnerICP } from 'modules/admin/components/table-projects/inner/TableProjectsInnerICP'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { ModalProjectCP } from 'modules/admin/components/modal-project/ModalProjectCP'
import { ModalProjectUsersCP } from 'modules/admin/components/modal-project-users/ModalProjectUsersCP'
import { ButtonNewProjectCP } from 'modules/admin/components/button-new-projetct/ButtonNewProjectCP'

interface ICPProps {
    accountCode: number
    wrappedOnCard?: boolean
}

/**
 * Tabela de projetos.
 */
export function TableProjectsCP(props: ICPProps): JSX.Element {

    const [isProjectModalVisible, setIsProjectModalVisible] = useState<boolean>(false)
    const [isProjectUserModalVisible, setIsProjectUserModalVisible] = useState<boolean>(false)

    const [selectedProject, setSelectedProject] = useState<ProjectResponseDTO>()
    const [reloadTable, setReloadTable] = useState<number>(TableUtils.getReloadNumber())

    return (
        <>
            <FlexCP
                justifyContent={'flex-end'}
                margin={{
                    right: (props.wrappedOnCard ? 20 : undefined),
                    bottom: (!props.wrappedOnCard ? 10 : undefined),
                }}
            >
                <ButtonNewProjectCP
                    onClick={() => {
                        setSelectedProject(undefined)
                        setIsProjectModalVisible(true)
                    }}
                />
            </FlexCP>

            <TableProjectsInnerICP
                wrappedOnCard={props.wrappedOnCard}
                accountCode={props.accountCode}
                reloadTable={reloadTable}
                onSelectProject={(project) => {
                    setSelectedProject(project)
                    setIsProjectModalVisible(true)
                }}
                onEditUser={(project) => {
                    setSelectedProject(project)
                    setIsProjectUserModalVisible(true)
                }}
            />

            <ModalProjectCP
                visible={isProjectModalVisible}
                onSave={() => {
                    setIsProjectModalVisible(false)
                    setReloadTable(TableUtils.getReloadNumber())
                }}
                onCancel={() => setIsProjectModalVisible(false)}
                accountCode={props.accountCode}
                project={selectedProject}
            />

            {
                !!selectedProject &&
                <ModalProjectUsersCP
                    visible={isProjectUserModalVisible}
                    onSave={() => setIsProjectUserModalVisible(false)}
                    onCancel={() => setIsProjectUserModalVisible(false)}
                    projectCode={selectedProject.code}
                />
            }
        </>
    )
}
