import React, { useState } from 'react'
import { TextAreaCP } from 'submodules/nerit-framework-ui/common/components/form-fields/text-area/TextAreaCP'
import { IFormStateManager } from 'common/form-state-manager/IFormStateManager'
import { SurveyChannelsFormModel } from 'modules/survey/components/wizard-survey-config/inner/models/SurveyChannelsFormModel'
import { ChannelEnum } from 'submodules/space4leads-sdk/services/surveys/surveys/enums/ChannelEnum'
import { ChannelConfigTP } from 'submodules/space4leads-sdk/services/surveys/surveys/types/ChannelConfigTP'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'

interface ICPProps {
    channelsFormStateManager: IFormStateManager<SurveyChannelsFormModel>
    onEditing: () => void
}

/**
 * TELA do modulo financeiro
 */
export function SurveyQrCodeLayoutConfigCP(props: ICPProps): JSX.Element {

    const [reload, setReload] = useState<number>(1)

    /**
     * Busca o valor de um canal.
     */
    function getFieldValue(field: string): string {

        const channels: ChannelConfigTP[] = props.channelsFormStateManager.getFieldValue('channels')
        const foundChannel = channels?.find((channel) => channel.channel === ChannelEnum.QRCODE)

        return foundChannel?.config?.layoutConfig?.[field] ?? ''
    }

    /**
     * Altera um valor de um canal
     */
    function changeFieldValue(field: string, value: string): void {
        const channels: ChannelConfigTP[] = props.channelsFormStateManager.getFieldValue('channels')

        // Nesse momento garante que foi criado na instanciacao
        let foundChannel: ChannelConfigTP | undefined = channels?.find((channel) => channel.channel === ChannelEnum.QRCODE)

        if (!foundChannel) {
            foundChannel = { channel: ChannelEnum.QRCODE }
            channels.push(foundChannel)
        }

        if (!foundChannel?.config?.layoutConfig) {
            foundChannel.config = {
                layoutConfig: {
                    title: 'Pesquisa de Satisfação',
                    subtitle: 'Nós queremos te ouvir',
                    footer: 'Aponte a câmera do seu celular para o QR CODE e deixe a sua avaliação',
                }
            }
        }

        foundChannel.config.layoutConfig![field] = value
        setReload(TableUtils.getReloadNumber())
        props.onEditing()
    }

    return (
        <>
            {
                reload &&
                <>
                    <TextAreaCP
                        label={'Título'}
                        required={true}
                        value={getFieldValue('title')}
                        onChange={(val) => changeFieldValue('title', val)}
                    />

                    <TextAreaCP
                        label={'Subtítulo'}
                        required={true}
                        value={getFieldValue('subtitle')}
                        onChange={(val) => changeFieldValue('subtitle', val)}
                    />

                    <TextAreaCP
                        label={'Rodapé'}
                        required={true}
                        value={getFieldValue('footer')}
                        onChange={(val) => changeFieldValue('footer', val)}
                    />
                </>
            }
        </>
    )
}
