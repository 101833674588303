import { RequestUtils } from 'common/request-manager/RequestUtils'
import { useRequest } from 'common/request-manager/use-request/UseRequest'
import React, { useEffect } from 'react'
import FileSaver from 'file-saver'
import { DrawerReferralProgramNotificationsCP } from 'modules/referral-program/components/drawer-referral-program-notifications/DrawerReferralProgramNotificationsCP'
import { NotificationHelper } from 'common/helpers/NotificationHelper'
import { ModalProjectCP } from 'modules/admin/components/modal-project/ModalProjectCP'
import { ModalProjectUsersCP } from 'modules/admin/components/modal-project-users/ModalProjectUsersCP'
import { ModalAccountCP } from 'modules/admin/components/modal-account/ModalAccountCP'

/**
 * Pagina para testar componentes de forma rapida
 *
 * Chamar na ROTA: /dev-test
 */
export function DevTestScreen(): JSX.Element {

    const customRequest = useRequest<Blob>('none')
    useEffect(onCustomRequestChange, [customRequest.isAwaiting])

    useEffect(init, [])

    /**
     * Inicializa.
     */
    function init(): void {
        // funnelStepSummaryDataRequest.runRequest(FunnelRequests.funnelStepsSummaryConfig())
    }

    function onCustomRequestChange(): void {

        if (customRequest.isAwaiting || !customRequest.wasTried)
            return

        if (!customRequest.isSuccess || !customRequest.responseData) {
            console.error('FALHA - ActionsColumn.onDownloadRequestChange: ', customRequest.responseData, customRequest.error)
            return RequestUtils.showDefaultErrorNotification(customRequest.error, 'Falha ao tentar realizar download')

        }

        FileSaver.saveAs(customRequest.responseData, 'teste.zip')
    }

    function doNothing(): void {
        NotificationHelper.warning('Do Nothing')
    }

    return (
        <>
            <DrawerReferralProgramNotificationsCP visible={false} onClose={doNothing} referralProgramCode={100}/>

            <ModalProjectUsersCP visible={false} onSave={doNothing} onCancel={doNothing} projectCode={125}/>
            <ModalProjectCP visible={false} onSave={doNothing} onCancel={doNothing} accountCode={123}/>

            <ModalAccountCP visible={true} onSave={doNothing} onCancel={doNothing} account={{} as any}/>

            {/*<DrawerAccountManagementCP visible={true} onSave={doNothing} onCancel={doNothing}/>*/}

            {/*<SmsTextAreaCP label={'tese'} considerCharLimit={false} mentionOptions={NOTIFICATION_CONTENT_VARS} value={*/}
            {/*    NotificationContentVarsTranslateUtils.translateVarstoPtBr('teste ##indicated_name## ')*/}
            {/*}/>*/}
        </>
    )
}
