import React from 'react'
import { useScreenSize } from 'submodules/nerit-framework-ui/common/responsiveness/use-screen-size/UseScreenSize'
import styledWithTheme from 'submodules/nerit-framework-ui/theme/_old/styledWithTheme'
import { BrowserWarningCP } from 'submodules/nerit-framework-ui/common/components/browser-warning/BrowserWarningCP'
import { UserSystemAccountStatusMessageICP } from 'submodules/nerit-framework-ui/common/components/top-bar-system-messages/inner/UserSystemAccountStatusMessageICP'

interface ICPProps {
    showBrowserWarning: boolean
    accountStatus: {
        isAccountActive: boolean
        disabledDate?: Date
        loggedUserName: string
        signNowLink?: string
    }
}

/**
 * Barra superior com uma mensagem
 */
export function TopBarSystemMessagesCP(props: ICPProps): JSX.Element {

    const screenSize = useScreenSize()

    return (
        <WrapperSCP isSmd={screenSize.smd}>
            {
                props.showBrowserWarning &&
                <BrowserWarningCP type={'topBar'}/>

            }

            <UserSystemAccountStatusMessageICP
                isAccountActive={props.accountStatus.isAccountActive}
                loggedUserName={props.accountStatus.loggedUserName}
                signNowLink={props.accountStatus.signNowLink}
                disabledDate={props.accountStatus.disabledDate}
            />
        </WrapperSCP>
    )
}

const WrapperSCP = styledWithTheme.div<{ isSmd: boolean }>`
    background: #FFF7D6;
    z-index: 9;
    padding-left: ${props => props.isSmd ? '40px' : ''}
`
