import React, { useEffect, useState } from 'react'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { ProjectsRequests } from 'submodules/space4leads-sdk/services/accounts/ProjectsRequests'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { ProjectUsersFormModel } from 'modules/admin/components/modal-edit-project-user/inner/ProjectUsersFormModel'
import { AddUserToProjectSaveRequestDTO } from 'submodules/space4leads-sdk/services/accounts/dtos/requests/AddUserToProjectSaveRequestDTO'
import { TitleCP } from 'submodules/nerit-framework-ui/common/components/title/TitleCP'
import { TableProjectUsersCP } from 'modules/admin/components/table-project-users/TableProjectUsersCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { FlexOneICP } from 'submodules/nerit-framework-ui/common/components/flex/inner/FlexOneICP'
import { ProjectUsersFormICP } from 'modules/admin/components/modal-edit-project-user/inner/ProjectUsersFormICP'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'

interface ICPProps {
    visible: boolean
    onSave: () => void
    onCancel: () => void
    projectCode: number
}

/**
 * Drawer de projeto.
 */
export function ModalProjectUsersCP(props: ICPProps): JSX.Element {

    const [formValidator, setFormValidator] = useState<ProjectUsersFormModel>((new ProjectUsersFormModel()))
    const formStateManager = useFormStateManager<ProjectUsersFormModel>(formValidator)

    const [reloadData, setReloadData] = useState<number>(TableUtils.getReloadNumber())

    const addProjectUserRequest = useRequest<void>('none')
    useEffect(onAddProjectUserRequestChange, [addProjectUserRequest.isAwaiting])

    useEffect(init, [props.visible])

    /**
     * Inicializa a tela.
     */
    function init(): void {

        if (!props.visible)
            return

    }

    /**
     * Salva.
     */
    async function save(): Promise<void> {

        if (!await FormStateManagerUtils.validateRequiredFields(formStateManager))
            return

        const formValues = formStateManager.getFormValues()!
        const dto: AddUserToProjectSaveRequestDTO = {
            projectCode: props.projectCode,
            permission: formValues.permission,
            user: {
                name: formValues.name,
                email: formValues.email
            }
        }
        addProjectUserRequest.runRequest(ProjectsRequests.addUser(dto))

        // Coloca aqui para resetar o campo depois que voltar da API
        setFormValidator(new ProjectUsersFormModel())
    }

    /**
     * Apos reotorno da api de salvar
     */
    function onAddProjectUserRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(addProjectUserRequest, NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE, NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE, true))
            return

        setReloadData(TableUtils.getReloadNumber())
    }

    return (
        <ModalCP
            title={'Usuários com acesso ao projeto'}
            visible={props.visible}
            onCancel={props.onCancel}
            width={700}
            noFooter={true}
        >

            <FlexCP alignItems={'center'}>
                <FlexOneICP>
                    <ProjectUsersFormICP
                        formStateManager={formStateManager}
                        isSaving={addProjectUserRequest.isAwaiting}
                        isEdit={false}
                    />
                </FlexOneICP>

                <ButtonCP
                    icon={'plus'}
                    onClick={save}
                    loading={addProjectUserRequest.isAwaiting}
                    marginLeft={10}
                />
            </FlexCP>

            <TitleCP textSize={'normal'} underLine={true} marginTop={30}>
                Usuários vinculados
            </TitleCP>
            <TableProjectUsersCP
                projectCode={props.projectCode}
                reloadData={reloadData}
            />

        </ModalCP>
    )
}
