import React, { useEffect } from 'react'
import { HeaderCP } from 'common/components/screen-layout/header/HeaderCP'
import { RoutingHelper } from 'config/RoutingHelper'
import { useRequest } from 'common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'common/request-manager/RequestUtils'
import { useParams } from 'react-router-dom'
import { IReferralProgramResponseDTO } from 'modules/referral-program/services/referral-program/dtos/responses/IReferralProgramResponseDTO'
import { ReferralProgramRequests } from 'modules/referral-program/services/referral-program/ReferralProgramRequests'
import { ReferralProgramRoutes } from 'modules/referral-program/routes/ReferralProgramRoutes'
import { ReferralProgramConfigCP } from 'modules/referral-program/components/referral-program-config/ReferralProgramConfigCP'
import { LoadingOverlayCP } from 'common/components/loading/loading-overlay/LoadingOverlayCP'
import { PermissionEnum } from 'submodules/space4leads-sdk/services/people/person/enums/PermissionEnum'
import { AccessControlCP } from 'common/components/access-control/AccessControlCP'

/**
 * TELA de criacao/edicao de um Programa de Indicacao.
 */
export function ScreenReferralProgramConfig(): JSX.Element {

    const routeParams = useParams<{ code?: string }>()

    const getReferralProgramyRequest = useRequest<IReferralProgramResponseDTO>()
    useEffect(onGetReferralProgramRequestChange, [getReferralProgramyRequest.isAwaiting])

    useEffect(init, [])

    /**
     * Inicializa dados da tela
     */
    function init(): void {

        if (!routeParams.code)
            return

        getReferralProgramyRequest.runRequest(ReferralProgramRequests.findByCode(Number(routeParams.code)))
    }

    /**
     * Retorno da requisicao para.
     */
    function onGetReferralProgramRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getReferralProgramyRequest, 'Erro obter programa'))
            return

    }

    return (
        <AccessControlCP permissions={[PermissionEnum.ADMIN, PermissionEnum.EDITOR]}>
            <HeaderCP
                title={'Configurações do Programa de Indicação'}
                onClickBack={() => RoutingHelper.historyPush(ReferralProgramRoutes.ROOT)}
            />

            <LoadingOverlayCP show={getReferralProgramyRequest.isAwaiting}/>
            <ReferralProgramConfigCP
                referralProgram={getReferralProgramyRequest.responseData}
            />
        </AccessControlCP>
    )
}
