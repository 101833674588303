import { ReferralProgramRoutes } from 'modules/referral-program/routes/ReferralProgramRoutes'
import { IReferralProgramResponseDTO } from 'modules/referral-program/services/referral-program/dtos/responses/IReferralProgramResponseDTO'
import { SystemConfig } from 'config/SystemConfig'
import { RoutingHelper } from 'config/RoutingHelper'

/**
 * Util do modulo de programas de indicacao
 */
export const ReferralProgramUtils = {

    /**
     * Obtem a url do portal.
     */
    getCustomerDashboardUrl(referralProgram: IReferralProgramResponseDTO): string {
        return referralProgram.customArgs?.externalUrls?.externalDashboardUrl ?? `${ReferralProgramRoutes.CUSTOMER_REFERRALS_LP}/${referralProgram.slug}`
    },

    /**
     * Obtem a url de inscricao na campanha.
     */
    getSubscribeUrl(referralProgram: IReferralProgramResponseDTO, considerFullUrl?: boolean): string {

        if (!!referralProgram.customArgs?.externalUrls?.externalSubscribeUrl)
            return referralProgram.customArgs.externalUrls.externalSubscribeUrl

        if (considerFullUrl)
            return `${SystemConfig.getInstance().uiBaseUrl}${ReferralProgramRoutes.REFERRAL_PROGRAM_SUBSCRIBE_LP}/${referralProgram.slug}`

        return `${ReferralProgramRoutes.REFERRAL_PROGRAM_SUBSCRIBE_LP}/${referralProgram.slug}`
    },

    /**
     * Realiza o redirect ou muda o path, caso tenha HTTP ou nao toma a decisao.
     */
    redirectOrHistoryPush(url: string): void {

        if (!url.includes('http')) {
            RoutingHelper.historyPush(url)
            return
        }

        window.top.location.href = url
    },
}
