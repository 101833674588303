import React from 'react'
import styled from 'styled-components'
import * as _ from 'lodash'

type _ScpPropsTP = {
    marginRight?: number,
    marginLeft?: number,
    marginTop?: number,
    marginBottom?: number,
    align?: 'center',
}

interface ILogoCPProps extends _ScpPropsTP {
    imageUrl?: string
    width?: string
}

/**
 * COMPONENTE: Logo.
 */
export function LogoCP(props: ILogoCPProps): JSX.Element {

    return (
        <WrapperSCP
            marginTop={props.marginTop ?? 20}
            marginRight={props.marginRight ?? 0}
            marginBottom={props.marginBottom ?? 20}
            marginLeft={props.marginLeft ?? 0}
            align={props.align}
        >
            <img
                src={_.isEmpty(props.imageUrl) ? `${process.env.PUBLIC_URL}/logo-sistema.png` : props.imageUrl}
                alt={'logo'}
                width={props.width ?? '250px'}
            />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div<_ScpPropsTP>`
    margin: ${props => `${props.marginTop!}px ${props.marginRight!}px ${props.marginBottom!}px ${props.marginLeft!}px`};
    
    text-align: ${props => props.align ?? ''};
`
