import * as React from 'react'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { FallbackScreenContentCP } from 'common/components/fallback-screen-content/FallbackScreenContent'

/**
 * Pagina 404 - De uma pesquisa nao encontrada ou com parametros errados.
 */
export function ScreenSurveyNotFound(): JSX.Element {
    return (
        <>
            <FallbackScreenContentCP
                status={HttpStatusEnum.NOT_FOUND}
                title={'Oops! Pesquisa não encontrada!'}
                bottomContent={
                    <>Não foi encontrada a pesquisa para os parâmetros informados</>
                }
            />
        </>
    )
}
