import React from 'react'
import styled from 'styled-components'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import { InputMaskTypeEnum } from 'common/enums/InputMaskTypeEnum'
import { CheckboxCP } from 'common/components/checkbox/CheckboxCP'
import { IFormStateManager } from 'common/form-state-manager/IFormStateManager'
import { ReferralProgramSubscribeFormModel } from 'modules/referral-program/components/wizard-referral-program-subscribe/models/ReferralProgramSubscribeFormModel'

interface ICPProps {
    referralProgramTermUrl: string
    formStateManager?: IFormStateManager<ReferralProgramSubscribeFormModel>
}

/**
 * Etapa de insercao de dados.
 */
export function StepCustomerDataICP(props: ICPProps): JSX.Element {

    return (
        <WrapperSCP>
            <InputCP
                label={'Nome completo'}
                required={true}
                formStateManager={props.formStateManager}
                fieldName={'name'}
                disabled={!props.formStateManager}
            />

            <InputCP
                label={'Telefone celular'}
                required={true}
                mask={InputMaskTypeEnum.PHONE}
                formStateManager={props.formStateManager}
                fieldName={'phone'}
                disabled={!props.formStateManager}
            />

            <InputCP
                label={'E-mail'}
                type={'email'}
                formStateManager={props.formStateManager}
                fieldName={'email'}
                required={true}
                disabled={!props.formStateManager}
            />

            <CheckboxCP
                onChange={(checked) => props.formStateManager?.changeFieldValue('isTermChecked', checked)}
                isChecked={props.formStateManager?.getFieldValue('isTermChecked')}
                disabled={!props.formStateManager}
            >
                <>
                    Li e concordo com o <a href={props.referralProgramTermUrl} target={'_blank'} rel={'noopener noreferrer'}>Regulamento</a>
                </>
            </CheckboxCP>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    a {
      text-decoration: underline;
    }
`
