import { registerDecorator, ValidationArguments, ValidationOptions, Validator, ValidatorConstraint, ValidatorConstraintInterface } from 'class-validator'
import { ValidationTypes } from '../ValidationTypes'

/**
 * VALIDATOR
 * Valida numero de telefone (celular e fixo)
 *
 * @author guilherme.diniz
 */
@ValidatorConstraint({ name: ValidationTypes.IS_PHONE_BR })
class IsPhoneBRConstraint implements ValidatorConstraintInterface {
    validate(value: string, args: ValidationArguments): boolean {

        const validator = new Validator()

        if (!!args.constraints[0]) {
            return validator.matches(value,
                /\(\d{2}\)(\s*:?)(9\d{4}-\d{4}|[1-6]\d{3}-\d{4})/)
        }

        return validator.isNumberString(value) && validator.matches(value, /\d{2}(9\d{8}|[1-6]\d{7})/)
    }

    defaultMessage(args: ValidationArguments): string {
        return !!args.constraints[0]
            ? 'Telefone inválido! ((XX) XXXX-XXXX ou (XX) 9XXXX-XXXX)'
            : 'Telefone inválido!'
    }
}

/**
 * DECORATOR
 * @param mask
 * @param {ValidationOptions} validationOptions
 * @return {(object: Object, propertyName: string) => void}
 * @constructor
 */
export function IsPhoneBR(mask = false, validationOptions?: ValidationOptions) {
    return (object: {}, propertyName: string) => {
        registerDecorator({
            target: object.constructor,
            propertyName,
            options: validationOptions,
            constraints: [mask],
            validator: IsPhoneBRConstraint
        })
    }
}
