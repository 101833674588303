import React from 'react'
import styled from 'styled-components'

interface ILayoutSubmenuContentCPProps {
    children: JSX.Element | JSX.Element[]
}

/**
 * Componente LayoutCP para telas que possuem submenu.
 */
export function LayoutSubmenuContentCP(props: ILayoutSubmenuContentCPProps): JSX.Element {

    return (
        <ContentSCP>
            <ContentWrapperSCP>
                {props.children}
            </ContentWrapperSCP>
        </ContentSCP>
    )
}

const ContentSCP = styled.div`
  display: flex;
  overflow-x: auto;
  justify-content: center;
`

const ContentWrapperSCP = styled.div`
  width: 100%;
`
