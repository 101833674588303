import React from 'react'
import styled from 'styled-components'
import { ResponsivePie } from '@nivo/pie'
import { LoadingOverlayCP } from 'common/components/loading/loading-overlay/LoadingOverlayCP'

export type PieChartSeriesTP = {
    id: string,
    label: string,
    value: number,
}

interface IScpProps {
    height: number
    width?: string
}

interface IChartPieCPProps extends IScpProps{
    series: PieChartSeriesTP[]
    loading?: boolean
}

/**
 * COMPONENTE Grafico de Pizza.
 */
export function ChartPieCP(props: IChartPieCPProps): JSX.Element {

    return (
        <ChartWrapperSCP
            height={props.height}
            width={props.width}
        >
            <LoadingOverlayCP show={props.loading ?? false}/>

            <ResponsivePie
                data={props.series}
                // margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                innerRadius={0.2}
                padAngle={2}
                cornerRadius={5}
                colors={{ scheme: 'category10' }}
                borderWidth={1}
                borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                radialLabel={'label'}
                enableRadialLabels={false}
                sliceLabel={'label'}
                tooltip={(a) => (
                    <>
                        <b>
                            {a.datum.label}
:
                        </b>
                        {' '}
                        {a.datum.value}
                    </>
                )}
                radialLabelsSkipAngle={10}
                radialLabelsTextColor={'#333333'}
                radialLabelsLinkColor={{ from: 'color' }}
                sliceLabelsSkipAngle={10}
                sliceLabelsTextColor={'#333333'}
                radialLabelsLinkHorizontalLength={0}
            />
        </ChartWrapperSCP>
    )
}

const ChartWrapperSCP = styled.div<IScpProps>`
  height: ${props => props.height}px;
  width: ${props => props.width};
`
