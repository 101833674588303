import { SystemConfig } from 'config/SystemConfig'
import { IReferralProgramStatusReportRequestDTO } from 'modules/reports/services/referral-program-reports/dtos/requests/IReferralProgramStatusReportRequestDTO'
import { HttpMethodEnum } from 'common/enums/HttpMethodEnum'
import { RequestConfigTP } from 'common/request-manager/types/RequestConfigTP'

/**
 * Requests de relatorios de venda
 */
export class ReferralProgramReportRequests {

    private static readonly _MODULE_BASE = `${SystemConfig.getInstance().apiBaseUrl}/referral-programs/report`

    static getAdheredReport = (dto: IReferralProgramStatusReportRequestDTO): RequestConfigTP => ({
        url: `${ReferralProgramReportRequests._MODULE_BASE}/adhered`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static getConversionsByEventReport = (dto: IReferralProgramStatusReportRequestDTO): RequestConfigTP => ({
        url: `${ReferralProgramReportRequests._MODULE_BASE}/events-performed`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static getGoalsPerformedReport = (dto: IReferralProgramStatusReportRequestDTO): RequestConfigTP => ({
        url: `${ReferralProgramReportRequests._MODULE_BASE}/goals-performed`,
        method: HttpMethodEnum.GET,
        params: dto
    })

}