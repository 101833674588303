import { MentionCP } from 'common/components/form-fields/mention/MentionCP'
import { ProgressCP } from 'common/components/progress/ProgressCP'
import { TextCP } from 'common/components/text/TextCP'
import { IFormStateManager } from 'common/form-state-manager/IFormStateManager'
import React, { useState } from 'react'
import { MARKETING_CONTENT_VARS } from 'common/components/sms-textarea/inner/MarketingContentVarsConfig'
import { MarketingContentVarsConfigTP } from 'modules/marketing/types/MarketingContentVarsConfigTP'

const TOTAL_CHARS_BY_MSG = 160

interface ISmsTextAreaCPProps {
    formStateManager?: IFormStateManager<any>
    fieldName?: string
    value?: string
    onChange?: (text: string) => void
    label: string
    considerCharLimit: boolean
    mentionOptions?: MarketingContentVarsConfigTP[]
}

/**
 * Text area para texto de SMS, autocompleta com as variaveis que podem ser utilizadas
 */
export function SmsTextAreaCP(props: ISmsTextAreaCPProps): JSX.Element {

    const [msgPercent, setMsgPercent] = useState<number>(0)
    const [totalChars, setTotalChars] = useState<number>(0)

    /**
     * Ao mudar o valor.
     */
    function onChange(text: string): void {
        setTotalChars(text.length)
        setMsgPercent(Math.ceil((text.length / TOTAL_CHARS_BY_MSG) * 100))

        props.onChange?.(text)
    }

    return (
        <>
            <MentionCP
                label={props.label}
                prefix={'#'}
                required={true}
                formStateManager={props.formStateManager}
                fieldName={props.fieldName}
                options={props.mentionOptions ?? MARKETING_CONTENT_VARS}
                onChange={onChange}
                value={props.value}
                rows={3}
                maxLength={props.considerCharLimit ? TOTAL_CHARS_BY_MSG : undefined}
            />

            {
                props.considerCharLimit &&
                <>
                    <ProgressCP percent={msgPercent} showInfo={false}/>
                    <TextCP text={`Caracteres restantes: ${totalChars}/${TOTAL_CHARS_BY_MSG}`}/>
                </>
            }
        </>
    )
}
