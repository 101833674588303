import React from 'react'
import { IFormStateManager } from 'common/form-state-manager/IFormStateManager'
import { SurveyQuestionFormModel } from 'modules/survey/components/wizard-survey-config/inner/models/SurveyQuestionFormModel'
import { SurveyQuestionsStepICP } from 'modules/survey/components/wizard-survey-config/inner/steps/SurveyQuestionsStepICP'
import { SurveyWizardUtils } from 'modules/survey/components/wizard-survey-config/inner/SurveyWizardUtils'
import { SurveyThanksStepICP } from 'modules/survey/components/wizard-survey-config/inner/steps/SurveyThanksStepICP'
import { SurveyThanksFormModel } from 'modules/survey/components/wizard-survey-config/inner/models/SurveyThanksFormModel'
import { SurveyChannelsStepICP } from 'modules/survey/components/wizard-survey-config/inner/steps/SurveyChannelsStepICP'
import { NpsScreenPreviewViewTP } from 'modules/survey/components/survey-config/inner/NpsScreenPreviewViewTP'
import { SurveyTypeEnum } from 'submodules/space4leads-sdk/services/surveys/surveys/enums/SurveyTypeEnum'
import { SurveyChannelsFormModel } from 'modules/survey/components/wizard-survey-config/inner/models/SurveyChannelsFormModel'
import { WizardCP } from 'submodules/nerit-framework-ui/common/components/wizard/WizardCP'
import { SurveyResponseDTO } from 'submodules/space4leads-sdk/services/surveys/surveys/dtos/response/SurveyResponseDTO'

interface ICPProps {
    survey?: SurveyResponseDTO
    questionsFormStateManager: IFormStateManager<SurveyQuestionFormModel>
    thanksFormStateManager: IFormStateManager<SurveyThanksFormModel>
    channelsFormStateManager: IFormStateManager<SurveyChannelsFormModel>
    onConfirm: () => void
    onChangeStep: (previewView: NpsScreenPreviewViewTP) => void
    type: SurveyTypeEnum
    onEditingChannel: () => void
}

/**
 * COMPOENENT Passo a passo para configurar a pesquisa.
 */
export function WizardSurveyConfigCP(props: ICPProps): JSX.Element {

    /**
     * Ao mudar de etapa define a pre-visualizacao a ser exibida no lado direito
     */
    function onChangeStep(currentStep: number): void {

        // Define a visao de preview de acordo com a etapa
        switch (currentStep) {
            case 0:
                props.onChangeStep('surveyForm')
                break

            case 1:
                props.onChangeStep('thanksForm')
                break

            case 2:
                props.onChangeStep('channelView')
                break

            default:
                break
        }

    }

    return (
        <WizardCP
            loading={false}
            action={props.onConfirm}
            onChangeStep={onChangeStep}
            steps={[
                {
                    key: 'question',
                    title: 'Pergunta',
                    content: <SurveyQuestionsStepICP formStateManager={props.questionsFormStateManager} type={props.type}/>,
                    nextAction: async () => SurveyWizardUtils.onFormSubmit(props.questionsFormStateManager)
                },
                {
                    key: 'thanks',
                    title: 'Thanks',
                    content: <SurveyThanksStepICP formStateManager={props.thanksFormStateManager}/>,
                    hide: !props.survey,
                    nextAction: props.survey ? undefined :
                        () => {
                            props.onConfirm()
                            return true
                        } // Quando for criacao, a criacao eh feita nesse momento para configurar os canais
                },
                {
                    key: 'channels',
                    title: 'Canais',
                    content:
                        <SurveyChannelsStepICP
                            survey={props.survey}
                            channelsFormStateManager={props.channelsFormStateManager}
                            onEditing={props.onEditingChannel}
                        />,
                },
            ]}
        />
    )
}
