import React from 'react'
import { ContentCP } from 'common/components/screen-layout/content/ContentCP'
import { HeaderCP } from 'common/components/screen-layout/header/HeaderCP'
import { HeaderButtonICP } from 'common/components/screen-layout/header/inner/HeaderButtonICP'
import styled from 'styled-components'
import { ListReferralProgramsCP } from 'modules/referral-program/components/list-referral-programs/ListReferralProgramsCP'
import { RoutingHelper } from 'config/RoutingHelper'
import { ReferralProgramRoutes } from 'modules/referral-program/routes/ReferralProgramRoutes'
import { PermissionEnum } from 'submodules/space4leads-sdk/services/people/person/enums/PermissionEnum'
import { AccessControlCP } from 'common/components/access-control/AccessControlCP'

/**
 * TELA de listagem de Indicacoes
 */
export function ScreenReferralProgramList(): JSX.Element {

    return (
        <>
            <HeaderCP
                title={'Programas de Indicação'}
            >
                <AccessControlCP permissions={[PermissionEnum.ADMIN, PermissionEnum.EDITOR]}>
                    <HeaderButtonICP
                        onClick={() => RoutingHelper.historyPush(ReferralProgramRoutes.SETUP)}
                        icon={'flag'}
                        label={'Novo programa'}
                    />
                </AccessControlCP>
            </HeaderCP>

            <ContentCP overflow={'auto'}>
                <ContentSCP>
                    <ListReferralProgramsCP/>
                </ContentSCP>
            </ContentCP>
        </>
    )

}

const ContentSCP = styled.div`
  padding: 20px;
  margin-bottom: 50px;
  max-width: 100%;
`
