/* eslint-disable react/jsx-props-no-spreading */
import React, { CSSProperties } from 'react'
import { Draggable, DraggableProvided, DraggingStyle, NotDraggingStyle } from 'react-beautiful-dnd'

interface IDragAndDropListItemICPProps {
    id: number
    index: number
    children: JSX.Element
    style?: CSSProperties
}

/**
 * Componente genérico item de uma lista drag and drop
 * @author Rafael V.
 */
export function DragAndDropListItemICP(props: IDragAndDropListItemICPProps): JSX.Element {

    function getItemStyle(draggableStyle?: DraggingStyle | NotDraggingStyle): CSSProperties {
        return (
            {
                userSelect: 'none',
                margin: '5px',
                padding: '0 10px',
                background: 'white',
                ...props.style,
                ...draggableStyle
            }
        )
    }

    return (

        <Draggable key={props.id} draggableId={`${props.id}`} index={props.index}>
            {(providedDraggable: DraggableProvided): JSX.Element => (
                <div
                    ref={providedDraggable.innerRef}
                    {...providedDraggable.draggableProps}
                    {...providedDraggable.dragHandleProps}
                    style={getItemStyle(providedDraggable.draggableProps.style)}
                >
                    {props.children}
                </div>
            )}
        </Draggable>

    )
}
