import { ICustomerReferralsResponseDTO } from 'modules/referral-program/services/referral-program/dtos/responses/ICustomerReferralsResponseDTO'
import { ICustomerPersonByReferralProgramResponseDTO } from 'modules/person/services/dtos/response/ICustomerPersonByReferralProgramResponseDTO'
import { IReferralProgramSubscribeItemRequestDTO } from 'modules/person/services/dtos/request/IReferralProgramSubscribeItemRequestDTO'
import { NotificationChannelEnum } from 'modules/marketing/services/notification/enums/NotificationChannelEnum'
import { NotificationRecipientEnum } from 'modules/marketing/services/notification/enums/NotificationRecipientEnum'
import { NotificationTriggerEnum } from 'modules/marketing/services/notification/enums/NotificationTriggerEnum'
import { INotificationResponseDTO } from 'modules/marketing/services/notification/dtos/responses/INotificationResponseDTO'

export const DEV_CUSTOMER_MOCK: ICustomerPersonByReferralProgramResponseDTO = {
    code: 117,
    name: 'Renato Santos',
    phone: '31991171111',
    email: 'renatofsantos17@gmail.com',
    token: 'MTE3',
    referralFranchise: { code: 100, name: 'Unidade' },
}

export const DEV_CUSTOMER_SUBSCRIBE_MOCK: IReferralProgramSubscribeItemRequestDTO = {
    name: 'Renato Santos',
    phone: '31991171111',
}

export const DEV_MY_REFERRALS_MOCK: ICustomerReferralsResponseDTO[] = [{
    person: DEV_CUSTOMER_MOCK,
    eventsDone: [{ code: 123, name: 'Avaliação' }],
    eventsAwaiting: [{ code: 123, name: 'Realizar uma sessão' }]
}]

export const DEV_NOTIFICATIONS: INotificationResponseDTO[] = [
    {
        code: 100,
        isActive: true,
        channel: NotificationChannelEnum.SMS,
        recipient: NotificationRecipientEnum.WHO_INDICATES,
        trigger: NotificationTriggerEnum.SUBSCRIBE,
        subject: 'Assunto',
        message: 'Mensagem',
    },
    {
        code: 101,
        isActive: true,
        channel: NotificationChannelEnum.WHATSAPP,
        recipient: NotificationRecipientEnum.WHO_INDICATES,
        trigger: NotificationTriggerEnum.SUBSCRIBE,
        subject: 'Assunto 2',
        message: 'Mensagem 2',
    },
]