import React from 'react'
import { LayoutSubmenuCP } from 'common/components/screen-layout-submenu/layout/LayoutSubmenuCP'
import { ADMIN_SUBMENU_SYSTEM_KEY, AdminScreenUtils, AdminViewTP } from 'modules/admin/screens/screen-admin/inner/AdminScreenUtils'

/**
 * TELA de administração do sistema.
 */
export function ScreenAdmin(): JSX.Element {

    return (
        <LayoutSubmenuCP<AdminViewTP>
            menuTitle={'Configurações'}
            defaultSubmenu={'systemAccountView'}
            defaultOpenKeys={[ADMIN_SUBMENU_SYSTEM_KEY]}
            submenuConfigs={AdminScreenUtils.getSubMenuConfigs()}
        />
    )
}
