import { Icon, Steps } from 'antd'
import React, { useState } from 'react'
import styled from 'styled-components'
import { IWizardSteps } from 'common/components/wizard/inner/IWizardSteps'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { LoadingOverlayCP } from 'common/components/loading/loading-overlay/LoadingOverlayCP'

// eslint-disable-next-line @typescript-eslint/naming-convention
const { Step } = Steps

interface IWizardCPProps {
    steps: IWizardSteps[]
    action: () => void
    loading?: boolean
    showBackButton?: boolean
    onChangeStep?: (currentStep: number) => void
}

/**
 * Componente de Passo a passo.
 */
export function WizardCP(props: IWizardCPProps): JSX.Element {

    const [currentStepIndex, setCurrentStepIndex] = useState<number>(0)
    const currentStep = props.steps[currentStepIndex]

    /**
     * Avancar.
     */
    async function next(customAction?: () => Promise<boolean> | boolean, nextStep?: number): Promise<void> {

        let canGoNext = true
        if (customAction)
            canGoNext = await customAction()

        if (!canGoNext)
            return

        const newStep = nextStep ?? (currentStepIndex + 1)

        setCurrentStepIndex(newStep)
        props.onChangeStep?.(newStep)
    }

    /**
     * Voltar.
     */
    function prev(prevStep?: number): void {

        const newStep = prevStep ?? (currentStepIndex - 1)

        setCurrentStepIndex(newStep)
        props.onChangeStep?.(newStep)
    }

    return (
        <WizardContainerSCP>
            <LoadingOverlayCP show={props.loading || false}/>

            <Steps
                current={currentStepIndex}
                onChange={(stepSelected) => {
                    if (stepSelected > currentStepIndex)
                        next(props.steps[currentStepIndex].nextAction, stepSelected)
                    else
                        prev()
                }}
            >
                {props.steps.map(item => (
                    <Step key={item.key} title={item.title} icon={item.icon && <Icon type={item.icon}/>}/>
                ))}
            </Steps>

            <ContentSCP>
                {currentStep.content}
            </ContentSCP>

            <ButtonsSCP>

                {
                    (props.showBackButton || props.showBackButton === undefined) && currentStepIndex > 0 &&
                    <ButtonCP
                        marginRight={8}
                        onClick={() => prev()}
                        type={'ghost'}
                    >
                        Voltar
                    </ButtonCP>
                }

                {currentStepIndex < props.steps.length - 1 && (
                    <ButtonCP
                        type={'primary'}
                        disabled={!!currentStep.canContinue && !currentStep.canContinue()}
                        onClick={() => { next(currentStep.nextAction) }}
                    >
                        Próximo
                    </ButtonCP>
                )}

                {currentStepIndex === props.steps.length - 1 && (
                    <ButtonCP
                        type={'primary'}
                        disabled={!!currentStep.canContinue && !currentStep.canContinue()}
                        onClick={() => {
                            props.action()
                        }}
                    >
                        Confirmar
                    </ButtonCP>
                )}
            </ButtonsSCP>

        </WizardContainerSCP>
    )

}

const WizardContainerSCP = styled.div`
    margin: 10px 0
`

const ContentSCP = styled.div`
    margin-top: 16px;
    border: 1px dashed #e9e9e9;
    border-radius: 6px;
    padding: 10px;
`

const ButtonsSCP = styled.div`
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
`
