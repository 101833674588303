import { LoadingOverlayCP } from 'common/components/loading/loading-overlay/LoadingOverlayCP'
import { PopConfirmCP } from 'common/components/pop-confirm/PopConfirmCP'
import { TooltipCP } from 'common/components/tooltip/TooltipCP'
import { NotificationHelper } from 'common/helpers/NotificationHelper'
import { RoutingHelper } from 'config/RoutingHelper'
import { RequestUtils } from 'common/request-manager/RequestUtils'
import { useRequest } from 'common/request-manager/use-request/UseRequest'
import { useScreenSize } from 'common/responsiveness/use-screen-size/UseScreenSize'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { CsatRoutes } from 'modules/survey/routes/CsatRoutes'
import { IconCP } from 'common/components/icons/IconCP'
import { RequestConfigTP } from 'common/request-manager/types/RequestConfigTP'
import { SurveyRequests } from 'modules/survey/services/survey/SurveyRequests'
import { ReportsRoutes } from 'modules/reports/routes/ReportsRoutes'
import { AccessControlCP } from 'common/components/access-control/AccessControlCP'
import { PermissionEnum } from 'submodules/space4leads-sdk/services/people/person/enums/PermissionEnum'

type _ActionTP = 'delete'

interface IShotListItemActionsColumnICPProps {
    code: number
    surveyLink: string
    onUpdateList?: () => void
}

/**
 * Acoes com cada CSAT.
 */
export function CsatListItemActionsColumnICP(props: IShotListItemActionsColumnICPProps): JSX.Element {

    const screenSize = useScreenSize()

    const [action, setAction] = useState<_ActionTP>()
    const actionRequest = useRequest<any>('none')
    useEffect(onActionRequestChange, [actionRequest.isAwaiting])

    /**
     * Roda o request correto.
     */
    function runActionRequest(selectedAction: _ActionTP): void {

        let configGetter: (code: number) => RequestConfigTP

        switch (selectedAction) {
            case 'delete':
                configGetter = SurveyRequests.delete
                break

            default:
                return
        }

        const requestConfig = configGetter(props.code)
        actionRequest.runRequest(requestConfig)
        setAction(selectedAction)
    }

    /**
     * Retorno ao chamar requisicao da acao.
     */
    function onActionRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(actionRequest, 'Ocorreu algun erro realizar a operação'))
            return

        switch (action) {

            case 'delete':
                NotificationHelper.success('Pronto!', 'Pesquisa removida com sucesso')
                break

            // no default
        }

        props.onUpdateList?.()
    }

    return (
        <ColumnSCP isSmallDevice={screenSize.smd}>
            <LoadingOverlayCP show={actionRequest.isAwaiting}/>

            <TooltipCP text={'Abrir página da pesquisa'} placement={'left'}>
                <a href={props.surveyLink} target={'_blank'} rel={'noopener noreferrer'}>
                    <IconCP antIcon={'link'}/>
                </a>
            </TooltipCP>

            <AccessControlCP permissions={[PermissionEnum.ADMIN, PermissionEnum.EDITOR]}>
                <TooltipCP text={'Editar'} placement={'left'}>
                    <div onClick={() => RoutingHelper.historyPush(`${CsatRoutes.SETUP}/${props.code}`)}>
                        <IconCP antIcon={'edit'}/>
                    </div>
                </TooltipCP>
            </AccessControlCP>

            <AccessControlCP permissions={[PermissionEnum.ADMIN, PermissionEnum.EDITOR]}>
                <PopConfirmCP
                    title={'Tem certeza que deseja remover esta pesquisa?'}
                    onConfirm={async () => runActionRequest('delete')}
                >
                    <div>
                        <IconCP antIcon={'delete'}/>
                    </div>
                </PopConfirmCP>
            </AccessControlCP>

            <TooltipCP text={'Ver relatório'} placement={'left'}>
                <div onClick={() => RoutingHelper.historyPush(`${ReportsRoutes.CSAT_ANSWERS_REPORT}?${ReportsRoutes.SURVEY_CODE_PARAM}=${props.code}`)}>
                    <IconCP antIcon={'line-chart'}/>
                </div>
            </TooltipCP>
        </ColumnSCP>
    )

}

const ColumnSCP = styled.div<{ isSmallDevice: boolean }>`

    display: ${props => (props.isSmallDevice ? 'flex' : 'inline-grid')};
    padding: 10px;
    align-items: center;
    border-left: 1px solid #dedede;
    background: #f9f9f9;
    justify-content: ${props => (props.isSmallDevice ? 'space-evenly' : 'center')};
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;

    div {
        cursor: pointer;
    }
`
