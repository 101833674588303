import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { IReferralProgramResponseDTO } from 'modules/referral-program/services/referral-program/dtos/responses/IReferralProgramResponseDTO'
import { useRequest } from 'common/request-manager/use-request/UseRequest'
import { ReferralProgramRequests } from 'modules/referral-program/services/referral-program/ReferralProgramRequests'
import { RequestUtils } from 'common/request-manager/RequestUtils'
import { LoadingOrErrorCP } from 'common/components/loading/LoadingOrErrorCP'
import { Helmet } from 'react-helmet'

interface ICPProps {
    children: JSX.Element
    onLoadReferralProgram: (referralProgram: IReferralProgramResponseDTO) => void
}

/**
 * Wrapper das LPs de programa de indicacao
 */
export function ReferralProgramLPWrapperCP(props: ICPProps): JSX.Element {

    const routeParams = useParams<{ slug: string }>()
    const [isRouteValid, setIsRouteValid] = useState<boolean>()

    const [loadedReferralProgram, setLoadedReferralProgram] = useState<IReferralProgramResponseDTO>()
    const getReferralProgramRequest = useRequest<IReferralProgramResponseDTO>()
    useEffect(onGetReferralProgramRequestChange, [getReferralProgramRequest.isAwaiting])

    useEffect(init, [])

    /**
     * Inicializa a tela
     */
    function init(): void {

        if (!routeParams.slug) {
            setIsRouteValid(false)
            return
        }

        getReferralProgramRequest.runRequest(ReferralProgramRequests.findBySlug(routeParams.slug))
    }

    /**
     * Retorno da requisicao para pegar o programa de indicacao.
     */
    function onGetReferralProgramRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getReferralProgramRequest, 'Erro obter ao obter o programa de indicacao'))
            return

        const responseData = getReferralProgramRequest.responseData!
        setLoadedReferralProgram(responseData)
        setIsRouteValid(true)

        props.onLoadReferralProgram(responseData)
    }

    if (!isRouteValid || !loadedReferralProgram)
        return <LoadingOrErrorCP isLoading={isRouteValid === undefined} isValid={!!isRouteValid && !!loadedReferralProgram}/>

    return (
        <>
            <Helmet>
                <title>{loadedReferralProgram.name}</title>
            </Helmet>
            { props.children }
        </>
    )
}
