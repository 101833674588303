import React from 'react'
import { ScreenSubMenuTP } from 'common/components/screen-layout-submenu/submenu/inner/ScreenSubMenuItemTP'
import { ReportsViewTP } from 'modules/reports/screens/reports/inner/ReportsViewTP'
import { ReportsRoutes } from 'modules/reports/routes/ReportsRoutes'
import { ScreenContentReferralProgramLeadsReportCP } from 'modules/reports/screens/reports/content/ScreenContentReferralProgramLeadsReportCP'
import { ScreenContentReferralProgramAnalysisCP } from 'modules/reports/screens/reports/content/ScreenContentReferralProgramAnalysisCP'
import { ScreenContentNpsAnswersReportCP } from 'modules/reports/screens/reports/content/ScreenContentNpsAnswersReportCP'
import { ScreenContentCsatAnswersReportCP } from 'modules/reports/screens/reports/content/ScreenContentCsatAnswersReportCP'
import { ScreenContentMultiProjectsNpsCP } from 'modules/reports/screens/reports/content/ScreenContentMultiProjectsNpsCP'

export const REFERRAL_PROGRAMS_SUBMENU_KEY = 'referral-program-programs'
export const SURVEYS_SUBMENU_KEY = 'surveys'
export const MULTI_PROJECTS_SUBMENU_KEY = 'multi-projects'

/**
 * Utils da tela de relatorios
 */
export const ReportsScreenUtils = {

    getSubMenuConfigs(): Array<ScreenSubMenuTP<ReportsViewTP>> {
        return [
            {
                key: SURVEYS_SUBMENU_KEY,
                title: 'Pesquisas',
                items: [
                    {
                        name: 'Respostas NPS',
                        view: 'npsAnswersReportView',
                        route: ReportsRoutes.NPS_ANSWERS_REPORT,
                        content: <ScreenContentNpsAnswersReportCP/>,
                    },
                    {
                        name: 'Respostas CSAT',
                        view: 'csatAnswersReportView',
                        route: ReportsRoutes.CSAT_ANSWERS_REPORT,
                        content: <ScreenContentCsatAnswersReportCP/>,
                    },
                ],
                enabled: true
            },
            {
                key: REFERRAL_PROGRAMS_SUBMENU_KEY,
                title: 'Programas de Indicação',
                items: [
                    {
                        name: 'Relatório de Adesão',
                        view: 'rpAnalysisReportView',
                        route: ReportsRoutes.REFERRAL_PROGRAM_ANALYSIS,
                        content: <ScreenContentReferralProgramAnalysisCP/>,
                    },
                    {
                        name: 'Leads do Programa',
                        view: 'rpLeadsReportView',
                        route: ReportsRoutes.REFERRAL_PROGRAM_LEADS,
                        content: <ScreenContentReferralProgramLeadsReportCP/>
                    },
                ],
                enabled: true
            },
            {
                key: MULTI_PROJECTS_SUBMENU_KEY,
                title: 'Comparativo entre Projetos',
                items: [
                    {
                        name: 'NPS',
                        view: 'multiProjectsReportView',
                        route: ReportsRoutes.MULTI_PROJECT_NPS,
                        content: <ScreenContentMultiProjectsNpsCP/>,
                        contentTitle: 'NPS de todos os projetos'
                    },
                ],
                enabled: true
            },
        ]
    },

}

