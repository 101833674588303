import { HttpMethodEnum } from 'common/enums/HttpMethodEnum'
import { RequestConfigTP } from 'common/request-manager/types/RequestConfigTP'
import { IUserLoginRequestDTO } from 'modules/auth/services/dtos/request/IUserLoginRequestDTO'
import { SystemConfig } from 'config/SystemConfig'

export class AuthRequests {

    private static readonly _MODULE_BASE = `${SystemConfig.getInstance().apiBaseUrl}/auth`

    static login = (dto: IUserLoginRequestDTO): RequestConfigTP<IUserLoginRequestDTO> => ({
        url: `${AuthRequests._MODULE_BASE}/login`,
        method: HttpMethodEnum.POST,
        params: dto,
        noAuth: true,
    })

}
