import { AdminRoutes } from 'modules/admin/AdminRoutes'
import { MainMenuItemTP } from 'modules/user/components/user-main-menu/inner/MainMenuItemTP'
import React from 'react'
import { NpsRoutes } from 'modules/survey/routes/NpsRoutes'
import { CsatRoutes } from 'modules/survey/routes/CsatRoutes'
import { FontAwsomeIconCP } from 'common/components/icons/FontAwsomeIconCP'
import { faChartLine, faCog, faCommentMedical, faGrinHearts, faHandHoldingUsd, faTools } from '@fortawesome/free-solid-svg-icons'
import { ReferralProgramRoutes } from 'modules/referral-program/routes/ReferralProgramRoutes'
import { ReportsRoutes } from 'modules/reports/routes/ReportsRoutes'
import { AdminSpaceRoutes } from 'modules/admin-space/AdminSpaceRoutes'
import { PermissionUtils } from 'modules/auth/utils/PermissionUtils'
import { PermissionEnum } from 'submodules/space4leads-sdk/services/people/person/enums/PermissionEnum'

/**
 * UTILITARIOS do menu principal do sistema
 */
export const UserMenuUtils = {

    getMenuOptionsTop(): MainMenuItemTP[] {
        return [
            {
                route: NpsRoutes.ROOT,
                title: 'NPS',
                iconComponent: <FontAwsomeIconCP type={faGrinHearts}/>,
                enabled: true
            },
            {
                route: CsatRoutes.ROOT,
                title: 'CSAT',
                iconComponent: <FontAwsomeIconCP type={faCommentMedical}/>,
                enabled: true
            },
            {
                route: ReferralProgramRoutes.ROOT,
                title: 'Indicações',
                iconComponent: <FontAwsomeIconCP type={faHandHoldingUsd}/>,
                enabled: true
            },
            {
                route: ReportsRoutes.ROOT,
                title: 'Relatórios',
                iconComponent: <FontAwsomeIconCP type={faChartLine}/>,
                enabled: true
            },
        ]
    },

    getMenuOptionsBottom(): MainMenuItemTP[] {

        return [
            {
                route: AdminSpaceRoutes.ROOT,
                title: 'Admin Space4Leads',
                iconComponent: <FontAwsomeIconCP type={faTools}/>,
                enabled: PermissionUtils.isSpaceAdmin()
            },
            {
                route: AdminRoutes.SYSTEM_ACCOUNT,
                title: 'Configurações do sistema',
                iconComponent: <FontAwsomeIconCP type={faCog}/>,
                enabled: PermissionUtils.hasAccess([PermissionEnum.ADMIN])
            },
        ]
    },

}
