import React, { useEffect, useState } from 'react'
import { PrivateRouter } from 'modules/app/routes/PrivateRouter'
import { LayoutCP } from 'common/components/screen-layout/layout/LayoutCP'
import { ContentCP } from 'common/components/screen-layout/content/ContentCP'
import { SiderCP } from 'common/components/screen-layout/sider/SiderCP'
import { UserMainMenuCP } from 'modules/user/components/user-main-menu/UserMainMenuCP'
import { ChatBalloonCP } from 'common/components/chat-balloon/ChatBalloonCP'
import { SystemUtils } from 'submodules/nerit-framework-utils/utils/SystemUtils'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'
import { ModalBoxCustomerSurveyCP } from 'submodules/space4leads-components-ui/survey/components/box-customer-survey/ModalBoxCustomerSurveyCP'
import { AppStateUtils } from 'common/utils/AppStateUtils'
import { TopBarSystemMessagesCP } from 'submodules/nerit-framework-ui/common/components/top-bar-system-messages/TopBarSystemMessagesCP'
import { AccountUtils } from 'submodules/space4leads-sdk/services/accounts/utils/AccountUtils'
import { SystemConfig } from 'config/SystemConfig'

/**
 * Layout principal da parte interna do sistema
 */
export function UserMainLayoutCP(): JSX.Element {

    const [shouldReload, setShouldReload] = useState<boolean>(false)
    useEffect(onReload, [shouldReload])

    /**
     * Metodo auxiliar ao precisar recarregar a tela
     */
    function onReload(): void {

        if (!shouldReload)
            return

        // Faz uma pausa para recarregar a tela pois alterou a variavel para true <-> false
        SystemUtils.sleep(1000)
        setShouldReload(false)
    }

    if (shouldReload)
        return <LoadingOverlayCP show={true}/>

    return (
        <LayoutCP
            mainLayout={true}
            content={
                <ContentCP>
                    <TopBarSystemMessagesCP
                        showBrowserWarning={true}
                        accountStatus={{
                            disabledDate: AppStateUtils.getCurrentAccount()!.account.disableDate,
                            isAccountActive: AppStateUtils.getCurrentAccount()!.account.isActive,
                            signNowLink: AccountUtils.getPlanConfig(AppStateUtils.getCurrentAccount()!.account.plan).paymentLink,
                            loggedUserName: AppStateUtils.getLoggedUserData()!.user.name
                        }}
                    />

                    <PrivateRouter/>

                    <ChatBalloonCP/>
                    <ModalBoxCustomerSurveyCP
                        person={{
                            name: AppStateUtils.getLoggedUserData()!.user.name,
                            email: AppStateUtils.getLoggedUserData()!.user.email,
                        }}
                        tags={[AppStateUtils.getCurrentProject()!.name, AppStateUtils.getCurrentAccount()!.account.name]}
                        timeConfig={{
                            daysBetweenAnswers: 30,
                            daysToFirstAnswer: 5,
                            daysToReopenOnCancel: 5,
                        }}
                        boxType={'bottom'}
                        surveyToken={SystemConfig.getInstance().space4LeadsOwnSurveyToken}
                        projectCode={SystemConfig.getInstance().space4LeadsOwnProjectCode}
                    />
                </ContentCP>
            }
            sider={
                <SiderCP
                    isMainSider={true}
                    collapsedWidth={0}
                    breakpoint={'md'}
                    width={67}
                >
                    <UserMainMenuCP onChangeCurrentFranchise={() => setShouldReload(true)}/>
                </SiderCP>
            }
        />
    )
}
