import React from 'react'
import { SurveyAnswersRequests } from 'submodules/space4leads-sdk/services/surveys/survey-answers/SurveyAnswersRequests'
import { SurveyAnswerResponseDTO } from 'submodules/space4leads-sdk/services/surveys/survey-answers/dtos/response/SurveyAnswerResponseDTO'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { TableFromApiCP } from 'submodules/nerit-framework-ui/common/components/table/TableFromApiCP'

interface ICPProps {
    surveyCode: number
}

/**
 * Tabela de respostas.
 */
export function TableSurveyAnswersCP(props: ICPProps): JSX.Element {

    return (
        <TableFromApiCP<SurveyAnswerResponseDTO>
            apiConfig={{
                requestConfigTP: () => SurveyAnswersRequests.search({ surveyCode: props.surveyCode })
            }}
            shouldLoadData={1}
            appearance={{
                wrappedOnCard: true,
            }}
            columns={[
                {
                    title: 'Data',
                    render: (text, record) => DateUtils.formatDate(record.date, DateFormatEnum.BR_WITH_TIME_H_M)
                },
                {
                    title: 'Nota',
                    align: 'center',
                    render: (text, record) => record.score
                },
                {
                    title: 'Comentários',
                    render: (text, record) => (
                        <>
                            {record.comments ?? 'Sem comentários'}
                            <TextCP text={record.person?.name ?? 'Anônimo'} color={'#adadad'}/>
                        </>
                    )
                },
            ]}
        />
    )

}
