import { FlexCP } from 'common/components/flex/FlexCP'
import React from 'react'
import { ColumnProps } from 'antd/es/table'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { IReferralProgramSubscribeItemRequestDTO } from 'modules/person/services/dtos/request/IReferralProgramSubscribeItemRequestDTO'
import { InputMaskTypeEnum } from 'common/enums/InputMaskTypeEnum'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import styled from 'styled-components'
import { ConditionalRenderCP } from 'common/components/conditional-render/ConditionalRenderCP'
import * as _ from 'lodash'
import { PhoneValidationUtils } from 'common/validation/decorators/phone/PhoneValidationUtils'

/**
 * Utilitarios gerais do componente de tabela.
 */
export const TableReferFriendsUtils = {

    getTableColumns(
        onChange: (index: number, name?: string, phone?: string) => void,
        onDelete: (index: number) => void,
    ): Array<ColumnProps<IReferralProgramSubscribeItemRequestDTO>> {
        return [
            {
                render: (text, dto, index) => `#${index + 1}`
            },
            {
                title: 'Indicações',
                render: (text, dto, index) => (
                    <InputsSCP>
                        <InputCP
                            placeholder={'Nome'}
                            required={true}
                            value={dto.name}
                            onChange={(val) => onChange(index, val)}
                        />
                        <InputCP
                            placeholder={'Celular'}
                            required={true}
                            mask={InputMaskTypeEnum.PHONE}
                            value={dto.phone}
                            onChange={(val) => onChange(index, undefined, val)}
                        />
                    </InputsSCP>
                )
            },
            {
                render: (text, dto, index) => (
                    <ConditionalRenderCP shouldRender={index !== 0}>
                        <FlexCP justifyContent={'flex-end'}>
                            <ButtonCP
                                icon={'delete'}
                                onClick={() => onDelete(index)}
                                type={'danger'}
                                size={'small'}
                            />
                        </FlexCP>
                    </ConditionalRenderCP>
                )
            },
        ]
    },

    /**
     * Valida se todas as linhas estao preenchidas.
     */
    isAllRowsFilled(friends: IReferralProgramSubscribeItemRequestDTO[]): boolean {
        return TableReferFriendsUtils.getFilledRows(friends).length === friends.length
    },

    /**
     * Obtem as linhas preenchidas.
     */
    getFilledRows(friends: IReferralProgramSubscribeItemRequestDTO[]): IReferralProgramSubscribeItemRequestDTO[] {
        return _.filter(friends, (friend) => !!friend && !!friend.name && !!friend.phone)
    },

    /**
     * Verifica se os valores informados sao validos.
     */
    isFilledRowsValid(friends: IReferralProgramSubscribeItemRequestDTO[]): boolean {

        let isSuccess = true

        friends.forEach((friend) => {

            // Se os dois nao estao preenchidos, apenas skipa
            if (!friend.phone && !friend.name)
                return

            if (!friend.name || !friend.phone || !PhoneValidationUtils.validateBR(friend.phone))
                isSuccess = false

        })

        return isSuccess
    },

}

const InputsSCP = styled.div`
  
  display: flex;
  align-items: center;

  .wrapper-input {
    padding-right: 10px !important;
  }

  @media (min-width: 600px) {
    justify-content: space-between;
  }

  @media (max-width: 599px) {
    flex-direction: column;
    justify-content: center;
  }
`
