import { LayoutCP } from 'common/components/screen-layout/layout/LayoutCP'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FallbackScreenContentCP } from 'common/components/fallback-screen-content/FallbackScreenContent'
import { FlexCP } from 'common/components/flex/FlexCP'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { useParams } from 'react-router-dom'
import { SurveyLandingPageScreenRoutePropsTP } from 'modules/survey/screens/screen-survey-landing-page/inner/types/SurveyLandingPageScreenRoutePropsTP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { SurveyResponseDTO } from 'submodules/space4leads-sdk/services/surveys/surveys/dtos/response/SurveyResponseDTO'
import { SurveysRequests } from 'submodules/space4leads-sdk/services/surveys/surveys/SurveysRequests'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { LoadingOverlayCP } from 'common/components/loading/loading-overlay/LoadingOverlayCP'
import { ScreenSurveyNotFound } from 'modules/survey/screens/screen-survey-not-found/ScreenSurveyNotFound'

/**
 * Landing Page para coletar as respostas de uma pesquisa
 */
export function ScreenSurveyResultLandingPage(): JSX.Element {

    const routeParams = useParams<SurveyLandingPageScreenRoutePropsTP>()

    useEffect(init, [])

    const [isRouteValid, setIsRouteValid] = useState<boolean>()

    const [survey, setSurvey] = useState<SurveyResponseDTO>()
    const getSurveyRequest = useRequest<SurveyResponseDTO>()
    useEffect(onGetSurveyRequestChange, [getSurveyRequest.isAwaiting])

    /**
     * Inicializa a tela.
     */
    function init(): void {

        if (!routeParams.token) {
            setIsRouteValid(false)
            return
        }

        getSurveyRequest.runRequest(SurveysRequests.findByToken(routeParams.token))
    }

    /**
     * Retorno dos detalhes da pesquisa
     */
    function onGetSurveyRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getSurveyRequest, 'Não foi possível encontra a pesquisa informada')) {
            setIsRouteValid(false)
            return
        }

        setIsRouteValid(!!getSurveyRequest.responseData)
        setSurvey(getSurveyRequest.responseData)
    }

    if (isRouteValid === undefined || !survey)
        return <LoadingOverlayCP show={true}/>

    if (!isRouteValid)
        return <ScreenSurveyNotFound/>

    return (
        <WrapperSCP>
            <LayoutWrapperSCP>
                <LayoutCP
                    content={
                        <FallbackScreenContentCP
                            status={'success'}
                            title={'Muito Obrigado!'}
                            message={survey.thanksMessage}
                            bottomContent={
                                <FlexCP justifyContent={'center'}>
                                    <ButtonCP
                                        onClick={
                                            survey.thanksCtaUrl
                                                ? () => window.open(survey.thanksCtaUrl)
                                                : () => window.close()
                                        }
                                    >
                                        { survey.thanksCtaLabel ?? 'Fechar Pesquisa' }
                                    </ButtonCP>
                                </FlexCP>
                            }
                        />
                    }
                />
            </LayoutWrapperSCP>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background: #fff;
`

const LayoutWrapperSCP = styled.div`
  margin: 0 auto 0;
  width: 95%;
  max-width: 750px;
  padding: 0 20px;
  height: 100%;
    
  .ant-layout {
    background: none;
  }
`
