import React from 'react'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { ProjectUsersFormModel } from 'modules/admin/components/modal-edit-project-user/inner/ProjectUsersFormModel'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { SelectPermissionCP } from 'modules/auth/components/select-permission/SelectPermissionCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { SkeletonCP } from 'submodules/nerit-framework-ui/common/components/skeleton/SkeletonCP'

interface ICPProps {
    formStateManager: IFormStateManager<ProjectUsersFormModel>
    isSaving: boolean
    isEdit: boolean
}

/**
 * Formulario para cadastrar uma pessoa no projeto.
 */
export function ProjectUsersFormICP(props: ICPProps): JSX.Element {

    if (props.isSaving && !props.isEdit)
        return <SkeletonCP/>

    return (
        <>
            <RowCP>
                <ColumnCP size={props.isEdit ? 12 : 8}>
                    <InputCP
                        formStateManager={props.formStateManager}
                        fieldName={'name'}
                        label={'Nome do Usuário'}
                        disabled={props.isEdit}
                    />
                </ColumnCP>

                <>
                    {
                        !props.isEdit &&
                        <ColumnCP size={8}>
                            <InputCP
                                formStateManager={props.formStateManager}
                                fieldName={'email'}
                                label={'E-mail'}
                            />
                        </ColumnCP>
                    }
                </>

                <ColumnCP size={props.isEdit ? 12 : 8}>
                    <SelectPermissionCP
                        formStateManager={props.formStateManager}
                        fieldName={'permission'}
                    />
                </ColumnCP>
            </RowCP>
        </>
    )
}
