import { LoadingOverlayCP } from 'common/components/loading/loading-overlay/LoadingOverlayCP'
import { PopConfirmCP } from 'common/components/pop-confirm/PopConfirmCP'
import { TooltipCP } from 'common/components/tooltip/TooltipCP'
import { NotificationHelper } from 'common/helpers/NotificationHelper'
import { RoutingHelper } from 'config/RoutingHelper'
import { RequestUtils } from 'common/request-manager/RequestUtils'
import { useRequest } from 'common/request-manager/use-request/UseRequest'
import { useScreenSize } from 'common/responsiveness/use-screen-size/UseScreenSize'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { IconCP } from 'common/components/icons/IconCP'
import { ReferralProgramRoutes } from 'modules/referral-program/routes/ReferralProgramRoutes'
import { RequestConfigTP } from 'common/request-manager/types/RequestConfigTP'
import { ReferralProgramRequests } from 'modules/referral-program/services/referral-program/ReferralProgramRequests'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { PermissionEnum } from 'submodules/space4leads-sdk/services/people/person/enums/PermissionEnum'
import { AccessControlCP } from 'common/components/access-control/AccessControlCP'

type _ActionTP = 'delete' | 'disable'

interface IShotListItemActionsColumnICPProps {
    code: number
    isActive: boolean
    referralProgramLink: string
    onUpdateList?: () => void
    onSelectReferralProgram: () => void
}

/**
 * Coluna de acoes.
 */
export function ReferralProgramActionsColumnICP(props: IShotListItemActionsColumnICPProps): JSX.Element {

    const screenSize = useScreenSize()

    const [action, setAction] = useState<_ActionTP>()
    const actionRequest = useRequest<any>('none')
    useEffect(onActionRequestChange, [actionRequest.isAwaiting])

    /**
     * Roda o request correto.
     */
    function runActionRequest(selectedAction: _ActionTP): void {

        let configGetter: (code: number) => RequestConfigTP

        switch (selectedAction) {
            case 'delete':
                configGetter = ReferralProgramRequests.delete
                break

            case 'disable':
                configGetter = ReferralProgramRequests.toogleActive
                break

            default:
                return
        }

        const requestConfig = configGetter(props.code)
        actionRequest.runRequest(requestConfig)
        setAction(selectedAction)
    }

    /**
     * Retorno ao chamar requisicao da acao.
     */
    function onActionRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(actionRequest, 'Ocorreu algun erro realizar a operação'))
            return

        switch (action) {

            case 'delete':
                NotificationHelper.success('Pronto!', 'Programa removido com sucesso')
                break

            case 'disable':
                NotificationHelper.success('Pronto!', 'Programa ativado/desativado com sucesso')
                break

            // no default
        }

        props.onUpdateList?.()
    }

    return (
        <ColumnSCP isSmallDevice={screenSize.smd}>
            <LoadingOverlayCP show={actionRequest.isAwaiting}/>

            <TooltipCP text={'Fluxo de Mensagens'} placement={'left'}>
                <div onClick={props.onSelectReferralProgram}>
                    <IconCP antIcon={'message'}/>
                </div>
            </TooltipCP>

            <TooltipCP text={'Editar'} placement={'left'}>
                <div onClick={() => RoutingHelper.historyPush(`${ReferralProgramRoutes.SETUP}/${props.code}`)}>
                    <IconCP antIcon={'edit'}/>
                </div>
            </TooltipCP>

            <TooltipCP text={'Abrir página do programa'} placement={'left'}>
                <a href={props.referralProgramLink} target={'_blank'} rel={'noopener noreferrer'}>
                    <IconCP antIcon={'link'}/>
                </a>
            </TooltipCP>

            <AccessControlCP permissions={[PermissionEnum.ADMIN, PermissionEnum.EDITOR]}>
                <PopConfirmCP
                    title={`Tem certeza que deseja ${props.isActive ? 'DESATIVAR' : 'ATIVAR'} este programa?`}
                    onConfirm={async () => runActionRequest('disable')}
                >
                    <div>
                        <IconCP antIcon={'poweroff'} color={props.isActive ? ThemeAnt.errorColor : ThemeAnt.successColor}/>
                    </div>
                </PopConfirmCP>
            </AccessControlCP>

            <AccessControlCP permissions={[PermissionEnum.ADMIN, PermissionEnum.EDITOR]}>
                <PopConfirmCP
                    title={'Tem certeza que deseja remover este programa?'}
                    onConfirm={async () => runActionRequest('delete')}
                >
                    <div>
                        <IconCP antIcon={'delete'}/>
                    </div>
                </PopConfirmCP>
            </AccessControlCP>

        </ColumnSCP>
    )

}

const ColumnSCP = styled.div<{ isSmallDevice: boolean }>`

    display: ${props => (props.isSmallDevice ? 'flex' : 'inline-grid')};
    padding: 10px;
    align-items: center;
    border-left: 1px solid #dedede;
    background: #f9f9f9;
    justify-content: ${props => (props.isSmallDevice ? 'space-evenly' : 'center')};
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;

    div {
        cursor: pointer;
    }
`
