import React from 'react'
import { LoadingOverlayCP } from 'common/components/loading/loading-overlay/LoadingOverlayCP'
import { Error404Screen } from 'common/screens/Error404Screen'

interface ICPProps {
    isLoading: boolean
    isValid: boolean
}

/**
 * Wrapper que ira exibir o conteudo de acordo se a rota eh valida ou nao
 */
export function LoadingOrErrorCP(props: ICPProps): JSX.Element {

    if (props.isLoading)
        return <LoadingOverlayCP show={true}/>

    if (!props.isValid)
        return <Error404Screen/>

    return <></>
}
