import { IsNotEmpty } from 'common/validation/decorators/IsNotEmpty'
import { IsString } from 'common/validation/decorators/IsString'
import { IsEmail } from 'common/validation/decorators/IsEmail'
import { NotContainsSpace } from 'common/validation/decorators/NotContainsSpace'
import { FormModel } from 'common/form-state-manager/FormModel'

/**
 * VALIDADOR
 * Define campos / validacao pra form de login.
 */
export class UserLoginFormValidator extends FormModel {

    @NotContainsSpace()
    @IsString()
    @IsNotEmpty()
    password: string

    @IsEmail()
    @IsNotEmpty()
    email: string

    constructor(initialValues?: Partial<UserLoginFormValidator>) {
        super()
        Object.assign(this, initialValues)
    }
}
