import React from 'react'
import { IIconProps } from 'common/components/icons/inner/IIconProps'
import { IconICP } from 'common/components/icons/inner/IconICP'

type CloseIconTypeTP = 'default' | 'circle' | 'box'

interface ICloseIconCPProps extends IIconProps {
    type?: CloseIconTypeTP
}

/**
 * Icone: Fechar
 */
export const CloseIconCP = (props: ICloseIconCPProps): JSX.Element => {

    let iconName: string
    switch (props.type) {
        case 'circle':
            iconName = 'close-circle'
            break

        case 'box':
            iconName = 'close-square'
            break

        case 'default':
        default:
            iconName = 'close'
            break
    }

    return (
        <IconICP
            iconName={iconName}
            {...props} // eslint-disable-line react/jsx-props-no-spreading
        />
    )
}
