import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { Space4LeadsSystemApiEnum } from 'submodules/space4leads-sdk/common/Space4LeadsSystemApiEnum'
import { AccountCreateRequestDTO } from 'submodules/space4leads-sdk/services/accounts/dtos/requests/AccountCreateRequestDTO'
import { AccountSaveRequestDTO } from 'submodules/space4leads-sdk/services/accounts/dtos/requests/AccountSaveRequestDTO'

export class AccountsRequests {

    public static CONTROLLER_ROOT = 'accounts'
    public static CREATE_ACCOUNT_EP = 'create'
    public static UPDATE_ACCOUNT_EP = 'update/:code'
    public static SEARCH_ACCOUNTS_EP = 'search'
    public static GET_ACCOUNT_EP = 'get/:code'

    static create = (dto: AccountCreateRequestDTO): RequestConfigTP => ({
        baseUrlType: Space4LeadsSystemApiEnum.SPACE4LEADS_API,
        url: `${AccountsRequests.CONTROLLER_ROOT}/${AccountsRequests.CREATE_ACCOUNT_EP}`,
        method: HttpMethodEnum.POST,
        params: dto,
    })

    static update = (code: number, dto: AccountSaveRequestDTO): RequestConfigTP => ({
        baseUrlType: Space4LeadsSystemApiEnum.SPACE4LEADS_API,
        url: `${AccountsRequests.CONTROLLER_ROOT}/${AccountsRequests.UPDATE_ACCOUNT_EP.replace(':code', code.toString())}`,
        method: HttpMethodEnum.PUT,
        params: dto,
    })

    static getAccount = (code: number): RequestConfigTP => ({
        baseUrlType: Space4LeadsSystemApiEnum.SPACE4LEADS_API,
        url: `${AccountsRequests.CONTROLLER_ROOT}/${AccountsRequests.GET_ACCOUNT_EP.replace(':code', code.toString())}`,
        method: HttpMethodEnum.GET,
    })

    static search = (): RequestConfigTP => ({
        baseUrlType: Space4LeadsSystemApiEnum.SPACE4LEADS_API,
        url: `${AccountsRequests.CONTROLLER_ROOT}/${AccountsRequests.SEARCH_ACCOUNTS_EP}`,
        method: HttpMethodEnum.GET,
    })

}
