import { Input } from 'antd'
import { FormItemICP } from 'common/components/form-fields/inner/FormItemICP'
import { IFormItemInputCommonProps } from 'common/components/form-fields/inner/interfaces/IFormItemInputCommonProps'
import { FormModel } from 'common/form-state-manager/FormModel'
import React from 'react'
import styled from 'styled-components'

const MIN_ROWS_DEFAULT = 2

interface ITextAreaCPProps<FModelTP extends FormModel = any> extends IFormItemInputCommonProps<FModelTP> {
    minRows?: number
    maxRows?: number
    placeholder?: string
    disabled?: boolean
}

/**
 * COMPONENTE
 * Input do tipo 'textarea'.
 */
export function TextAreaCP<FModelTP extends FormModel = any>(props: ITextAreaCPProps<FModelTP>): JSX.Element {

    function renderInput(): JSX.Element {

        if (!!props.formStateManager && !!props.fieldName) {
            return (
                <Input.TextArea
                    autosize={{
                        minRows: props.minRows ?? MIN_ROWS_DEFAULT,
                        maxRows: props.maxRows
                    }}
                    disabled={props.disabled}
                    value={props.formStateManager.getFieldValue(props.fieldName)}
                    placeholder={props.placeholder}
                />
            )
        }

        return (
            <Input.TextArea
                placeholder={props.placeholder}
                autosize={{
                    minRows: props.minRows ?? MIN_ROWS_DEFAULT,
                    maxRows: props.maxRows
                }}
                value={props.value}
                disabled={props.disabled}
            />
        )
    }

    return (
        <FormItemICP<FModelTP, IFormItemInputCommonProps<FModelTP>>
            label={props.label}
            onChange={props.onChange}
            fieldName={props.fieldName}
            formStateManager={props.formStateManager}
            required={props.required}
            width={props.width}
            errorMessage={props.errorMessage}
            noValidation={props.noValidation}
            disabled={props.disabled}
            value={props.value}
            hint={props.hint}
        >
            <TextAreaWrapperSCP>
                { renderInput() }
            </TextAreaWrapperSCP>
        </FormItemICP>
    )
}

const TextAreaWrapperSCP = styled.div`

    width: 100%;

    .ant-input {
        margin-top: 10px;
    }
`
