import { OrNullTP } from 'common/types/OrNullTP'
import { ReduxHelper } from 'common/redux/helpers/ReduxHelper'
import { OrUndefTP } from 'common/types/OrUndefTP'
import { DomainDataTP } from 'common/redux/types/DomainDataTP'
import { UserDataTP } from 'common/redux/types/UserDataTP'
import { AuthUserAccountResponseDTO } from 'submodules/space4leads-sdk/services/auth/dtos/responses/inner/AuthUserAccountResponseDTO'
import { AuthUserProjectsResponseDTO } from 'submodules/space4leads-sdk/services/auth/dtos/responses/inner/AuthUserProjectsResponseDTO'

/**
 * Para manipular estado global da aplicacao (redux).
 *
 * NOTE: Esses metodos nao podem pertencer a utilitaria do redux por problemas de depencia circular com a helper do redux.
 */
export const AppStateUtils = {

    /** Retorna dados do usuario logado atual. */
    getLoggedUserData(): OrNullTP<UserDataTP> {
        return ReduxHelper.getInstance().store.getState().userData
    },

    getCurrentAccount(): OrNullTP<AuthUserAccountResponseDTO> {
        return ReduxHelper.getInstance().store.getState().currentAccount
    },

    getCurrentProject(): OrNullTP<AuthUserProjectsResponseDTO> {
        return ReduxHelper.getInstance().store.getState().currentProject
    },

    getUserAccounts(): OrUndefTP<AuthUserAccountResponseDTO[]> {
        return AppStateUtils.getLoggedUserData()?.user?.accounts
    },

    /** Retorna dados do cliente (schema) a que pertence o usuario logado atual. */
    getDomainData(): OrNullTP<DomainDataTP> {
        return ReduxHelper.getInstance().store.getState().domainData
    },

    /** Retorna nome do schema/slug do dominio atual. */
    getDomainSlug(): OrUndefTP<string> {
        return AppStateUtils.getDomainData()?.schema?.slug
    },
}
