import React from 'react'
import { TitleCP } from 'common/components/title/TitleCP'
import { IFormStateManager } from 'common/form-state-manager/IFormStateManager'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import { SurveyThanksFormModel } from 'modules/survey/components/wizard-survey-config/inner/models/SurveyThanksFormModel'

interface ISurveyThanksStepICPProps {
    formStateManager: IFormStateManager<SurveyThanksFormModel>
}

/**
 * COMPOENENT Etapa de configuracoes do agradecimento.
 */
export function SurveyThanksStepICP(props: ISurveyThanksStepICPProps): JSX.Element {

    return (
        <>
            <InputCP
                label={'Mensagem de agradecimento'}
                required={true}
                formStateManager={props.formStateManager}
                fieldName={'thanksMessage'}
            />
            <TitleCP textSize={'normal'} underLine={true} marginTop={40} marginBottom={30}>
                Link de redirecionamento
            </TitleCP>
            <InputCP
                label={'URL do botão de redirecionamento'}
                formStateManager={props.formStateManager}
                fieldName={'thanksCtaUrl'}
            />
            <InputCP
                label={'Texto do botão de redirecionamento'}
                required={true}
                formStateManager={props.formStateManager}
                fieldName={'thanksCtaLabel'}
            />
        </>
    )
}
