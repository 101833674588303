import React from 'react'
import { IAddressResponseDTO } from 'modules/admin/services/dtos/responses/IAddressResponseDTO'

interface ICPProps {
    address?: IAddressResponseDTO
}

export function TextAddressCP(props: ICPProps): JSX.Element {

    if (!props.address)
        return <> Sem dados de endereço </>

    return (
        <>
            <p>
                <>{props.address.street && props.address.street}</>
                <>{props.address.number && `, ${props.address.number.toString()}`}</>
                <>{props.address.complement && ` / ${props.address.complement} `}</>
            </p>
            <p>
                <>{props.address.neighborhood && `${props.address.neighborhood}`}</>
                <>{props.address.city && ` - ${props.address.city}`}</>
                <>{props.address.state && ` / ${props.address.state}`}</>
            </p>
        </>
    )
}
